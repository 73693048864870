// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as DiffViewUtils from "./DiffViewUtils.mjs";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import ReactTextareaAutosize from "react-textarea-autosize";
import * as CopyChangesToBranchModal__Hooks from "./CopyChangesToBranchModal__Hooks.mjs";
import * as CopyChangesToBranchModal__Utils from "./CopyChangesToBranchModal__Utils.mjs";

var buttonsPalette = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("flexEnd"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

function CopyChangesToBranchModal__ChangesToCopy(Props) {
  var onClose = Props.onClose;
  var copyItems = Props.copyItems;
  var selectedBranch = Props.selectedBranch;
  var currentBranchId = Router.Schema.getBranchId(undefined);
  var mainModel = ModelStore.useMainModel(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setIsLoading = match[1];
  var fromAndToModels = CopyChangesToBranchModal__Hooks.useFromAndToModelsForCopy(selectedBranch);
  if (typeof fromAndToModels !== "object") {
    return React.createElement($$Text.make, {
                children: "Loading changes to copy..."
              });
  }
  var match$1 = fromAndToModels.VAL;
  var fromModel = match$1[0];
  var itemsActions = CopyChangesToBranchModal__Utils.getItemsActions(match$1[1], fromModel, copyItems);
  var match$2 = CopyChangesToBranchModal__Utils.resolveCopyItemActions(itemsActions);
  var actions = match$2[0];
  var __x = Belt_List.toArray(actions);
  var actionsToCopyString = JSON.stringify(Json_encode.array(Printer.printAction, __x), null, 2);
  var toModel = Belt_List.reduce(actions, fromModel, ActionsReducer.reduce);
  return React.createElement("div", undefined, React.createElement(Spacer.make, {
                  height: 16
                }), Caml_obj.notequal(match$2[1], []) ? React.createElement($$Text.make, {
                    children: "Could not find some items to copy"
                  }) : null, React.createElement(DiffViewUtils.RenderObjectsDiff.make, {
                  branchStatus: /* Loading */1,
                  fromModel: fromModel,
                  toModel: toModel,
                  actions: AvoUtils.groupActionsByObjectId(AvoUtils.actionByObject(actions)),
                  branchId: currentBranchId,
                  withCommentsAndCopyToBranch: false,
                  events: toModel.events
                }), React.createElement(ReactTextareaAutosize, {
                  className: Curry._1(Css.style, {
                        hd: Css.backgroundColor(Styles.Color.light01),
                        tl: {
                          hd: Css.padding(Css.px(10)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.width(Css.pct(100.0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }),
                  maxRows: 30,
                  onChange: (function (param) {
                      
                    }),
                  value: actionsToCopyString
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: buttonsPalette
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        }),
                      size: "large",
                      style: "ghost"
                    }), React.createElement(Button.make, {
                      disabled: Belt_Option.isNone(selectedBranch),
                      label: "Add to branch",
                      loading: match[0],
                      onClick: (function (param) {
                          if (window.confirm("Copying changes to a branch is an experimental feature. Are you sure you want to continue?") && selectedBranch !== undefined && typeof selectedBranch === "object") {
                            if (selectedBranch.NAME === "Create") {
                              window.alert("Creating a new branch is not yet supported");
                            } else {
                              var match = selectedBranch.VAL;
                              var toBranchName = match.name;
                              var toBranchId = match.id;
                              Curry._1(setIsLoading, (function (param) {
                                      return true;
                                    }));
                              var propertyIds = Belt_Array.keepMap(itemsActions, (function (itemActions) {
                                      if (typeof itemActions !== "object") {
                                        return ;
                                      }
                                      if (itemActions.NAME !== "actions") {
                                        return ;
                                      }
                                      var match = itemActions.VAL[0];
                                      if (typeof match === "object" && match.NAME === "property") {
                                        return match.VAL;
                                      }
                                      
                                    }));
                              Curry.app(sendActions, [
                                    {
                                      NAME: "Branch",
                                      VAL: toBranchId
                                    },
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (param) {
                                        Curry._1(setIsLoading, (function (param) {
                                                return false;
                                              }));
                                        Curry._1(addToast, {
                                              message: "Changes copied to " + toBranchName + "",
                                              toastType: /* Success */0
                                            });
                                        Curry._1(onClose, undefined);
                                      }),
                                    (function (param) {
                                        Curry._1(setIsLoading, (function (param) {
                                                return false;
                                              }));
                                        Curry._1(addToast, {
                                              message: "Failed to copy changes to " + toBranchName + "",
                                              toastType: /* Error */1
                                            });
                                      }),
                                    [[
                                        {
                                          NAME: "CopyChangesToBranch",
                                          VAL: [
                                            currentBranchId,
                                            Belt_Option.getWithDefault(AvoModel.getBranchName(mainModel, currentBranchId), "N/A"),
                                            toBranchId,
                                            toBranchName,
                                            actions
                                          ]
                                        },
                                        {
                                          propertyIds: propertyIds,
                                          propertiesQuery: propertyIds
                                        }
                                      ]]
                                  ]);
                            }
                          }
                          
                        }),
                      size: "large",
                      style: "primary"
                    })));
}

var make = CopyChangesToBranchModal__ChangesToCopy;

export {
  buttonsPalette ,
  make ,
}
/* buttonsPalette Not a pure module */
