// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Styles from "./styles.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "./IconErase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DomainStore from "./DomainStore.mjs";
import * as FramerMotion from "framer-motion";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SideEffectUtils from "./SideEffectUtils.mjs";
import LodashDebounce from "lodash.debounce";
import * as PortalMenu_deprecated from "./PortalMenu_deprecated.mjs";

var filter = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: {
            hd: Css.paddingRight(Css.px(0)),
            tl: {
              hd: Css.paddingLeft(Css.px(8)),
              tl: {
                hd: Css.marginRight(Css.px(8)),
                tl: {
                  hd: Css.borderRadius(Css.px(32)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.green),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var removeFilter = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.height(Css.px(22)),
                tl: {
                  hd: Css.width(Css.px(22)),
                  tl: {
                    hd: Css.marginLeft(Css.px(2)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.background("transparent"),
                            tl: {
                              hd: Css.zIndex(1),
                              tl: {
                                hd: Css.after({
                                      hd: Css.display("block"),
                                      tl: {
                                        hd: Css.position("absolute"),
                                        tl: {
                                          hd: Css.top(Css.px(2)),
                                          tl: {
                                            hd: Css.right(Css.px(2)),
                                            tl: {
                                              hd: Css.bottom(Css.px(2)),
                                              tl: {
                                                hd: Css.left(Css.px(2)),
                                                tl: {
                                                  hd: Css.borderRadius(Css.px(32)),
                                                  tl: {
                                                    hd: Css.unsafe("content", "' '"),
                                                    tl: {
                                                      hd: Css.zIndex(-1),
                                                      tl: {
                                                        hd: Css.transition({
                                                              NAME: "ms",
                                                              VAL: Styles.Duration.$$default
                                                            }, undefined, undefined, "background-color"),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.after({
                                              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.3)),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Style = {
  filter: filter,
  removeFilter: removeFilter
};

function EventsSheetFilters$FilterBubble(Props) {
  var label = Props.label;
  var items = Props.items;
  var onRemove = Props.onRemove;
  if (items.length === 0) {
    return null;
  } else {
    return React.createElement("div", {
                className: filter
              }, React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.white,
                    children: null
                  }, label + ": ", items.join(", ")), React.createElement("button", {
                    "aria-label": "Remove",
                    className: removeFilter,
                    onClick: onRemove
                  }, React.createElement(Icon.make, {
                        type_: "close",
                        size: "small",
                        color: Styles.Color.white
                      })));
  }
}

var FilterBubble = {
  Style: Style,
  make: EventsSheetFilters$FilterBubble
};

function MakeFilter(F) {
  var root = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingRight(Css.px(16)),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$short
                  }, undefined, undefined, "background-color"),
              tl: {
                hd: Css.hover({
                      hd: Css.backgroundColor(Styles.Color.light02),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      });
  var label = Curry._1(Css.style, {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.paddingTop(Css.px(4)),
          tl: {
            hd: Css.paddingBottom(Css.px(4)),
            tl: {
              hd: Css.marginRight(Css.px(4)),
              tl: {
                hd: Css.paddingLeft(Css.px(20)),
                tl: {
                  hd: Css.color(Styles.Color.light10),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$default
                        }, undefined, undefined, "color"),
                    tl: {
                      hd: Css.hover({
                            hd: Css.color(Styles.Color.light11),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.minWidth(Css.px(0)),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var input = Curry._1(Css.style, {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.margin(Css.px(0)),
          tl: /* [] */0
        }
      });
  var Style = {
    root: root,
    label: label,
    input: input
  };
  var EventsSheetFilters$MakeFilter$PopupRow = function (Props) {
    var id = Props.id;
    var label$1 = Props.label;
    var checked = Props.checked;
    var onChange = Props.onChange;
    return React.createElement("div", {
                className: root
              }, React.createElement("label", {
                    className: label,
                    htmlFor: id
                  }, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Medium",
                        singleLine: true,
                        children: label$1
                      })), React.createElement("input", {
                    className: input,
                    id: id,
                    checked: checked,
                    type: "checkbox",
                    onChange: (function (param) {
                        Curry._1(onChange, checked);
                      })
                  }));
  };
  var PopupRow = {
    Style: Style,
    make: EventsSheetFilters$MakeFilter$PopupRow
  };
  var root$1 = Curry._1(Css.style, {
        hd: Css.paddingLeft(Css.px(20)),
        tl: {
          hd: Css.paddingTop(Css.px(4)),
          tl: {
            hd: Css.paddingBottom(Css.px(4)),
            tl: {
              hd: Css.marginRight(Css.px(4)),
              tl: /* [] */0
            }
          }
        }
      });
  var button = Curry._1(Css.merge, {
        hd: Curry._1(Css.style, Styles.buttonReset),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      });
  var Style$1 = {
    root: root$1,
    button: button
  };
  var EventsSheetFilters$MakeFilter$ShowAllRow = function (Props) {
    var onClick = Props.onClick;
    var filterLabelPlural = Props.filterLabelPlural;
    var filterCount = Props.filterCount;
    return React.createElement("div", {
                className: root$1
              }, React.createElement("button", {
                    className: button,
                    onClick: onClick
                  }, React.createElement($$Text.make, {
                        size: "Medium",
                        weight: "Medium",
                        color: Styles.Color.light08,
                        children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                      })));
  };
  var ShowAllRow = {
    Style: Style$1,
    make: EventsSheetFilters$MakeFilter$ShowAllRow
  };
  var popupSectionHeader = Curry._1(Css.style, {
        hd: Css.position("sticky"),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.top(Css.px(0)),
            tl: {
              hd: Css.backdropFilter({
                    hd: {
                      NAME: "blur",
                      VAL: Css.px(10)
                    },
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
                tl: {
                  hd: Css.paddingTop(Css.px(8)),
                  tl: {
                    hd: Css.paddingRight(Css.px(8)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(8)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(20)),
                        tl: {
                          hd: Css.marginTop(Css.px(8)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
  var popupItemEmpty = Curry._1(Css.style, {
        hd: Css.paddingLeft(Css.px(20)),
        tl: /* [] */0
      });
  var Style$2 = {
    popupSectionHeader: popupSectionHeader,
    popupItemEmpty: popupItemEmpty
  };
  var EventsSheetFilters$MakeFilter = function (Props) {
    var items = Props.items;
    var filters = Props.filters;
    var searchString = Props.searchString;
    var onChange = Props.onChange;
    var match = React.useState(function () {
          return Uuid.v4();
        });
    var id = match[0];
    var match$1 = React.useState(function () {
          return F.take;
        });
    var setItemsTaken = match$1[1];
    var itemsTaken = match$1[0];
    var filterIdSet = React.useMemo((function () {
            return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                              return filter.VAL;
                            })));
          }), [filters]);
    var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
                return {
                        searchBy: Curry._1(F.getFilteringItem, item),
                        item: item
                      };
              })), searchString, {
          key: "searchBy",
          maxResults: 100
        });
    var isSearching = searchString !== "";
    var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
    if (isSearchingWithNoResults) {
      return null;
    } else {
      return React.createElement("section", undefined, React.createElement("header", {
                      className: popupSectionHeader
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Tiny",
                          color: Styles.Color.light11,
                          children: F.filterName.toLocaleUpperCase()
                        })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                        className: popupItemEmpty
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Medium",
                            singleLine: true,
                            color: Styles.Color.light08,
                            children: "No " + F.filterName.toLocaleLowerCase() + ""
                          })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                          return React.createElement(EventsSheetFilters$MakeFilter$PopupRow, {
                                      id: id + Curry._1(F.getId, filteredItem.item),
                                      label: Curry._1(F.getFilteringItem, filteredItem.item),
                                      checked: Belt_SetString.has(filterIdSet, Curry._1(F.getId, filteredItem.item)),
                                      onChange: Curry._1(onChange, Curry._1(F.getId, filteredItem.item)),
                                      key: Curry._1(F.getId, filteredItem.item)
                                    });
                        })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow, {
                        onClick: (function (param) {
                            Curry._1(setItemsTaken, (function (param) {
                                    return filteredItems.length;
                                  }));
                          }),
                        filterLabelPlural: F.filterName,
                        filterCount: filteredItems.length
                      }) : null);
    }
  };
  return {
          PopupRow: PopupRow,
          ShowAllRow: ShowAllRow,
          Style: Style$2,
          make: EventsSheetFilters$MakeFilter
        };
}

var filterName = "Sources";

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.minWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: /* [] */0
      }
    });

var Style$1 = {
  root: root,
  label: label,
  input: input
};

function EventsSheetFilters$MakeFilter$PopupRow(Props) {
  var id = Props.id;
  var label$1 = Props.label;
  var checked = Props.checked;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: root
            }, React.createElement("label", {
                  className: label,
                  htmlFor: id
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      children: label$1
                    })), React.createElement("input", {
                  className: input,
                  id: id,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      Curry._1(onChange, checked);
                    })
                }));
}

var PopupRow = {
  Style: Style$1,
  make: EventsSheetFilters$MakeFilter$PopupRow
};

var root$1 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var button = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Style$2 = {
  root: root$1,
  button: button
};

function EventsSheetFilters$MakeFilter$ShowAllRow(Props) {
  var onClick = Props.onClick;
  var filterLabelPlural = Props.filterLabelPlural;
  var filterCount = Props.filterCount;
  return React.createElement("div", {
              className: root$1
            }, React.createElement("button", {
                  className: button,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                    })));
}

var ShowAllRow = {
  Style: Style$2,
  make: EventsSheetFilters$MakeFilter$ShowAllRow
};

var popupSectionHeader = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.marginTop(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var popupItemEmpty = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var Style$3 = {
  popupSectionHeader: popupSectionHeader,
  popupItemEmpty: popupItemEmpty
};

function EventsSheetFilters$MakeFilter(Props) {
  var items = Props.items;
  var filters = Props.filters;
  var searchString = Props.searchString;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  var match$1 = React.useState(function () {
        return 5;
      });
  var setItemsTaken = match$1[1];
  var itemsTaken = match$1[0];
  var filterIdSet = React.useMemo((function () {
          return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                            return filter.VAL;
                          })));
        }), [filters]);
  var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
              return {
                      searchBy: AvoConfig.getSourceName(item),
                      item: item
                    };
            })), searchString, {
        key: "searchBy",
        maxResults: 100
      });
  var isSearching = searchString !== "";
  var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
  if (isSearchingWithNoResults) {
    return null;
  } else {
    return React.createElement("section", undefined, React.createElement("header", {
                    className: popupSectionHeader
                  }, React.createElement($$Text.make, {
                        element: "H1",
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: filterName.toLocaleUpperCase()
                      })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                      className: popupItemEmpty
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: "No " + filterName.toLocaleLowerCase() + ""
                        })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                        return React.createElement(EventsSheetFilters$MakeFilter$PopupRow, {
                                    id: id + filteredItem.item.id,
                                    label: AvoConfig.getSourceName(filteredItem.item),
                                    checked: Belt_SetString.has(filterIdSet, filteredItem.item.id),
                                    onChange: Curry._1(onChange, filteredItem.item.id),
                                    key: filteredItem.item.id
                                  });
                      })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow, {
                      onClick: (function (param) {
                          Curry._1(setItemsTaken, (function (param) {
                                  return filteredItems.length;
                                }));
                        }),
                      filterLabelPlural: filterName,
                      filterCount: filteredItems.length
                    }) : null);
  }
}

var SourceFilter = {
  PopupRow: PopupRow,
  ShowAllRow: ShowAllRow,
  Style: Style$3,
  make: EventsSheetFilters$MakeFilter
};

var filterName$1 = "Destinations";

var root$2 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label$1 = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.minWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input$1 = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: /* [] */0
      }
    });

var Style$4 = {
  root: root$2,
  label: label$1,
  input: input$1
};

function EventsSheetFilters$MakeFilter$PopupRow$1(Props) {
  var id = Props.id;
  var label$2 = Props.label;
  var checked = Props.checked;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: root$2
            }, React.createElement("label", {
                  className: label$1,
                  htmlFor: id
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      children: label$2
                    })), React.createElement("input", {
                  className: input$1,
                  id: id,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      Curry._1(onChange, checked);
                    })
                }));
}

var PopupRow$1 = {
  Style: Style$4,
  make: EventsSheetFilters$MakeFilter$PopupRow$1
};

var root$3 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var button$1 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Style$5 = {
  root: root$3,
  button: button$1
};

function EventsSheetFilters$MakeFilter$ShowAllRow$1(Props) {
  var onClick = Props.onClick;
  var filterLabelPlural = Props.filterLabelPlural;
  var filterCount = Props.filterCount;
  return React.createElement("div", {
              className: root$3
            }, React.createElement("button", {
                  className: button$1,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                    })));
}

var ShowAllRow$1 = {
  Style: Style$5,
  make: EventsSheetFilters$MakeFilter$ShowAllRow$1
};

var popupSectionHeader$1 = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.marginTop(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var popupItemEmpty$1 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var Style$6 = {
  popupSectionHeader: popupSectionHeader$1,
  popupItemEmpty: popupItemEmpty$1
};

function EventsSheetFilters$MakeFilter$1(Props) {
  var items = Props.items;
  var filters = Props.filters;
  var searchString = Props.searchString;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  var match$1 = React.useState(function () {
        return 5;
      });
  var setItemsTaken = match$1[1];
  var itemsTaken = match$1[0];
  var filterIdSet = React.useMemo((function () {
          return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                            return filter.VAL;
                          })));
        }), [filters]);
  var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
              return {
                      searchBy: ModelUtils.getDestinationName(item),
                      item: item
                    };
            })), searchString, {
        key: "searchBy",
        maxResults: 100
      });
  var isSearching = searchString !== "";
  var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
  if (isSearchingWithNoResults) {
    return null;
  } else {
    return React.createElement("section", undefined, React.createElement("header", {
                    className: popupSectionHeader$1
                  }, React.createElement($$Text.make, {
                        element: "H1",
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: filterName$1.toLocaleUpperCase()
                      })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                      className: popupItemEmpty$1
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: "No " + filterName$1.toLocaleLowerCase() + ""
                        })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                        return React.createElement(EventsSheetFilters$MakeFilter$PopupRow$1, {
                                    id: id + filteredItem.item.id,
                                    label: ModelUtils.getDestinationName(filteredItem.item),
                                    checked: Belt_SetString.has(filterIdSet, filteredItem.item.id),
                                    onChange: Curry._1(onChange, filteredItem.item.id),
                                    key: filteredItem.item.id
                                  });
                      })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow$1, {
                      onClick: (function (param) {
                          Curry._1(setItemsTaken, (function (param) {
                                  return filteredItems.length;
                                }));
                        }),
                      filterLabelPlural: filterName$1,
                      filterCount: filteredItems.length
                    }) : null);
  }
}

var DestinationFilter = {
  PopupRow: PopupRow$1,
  ShowAllRow: ShowAllRow$1,
  Style: Style$6,
  make: EventsSheetFilters$MakeFilter$1
};

var filterName$2 = "Tags";

var root$4 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label$2 = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.minWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input$2 = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: /* [] */0
      }
    });

var Style$7 = {
  root: root$4,
  label: label$2,
  input: input$2
};

function EventsSheetFilters$MakeFilter$PopupRow$2(Props) {
  var id = Props.id;
  var label$3 = Props.label;
  var checked = Props.checked;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: root$4
            }, React.createElement("label", {
                  className: label$2,
                  htmlFor: id
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      children: label$3
                    })), React.createElement("input", {
                  className: input$2,
                  id: id,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      Curry._1(onChange, checked);
                    })
                }));
}

var PopupRow$2 = {
  Style: Style$7,
  make: EventsSheetFilters$MakeFilter$PopupRow$2
};

var root$5 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var button$2 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Style$8 = {
  root: root$5,
  button: button$2
};

function EventsSheetFilters$MakeFilter$ShowAllRow$2(Props) {
  var onClick = Props.onClick;
  var filterLabelPlural = Props.filterLabelPlural;
  var filterCount = Props.filterCount;
  return React.createElement("div", {
              className: root$5
            }, React.createElement("button", {
                  className: button$2,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                    })));
}

var ShowAllRow$2 = {
  Style: Style$8,
  make: EventsSheetFilters$MakeFilter$ShowAllRow$2
};

var popupSectionHeader$2 = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.marginTop(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var popupItemEmpty$2 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var Style$9 = {
  popupSectionHeader: popupSectionHeader$2,
  popupItemEmpty: popupItemEmpty$2
};

function EventsSheetFilters$MakeFilter$2(Props) {
  var items = Props.items;
  var filters = Props.filters;
  var searchString = Props.searchString;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  var match$1 = React.useState(function () {
        return 5;
      });
  var setItemsTaken = match$1[1];
  var itemsTaken = match$1[0];
  var filterIdSet = React.useMemo((function () {
          return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                            return filter.VAL;
                          })));
        }), [filters]);
  var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
              return {
                      searchBy: item,
                      item: item
                    };
            })), searchString, {
        key: "searchBy",
        maxResults: 100
      });
  var isSearching = searchString !== "";
  var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
  if (isSearchingWithNoResults) {
    return null;
  } else {
    return React.createElement("section", undefined, React.createElement("header", {
                    className: popupSectionHeader$2
                  }, React.createElement($$Text.make, {
                        element: "H1",
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: filterName$2.toLocaleUpperCase()
                      })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                      className: popupItemEmpty$2
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: "No " + filterName$2.toLocaleLowerCase() + ""
                        })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                        return React.createElement(EventsSheetFilters$MakeFilter$PopupRow$2, {
                                    id: id + filteredItem.item,
                                    label: filteredItem.item,
                                    checked: Belt_SetString.has(filterIdSet, filteredItem.item),
                                    onChange: Curry._1(onChange, filteredItem.item),
                                    key: filteredItem.item
                                  });
                      })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow$2, {
                      onClick: (function (param) {
                          Curry._1(setItemsTaken, (function (param) {
                                  return filteredItems.length;
                                }));
                        }),
                      filterLabelPlural: filterName$2,
                      filterCount: filteredItems.length
                    }) : null);
  }
}

var TagFilter = {
  PopupRow: PopupRow$2,
  ShowAllRow: ShowAllRow$2,
  Style: Style$9,
  make: EventsSheetFilters$MakeFilter$2
};

var filterName$3 = "Property Bundles";

var root$6 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label$3 = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.minWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input$3 = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: /* [] */0
      }
    });

var Style$10 = {
  root: root$6,
  label: label$3,
  input: input$3
};

function EventsSheetFilters$MakeFilter$PopupRow$3(Props) {
  var id = Props.id;
  var label$4 = Props.label;
  var checked = Props.checked;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: root$6
            }, React.createElement("label", {
                  className: label$3,
                  htmlFor: id
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      children: label$4
                    })), React.createElement("input", {
                  className: input$3,
                  id: id,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      Curry._1(onChange, checked);
                    })
                }));
}

var PopupRow$3 = {
  Style: Style$10,
  make: EventsSheetFilters$MakeFilter$PopupRow$3
};

var root$7 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var button$3 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Style$11 = {
  root: root$7,
  button: button$3
};

function EventsSheetFilters$MakeFilter$ShowAllRow$3(Props) {
  var onClick = Props.onClick;
  var filterLabelPlural = Props.filterLabelPlural;
  var filterCount = Props.filterCount;
  return React.createElement("div", {
              className: root$7
            }, React.createElement("button", {
                  className: button$3,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                    })));
}

var ShowAllRow$3 = {
  Style: Style$11,
  make: EventsSheetFilters$MakeFilter$ShowAllRow$3
};

var popupSectionHeader$3 = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.marginTop(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var popupItemEmpty$3 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var Style$12 = {
  popupSectionHeader: popupSectionHeader$3,
  popupItemEmpty: popupItemEmpty$3
};

function EventsSheetFilters$MakeFilter$3(Props) {
  var items = Props.items;
  var filters = Props.filters;
  var searchString = Props.searchString;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  var match$1 = React.useState(function () {
        return 5;
      });
  var setItemsTaken = match$1[1];
  var itemsTaken = match$1[0];
  var filterIdSet = React.useMemo((function () {
          return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                            return filter.VAL;
                          })));
        }), [filters]);
  var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
              return {
                      searchBy: item.name,
                      item: item
                    };
            })), searchString, {
        key: "searchBy",
        maxResults: 100
      });
  var isSearching = searchString !== "";
  var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
  if (isSearchingWithNoResults) {
    return null;
  } else {
    return React.createElement("section", undefined, React.createElement("header", {
                    className: popupSectionHeader$3
                  }, React.createElement($$Text.make, {
                        element: "H1",
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: filterName$3.toLocaleUpperCase()
                      })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                      className: popupItemEmpty$3
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: "No " + filterName$3.toLocaleLowerCase() + ""
                        })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                        return React.createElement(EventsSheetFilters$MakeFilter$PopupRow$3, {
                                    id: id + filteredItem.item.id,
                                    label: filteredItem.item.name,
                                    checked: Belt_SetString.has(filterIdSet, filteredItem.item.id),
                                    onChange: Curry._1(onChange, filteredItem.item.id),
                                    key: filteredItem.item.id
                                  });
                      })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow$3, {
                      onClick: (function (param) {
                          Curry._1(setItemsTaken, (function (param) {
                                  return filteredItems.length;
                                }));
                        }),
                      filterLabelPlural: filterName$3,
                      filterCount: filteredItems.length
                    }) : null);
  }
}

var PropertyGroupFilter = {
  PopupRow: PopupRow$3,
  ShowAllRow: ShowAllRow$3,
  Style: Style$12,
  make: EventsSheetFilters$MakeFilter$3
};

var filterName$4 = "Properties";

var root$8 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label$4 = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.minWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input$4 = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: /* [] */0
      }
    });

var Style$13 = {
  root: root$8,
  label: label$4,
  input: input$4
};

function EventsSheetFilters$MakeFilter$PopupRow$4(Props) {
  var id = Props.id;
  var label$5 = Props.label;
  var checked = Props.checked;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: root$8
            }, React.createElement("label", {
                  className: label$4,
                  htmlFor: id
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      children: label$5
                    })), React.createElement("input", {
                  className: input$4,
                  id: id,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      Curry._1(onChange, checked);
                    })
                }));
}

var PopupRow$4 = {
  Style: Style$13,
  make: EventsSheetFilters$MakeFilter$PopupRow$4
};

var root$9 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var button$4 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Style$14 = {
  root: root$9,
  button: button$4
};

function EventsSheetFilters$MakeFilter$ShowAllRow$4(Props) {
  var onClick = Props.onClick;
  var filterLabelPlural = Props.filterLabelPlural;
  var filterCount = Props.filterCount;
  return React.createElement("div", {
              className: root$9
            }, React.createElement("button", {
                  className: button$4,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                    })));
}

var ShowAllRow$4 = {
  Style: Style$14,
  make: EventsSheetFilters$MakeFilter$ShowAllRow$4
};

var popupSectionHeader$4 = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.marginTop(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var popupItemEmpty$4 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var Style$15 = {
  popupSectionHeader: popupSectionHeader$4,
  popupItemEmpty: popupItemEmpty$4
};

function EventsSheetFilters$MakeFilter$4(Props) {
  var items = Props.items;
  var filters = Props.filters;
  var searchString = Props.searchString;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  var match$1 = React.useState(function () {
        return 5;
      });
  var setItemsTaken = match$1[1];
  var itemsTaken = match$1[0];
  var filterIdSet = React.useMemo((function () {
          return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                            return filter.VAL;
                          })));
        }), [filters]);
  var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
              return {
                      searchBy: item.name,
                      item: item
                    };
            })), searchString, {
        key: "searchBy",
        maxResults: 100
      });
  var isSearching = searchString !== "";
  var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
  if (isSearchingWithNoResults) {
    return null;
  } else {
    return React.createElement("section", undefined, React.createElement("header", {
                    className: popupSectionHeader$4
                  }, React.createElement($$Text.make, {
                        element: "H1",
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: filterName$4.toLocaleUpperCase()
                      })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                      className: popupItemEmpty$4
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: "No " + filterName$4.toLocaleLowerCase() + ""
                        })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                        return React.createElement(EventsSheetFilters$MakeFilter$PopupRow$4, {
                                    id: id + filteredItem.item.id,
                                    label: filteredItem.item.name,
                                    checked: Belt_SetString.has(filterIdSet, filteredItem.item.id),
                                    onChange: Curry._1(onChange, filteredItem.item.id),
                                    key: filteredItem.item.id
                                  });
                      })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow$4, {
                      onClick: (function (param) {
                          Curry._1(setItemsTaken, (function (param) {
                                  return filteredItems.length;
                                }));
                        }),
                      filterLabelPlural: filterName$4,
                      filterCount: filteredItems.length
                    }) : null);
  }
}

var PropertyFilter = {
  PopupRow: PopupRow$4,
  ShowAllRow: ShowAllRow$4,
  Style: Style$15,
  make: EventsSheetFilters$MakeFilter$4
};

var filterName$5 = "Actions";

var root$10 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label$5 = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.minWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input$5 = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: /* [] */0
      }
    });

var Style$16 = {
  root: root$10,
  label: label$5,
  input: input$5
};

function EventsSheetFilters$MakeFilter$PopupRow$5(Props) {
  var id = Props.id;
  var label$6 = Props.label;
  var checked = Props.checked;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: root$10
            }, React.createElement("label", {
                  className: label$5,
                  htmlFor: id
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      children: label$6
                    })), React.createElement("input", {
                  className: input$5,
                  id: id,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      Curry._1(onChange, checked);
                    })
                }));
}

var PopupRow$5 = {
  Style: Style$16,
  make: EventsSheetFilters$MakeFilter$PopupRow$5
};

var root$11 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var button$5 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Style$17 = {
  root: root$11,
  button: button$5
};

function EventsSheetFilters$MakeFilter$ShowAllRow$5(Props) {
  var onClick = Props.onClick;
  var filterLabelPlural = Props.filterLabelPlural;
  var filterCount = Props.filterCount;
  return React.createElement("div", {
              className: root$11
            }, React.createElement("button", {
                  className: button$5,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                    })));
}

var ShowAllRow$5 = {
  Style: Style$17,
  make: EventsSheetFilters$MakeFilter$ShowAllRow$5
};

var popupSectionHeader$5 = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.marginTop(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var popupItemEmpty$5 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var Style$18 = {
  popupSectionHeader: popupSectionHeader$5,
  popupItemEmpty: popupItemEmpty$5
};

function EventsSheetFilters$MakeFilter$5(Props) {
  var items = Props.items;
  var filters = Props.filters;
  var searchString = Props.searchString;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  var match$1 = React.useState(function () {
        return 5;
      });
  var setItemsTaken = match$1[1];
  var itemsTaken = match$1[0];
  var filterIdSet = React.useMemo((function () {
          return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                            return filter.VAL;
                          })));
        }), [filters]);
  var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
              return {
                      searchBy: item,
                      item: item
                    };
            })), searchString, {
        key: "searchBy",
        maxResults: 100
      });
  var isSearching = searchString !== "";
  var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
  if (isSearchingWithNoResults) {
    return null;
  } else {
    return React.createElement("section", undefined, React.createElement("header", {
                    className: popupSectionHeader$5
                  }, React.createElement($$Text.make, {
                        element: "H1",
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: filterName$5.toLocaleUpperCase()
                      })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                      className: popupItemEmpty$5
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: "No " + filterName$5.toLocaleLowerCase() + ""
                        })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                        return React.createElement(EventsSheetFilters$MakeFilter$PopupRow$5, {
                                    id: id + filteredItem.item,
                                    label: filteredItem.item,
                                    checked: Belt_SetString.has(filterIdSet, filteredItem.item),
                                    onChange: Curry._1(onChange, filteredItem.item),
                                    key: filteredItem.item
                                  });
                      })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow$5, {
                      onClick: (function (param) {
                          Curry._1(setItemsTaken, (function (param) {
                                  return filteredItems.length;
                                }));
                        }),
                      filterLabelPlural: filterName$5,
                      filterCount: filteredItems.length
                    }) : null);
  }
}

var ActionFilter = {
  PopupRow: PopupRow$5,
  ShowAllRow: ShowAllRow$5,
  Style: Style$18,
  make: EventsSheetFilters$MakeFilter$5
};

var filterName$6 = "Categories";

var root$12 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label$6 = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.minWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input$6 = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: /* [] */0
      }
    });

var Style$19 = {
  root: root$12,
  label: label$6,
  input: input$6
};

function EventsSheetFilters$MakeFilter$PopupRow$6(Props) {
  var id = Props.id;
  var label$7 = Props.label;
  var checked = Props.checked;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: root$12
            }, React.createElement("label", {
                  className: label$6,
                  htmlFor: id
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      children: label$7
                    })), React.createElement("input", {
                  className: input$6,
                  id: id,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      Curry._1(onChange, checked);
                    })
                }));
}

var PopupRow$6 = {
  Style: Style$19,
  make: EventsSheetFilters$MakeFilter$PopupRow$6
};

var root$13 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var button$6 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Style$20 = {
  root: root$13,
  button: button$6
};

function EventsSheetFilters$MakeFilter$ShowAllRow$6(Props) {
  var onClick = Props.onClick;
  var filterLabelPlural = Props.filterLabelPlural;
  var filterCount = Props.filterCount;
  return React.createElement("div", {
              className: root$13
            }, React.createElement("button", {
                  className: button$6,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                    })));
}

var ShowAllRow$6 = {
  Style: Style$20,
  make: EventsSheetFilters$MakeFilter$ShowAllRow$6
};

var popupSectionHeader$6 = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.marginTop(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var popupItemEmpty$6 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var Style$21 = {
  popupSectionHeader: popupSectionHeader$6,
  popupItemEmpty: popupItemEmpty$6
};

function EventsSheetFilters$MakeFilter$6(Props) {
  var items = Props.items;
  var filters = Props.filters;
  var searchString = Props.searchString;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  var match$1 = React.useState(function () {
        return 5;
      });
  var setItemsTaken = match$1[1];
  var itemsTaken = match$1[0];
  var filterIdSet = React.useMemo((function () {
          return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                            return filter.VAL;
                          })));
        }), [filters]);
  var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
              return {
                      searchBy: item.name,
                      item: item
                    };
            })), searchString, {
        key: "searchBy",
        maxResults: 100
      });
  var isSearching = searchString !== "";
  var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
  if (isSearchingWithNoResults) {
    return null;
  } else {
    return React.createElement("section", undefined, React.createElement("header", {
                    className: popupSectionHeader$6
                  }, React.createElement($$Text.make, {
                        element: "H1",
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: filterName$6.toLocaleUpperCase()
                      })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                      className: popupItemEmpty$6
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: "No " + filterName$6.toLocaleLowerCase() + ""
                        })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                        return React.createElement(EventsSheetFilters$MakeFilter$PopupRow$6, {
                                    id: id + filteredItem.item.id,
                                    label: filteredItem.item.name,
                                    checked: Belt_SetString.has(filterIdSet, filteredItem.item.id),
                                    onChange: Curry._1(onChange, filteredItem.item.id),
                                    key: filteredItem.item.id
                                  });
                      })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow$6, {
                      onClick: (function (param) {
                          Curry._1(setItemsTaken, (function (param) {
                                  return filteredItems.length;
                                }));
                        }),
                      filterLabelPlural: filterName$6,
                      filterCount: filteredItems.length
                    }) : null);
  }
}

var CategoryFilter = {
  PopupRow: PopupRow$6,
  ShowAllRow: ShowAllRow$6,
  Style: Style$21,
  make: EventsSheetFilters$MakeFilter$6
};

var filterName$7 = "Stakeholders";

var root$14 = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.paddingRight(Css.px(16)),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "background-color"),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label$7 = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: {
              hd: Css.paddingLeft(Css.px(20)),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$default
                      }, undefined, undefined, "color"),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.minWidth(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var input$7 = Curry._1(Css.style, {
      hd: Css.flexShrink(0.0),
      tl: {
        hd: Css.margin(Css.px(0)),
        tl: /* [] */0
      }
    });

var Style$22 = {
  root: root$14,
  label: label$7,
  input: input$7
};

function EventsSheetFilters$MakeFilter$PopupRow$7(Props) {
  var id = Props.id;
  var label$8 = Props.label;
  var checked = Props.checked;
  var onChange = Props.onChange;
  return React.createElement("div", {
              className: root$14
            }, React.createElement("label", {
                  className: label$7,
                  htmlFor: id
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      singleLine: true,
                      children: label$8
                    })), React.createElement("input", {
                  className: input$7,
                  id: id,
                  checked: checked,
                  type: "checkbox",
                  onChange: (function (param) {
                      Curry._1(onChange, checked);
                    })
                }));
}

var PopupRow$7 = {
  Style: Style$22,
  make: EventsSheetFilters$MakeFilter$PopupRow$7
};

var root$15 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: {
        hd: Css.paddingTop(Css.px(4)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.marginRight(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var button$7 = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var Style$23 = {
  root: root$15,
  button: button$7
};

function EventsSheetFilters$MakeFilter$ShowAllRow$7(Props) {
  var onClick = Props.onClick;
  var filterLabelPlural = Props.filterLabelPlural;
  var filterCount = Props.filterCount;
  return React.createElement("div", {
              className: root$15
            }, React.createElement("button", {
                  className: button$7,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Medium",
                      color: Styles.Color.light08,
                      children: "Show all " + String(filterCount) + " " + filterLabelPlural + ""
                    })));
}

var ShowAllRow$7 = {
  Style: Style$23,
  make: EventsSheetFilters$MakeFilter$ShowAllRow$7
};

var popupSectionHeader$7 = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.top(Css.px(0)),
          tl: {
            hd: Css.backdropFilter({
                  hd: {
                    NAME: "blur",
                    VAL: Css.px(10)
                  },
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.5)),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(20)),
                      tl: {
                        hd: Css.marginTop(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var popupItemEmpty$7 = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(20)),
      tl: /* [] */0
    });

var Style$24 = {
  popupSectionHeader: popupSectionHeader$7,
  popupItemEmpty: popupItemEmpty$7
};

function EventsSheetFilters$MakeFilter$7(Props) {
  var items = Props.items;
  var filters = Props.filters;
  var searchString = Props.searchString;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  var match$1 = React.useState(function () {
        return 8;
      });
  var setItemsTaken = match$1[1];
  var itemsTaken = match$1[0];
  var filterIdSet = React.useMemo((function () {
          return Belt_SetString.fromArray(Belt_Array.mapU(filters, (function (filter) {
                            return filter.VAL;
                          })));
        }), [filters]);
  var filteredItems = Fuzzaldrin.filter(Belt_Array.map(items, (function (item) {
              return {
                      searchBy: item.name,
                      item: item
                    };
            })), searchString, {
        key: "searchBy",
        maxResults: 100
      });
  var isSearching = searchString !== "";
  var isSearchingWithNoResults = isSearching && filteredItems.length === 0;
  if (isSearchingWithNoResults) {
    return null;
  } else {
    return React.createElement("section", undefined, React.createElement("header", {
                    className: popupSectionHeader$7
                  }, React.createElement($$Text.make, {
                        element: "H1",
                        size: "Tiny",
                        color: Styles.Color.light11,
                        children: filterName$7.toLocaleUpperCase()
                      })), Caml_obj.equal(filteredItems, []) ? React.createElement("div", {
                      className: popupItemEmpty$7
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Medium",
                          singleLine: true,
                          color: Styles.Color.light08,
                          children: "No " + filterName$7.toLocaleLowerCase() + ""
                        })) : Belt_Array.mapU(Belt_Array.slice(filteredItems, 0, itemsTaken), (function (filteredItem) {
                        return React.createElement(EventsSheetFilters$MakeFilter$PopupRow$7, {
                                    id: id + filteredItem.item.id,
                                    label: filteredItem.item.name,
                                    checked: Belt_SetString.has(filterIdSet, filteredItem.item.id),
                                    onChange: Curry._1(onChange, filteredItem.item.id),
                                    key: filteredItem.item.id
                                  });
                      })), itemsTaken < filteredItems.length ? React.createElement(EventsSheetFilters$MakeFilter$ShowAllRow$7, {
                      onClick: (function (param) {
                          Curry._1(setItemsTaken, (function (param) {
                                  return filteredItems.length;
                                }));
                        }),
                      filterLabelPlural: filterName$7,
                      filterCount: filteredItems.length
                    }) : null);
  }
}

var DomainFilter = {
  PopupRow: PopupRow$7,
  ShowAllRow: ShowAllRow$7,
  Style: Style$24,
  make: EventsSheetFilters$MakeFilter$7
};

var popup = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.white),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                        NAME: "num",
                        VAL: 0.10
                      }))),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: {
                hd: Css.overflow("hidden"),
                tl: {
                  hd: Css.width(Css.px(260)),
                  tl: {
                    hd: Css.maxHeight(Css.px(320)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var popupHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
            tl: {
              hd: Css.paddingRight(Css.px(16)),
              tl: {
                hd: Css.paddingLeft(Css.px(20)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var popupResetButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("inlineFlex"),
              tl: {
                hd: Css.flexDirection("row"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.color(Styles.Color.darkBlue),
                    tl: {
                      hd: Css.fontSize(Css.px(10)),
                      tl: {
                        hd: Css.letterSpacing(Css.em(0.01)),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(Styles.Color.deepBlue),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.active({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: {
                                    hd: Css.focus({
                                          hd: Css.outlineStyle("none"),
                                          tl: {
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.backgroundColor("transparent"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var popupContent = Curry._1(Css.style, {
      hd: Css.flexGrow(1.0),
      tl: {
        hd: Css.flexShrink(1.0),
        tl: {
          hd: Css.overflowY("auto"),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.selector(":empty::after", {
                    hd: Css.unsafe("content", "'No results...'"),
                    tl: {
                      hd: Css.display("block"),
                      tl: {
                        hd: Css.padding(Css.px(16)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(20)),
                          tl: {
                            hd: Css.paddingBottom(Css.px(8)),
                            tl: {
                              hd: Css.color(Styles.Color.light10),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var filters = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.minHeight(Css.pct(100.0)),
            tl: {
              hd: Css.marginLeft(Css.px(1)),
              tl: {
                hd: Css.paddingLeft(Css.px(8)),
                tl: {
                  hd: Css.overflowX("auto"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function eraseButton(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginTop(Css.px(0)),
                tl: {
                  hd: Css.marginBottom(Css.px(0)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.margin(Css.px(0)),
                      tl: {
                        hd: Css.borderWidth(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.px(2)),
                          tl: {
                            hd: Css.background("transparent"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "opacity"),
                              tl: {
                                hd: Css.opacity(disabled ? 0 : 1),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.active({
                                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Style$25 = {
  popup: popup,
  popupHeader: popupHeader,
  popupResetButton: popupResetButton,
  popupContent: popupContent,
  filters: filters,
  eraseButton: eraseButton
};

var debounceFunction = LodashDebounce((function (fn) {
        Curry._1(fn, undefined);
      }), 500, {
      leading: false,
      trailing: true
    });

function EventsSheetFilters(Props) {
  var model = Props.model;
  var globalSend = Props.globalSend;
  var schemaBundle = Props.schemaBundle;
  var filters$1 = Props.filters;
  var domains = DomainStore.useDomains(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setShowFilterPopup = match[1];
  var showFilterPopup = match[0];
  var buttonRef = React.useRef(null);
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchString = match$1[1];
  var searchString = match$1[0];
  Hooks.useDidUpdate1((function (param) {
          AnalyticsRe.sheetFiltersInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, showFilterPopup ? "Opened" : "Closed");
          if (!showFilterPopup) {
            Curry._1(setSearchString, (function (param) {
                    return "";
                  }));
          }
          
        }), [showFilterPopup]);
  var sources = React.useMemo((function () {
          return Belt_List.toArray(model.sources);
        }), [model.sources]);
  var destinations = React.useMemo((function () {
          return Belt_List.toArray(model.destinations);
        }), [model.destinations]);
  var tags = React.useMemo((function () {
          return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_List.toArray(Belt_List.mapU(model.events, (function (param) {
                                        return Belt_List.toArray(param.tags);
                                      }))))));
        }), [model.events]);
  var propertyGroups = React.useMemo((function () {
          return Belt_List.toArray(model.propertyBundles);
        }), [model.propertyBundles]);
  var properties = React.useMemo((function () {
          return Belt_List.toArray(Belt_List.sortU(Belt_List.keepMapU(model.properties, (function (p) {
                                var tmp;
                                tmp = p._0.id;
                                return ModelUtils.resolvePropertyById(tmp, model);
                              })), (function (a, b) {
                            return Caml.string_compare(a.name, b.name);
                          })));
        }), [model.properties]);
  var actions = React.useMemo((function () {
          return Belt_Array.mapU(SideEffectUtils.sideEffectsOrder, SideEffectUtils.getSideEffectLabel);
        }), []);
  var categories = React.useMemo((function () {
          return Belt_List.toArray(Belt_List.sortU(model.goals, (function (a, b) {
                            return Caml.string_compare(a.name, b.name);
                          })));
        }), [model.goals]);
  var orderedDomains = Belt_SortArray.stableSortByU(Curry._1(Domains.toArray, domains), (function (a, b) {
          return $$String.compare(a.name, b.name);
        }));
  return React.createElement(React.Fragment, undefined, React.createElement("span", {
                  id: "sheetview-tour-filters"
                }, React.createElement(Button.make, {
                      icon: "filter",
                      label: "Filter",
                      onClick: (function (param) {
                          Curry._1(setShowFilterPopup, (function (state) {
                                  return !state;
                                }));
                        }),
                      style: "ghost",
                      ref: buttonRef
                    })), React.createElement(PortalMenu_deprecated.make, {
                  position: "Bottom",
                  visible: showFilterPopup,
                  onClose: (function (param) {
                      Curry._1(setShowFilterPopup, (function (param) {
                              return false;
                            }));
                    }),
                  referenceItemRef: buttonRef,
                  children: React.createElement("aside", {
                        className: popup
                      }, React.createElement("header", {
                            className: popupHeader
                          }, React.createElement("input", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width(Css.pct(100)),
                                      tl: {
                                        hd: Css.padding(Css.px(16)),
                                        tl: {
                                          hd: Css.paddingLeft(Css.px(0)),
                                          tl: {
                                            hd: Css.fontSize(Css.px(14)),
                                            tl: {
                                              hd: Css.fontWeight({
                                                    NAME: "num",
                                                    VAL: 500
                                                  }),
                                              tl: {
                                                hd: Css.borderWidth(Css.px(0)),
                                                tl: {
                                                  hd: Css.outlineStyle("none"),
                                                  tl: {
                                                    hd: Css.outlineWidth(Css.px(0)),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.light10),
                                                      tl: {
                                                        hd: Css.placeholder({
                                                              hd: Css.color(Styles.Color.light08),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                autoFocus: true,
                                placeholder: "Filter by...",
                                type: "text",
                                value: searchString,
                                onChange: (function (e) {
                                    var value = e.target.value;
                                    Curry._1(setSearchString, (function (param) {
                                            return value;
                                          }));
                                    debounceFunction(function (param) {
                                          AnalyticsRe.sheetFiltersInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "SearchStringEntered");
                                        });
                                  })
                              }), React.createElement("button", {
                                className: eraseButton(searchString === ""),
                                disabled: searchString === "",
                                onClick: (function (param) {
                                    Curry._1(setSearchString, (function (param) {
                                            return "";
                                          }));
                                  })
                              }, React.createElement(IconErase.make, {
                                    color: Styles.Color.light07,
                                    hoverColor: Styles.Color.red
                                  })), React.createElement(FramerMotion.AnimatePresence, {
                                children: filters$1.length === 0 ? null : React.createElement(FramerMotion.motion.div, {
                                        animate: {
                                          opacity: 1,
                                          width: "max-content"
                                        },
                                        transition: {
                                          duration: 0.2
                                        },
                                        initial: {
                                          opacity: 0,
                                          width: "0px"
                                        },
                                        exit: {
                                          opacity: 0,
                                          width: "0px"
                                        },
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.marginLeft(Css.px(12)),
                                                tl: /* [] */0
                                              }
                                            }),
                                        key: "reset",
                                        children: React.createElement("button", {
                                              className: popupResetButton,
                                              onClick: (function (param) {
                                                  Curry._1(globalSend, {
                                                        TAG: /* UpdateFilters */0,
                                                        _0: /* Reset */8,
                                                        _1: "Events"
                                                      });
                                                })
                                            }, "RESET")
                                      })
                              })), React.createElement("div", {
                            className: popupContent
                          }, React.createElement(EventsSheetFilters$MakeFilter, {
                                items: sources,
                                filters: filters$1,
                                searchString: searchString,
                                onChange: (function (id, checked) {
                                    Curry._1(globalSend, {
                                          TAG: /* UpdateFilters */0,
                                          _0: checked ? ({
                                                TAG: /* RemoveSource */1,
                                                _0: id
                                              }) : ({
                                                TAG: /* SetSource */0,
                                                _0: id
                                              }),
                                          _1: "Events"
                                        });
                                  })
                              }), React.createElement(EventsSheetFilters$MakeFilter$7, {
                                items: orderedDomains,
                                filters: filters$1,
                                searchString: searchString,
                                onChange: (function (id, checked) {
                                    Curry._1(globalSend, {
                                          TAG: /* UpdateFilters */0,
                                          _0: checked ? ({
                                                TAG: /* RemoveDomain */15,
                                                _0: id
                                              }) : ({
                                                TAG: /* SetDomain */14,
                                                _0: id
                                              }),
                                          _1: "Events"
                                        });
                                  })
                              }), React.createElement(EventsSheetFilters$MakeFilter$1, {
                                items: destinations,
                                filters: filters$1,
                                searchString: searchString,
                                onChange: (function (id, checked) {
                                    Curry._1(globalSend, {
                                          TAG: /* UpdateFilters */0,
                                          _0: checked ? ({
                                                TAG: /* RemoveDestination */5,
                                                _0: id
                                              }) : ({
                                                TAG: /* SetDestination */4,
                                                _0: id
                                              }),
                                          _1: "Events"
                                        });
                                  })
                              }), React.createElement(EventsSheetFilters$MakeFilter$2, {
                                items: tags,
                                filters: filters$1,
                                searchString: searchString,
                                onChange: (function (tag, checked) {
                                    Curry._1(globalSend, {
                                          TAG: /* UpdateFilters */0,
                                          _0: checked ? ({
                                                TAG: /* RemoveTag */3,
                                                _0: tag
                                              }) : ({
                                                TAG: /* SetTag */2,
                                                _0: tag
                                              }),
                                          _1: "Events"
                                        });
                                  })
                              }), React.createElement(EventsSheetFilters$MakeFilter$3, {
                                items: propertyGroups,
                                filters: filters$1,
                                searchString: searchString,
                                onChange: (function (id, checked) {
                                    Curry._1(globalSend, {
                                          TAG: /* UpdateFilters */0,
                                          _0: checked ? ({
                                                TAG: /* RemovePropertyGroup */7,
                                                _0: id
                                              }) : ({
                                                TAG: /* SetPropertyGroup */6,
                                                _0: id
                                              }),
                                          _1: "Events"
                                        });
                                  })
                              }), React.createElement(EventsSheetFilters$MakeFilter$4, {
                                items: properties,
                                filters: filters$1,
                                searchString: searchString,
                                onChange: (function (id, checked) {
                                    Curry._1(globalSend, {
                                          TAG: /* UpdateFilters */0,
                                          _0: checked ? ({
                                                TAG: /* RemoveProperty */9,
                                                _0: id
                                              }) : ({
                                                TAG: /* SetProperty */8,
                                                _0: id
                                              }),
                                          _1: "Events"
                                        });
                                  })
                              }), React.createElement(EventsSheetFilters$MakeFilter$5, {
                                items: actions,
                                filters: filters$1,
                                searchString: searchString,
                                onChange: (function (action, checked) {
                                    Curry._1(globalSend, {
                                          TAG: /* UpdateFilters */0,
                                          _0: checked ? ({
                                                TAG: /* RemoveAction */11,
                                                _0: action
                                              }) : ({
                                                TAG: /* SetAction */10,
                                                _0: action
                                              }),
                                          _1: "Events"
                                        });
                                  })
                              }), React.createElement(EventsSheetFilters$MakeFilter$6, {
                                items: categories,
                                filters: filters$1,
                                searchString: searchString,
                                onChange: (function (id, checked) {
                                    Curry._1(globalSend, {
                                          TAG: /* UpdateFilters */0,
                                          _0: checked ? ({
                                                TAG: /* RemoveCategory */13,
                                                _0: id
                                              }) : ({
                                                TAG: /* SetCategory */12,
                                                _0: id
                                              }),
                                          _1: "Events"
                                        });
                                  })
                              })))
                }), React.createElement("div", {
                  className: filters
                }, React.createElement(EventsSheetFilters$FilterBubble, {
                      label: "Source",
                      items: Belt_Array.keepMapU(filters$1, (function (filter) {
                              if (typeof filter === "object" && filter.NAME === "Source") {
                                return AvoConfig.getSourceNameById(model, filter.VAL);
                              }
                              
                            })),
                      onRemove: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* UpdateFilters */0,
                                _0: /* ClearSources */0,
                                _1: "Events"
                              });
                        })
                    }), React.createElement(EventsSheetFilters$FilterBubble, {
                      label: "Stakeholder",
                      items: Belt_Array.keepMapU(filters$1, (function (filter) {
                              if (typeof filter === "object" && filter.NAME === "Domain") {
                                return Belt_Option.mapU(Curry._2(Domains.get, domains, filter.VAL), (function (domain) {
                                              return domain.name;
                                            }));
                              }
                              
                            })),
                      onRemove: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* UpdateFilters */0,
                                _0: /* ClearDomains */7,
                                _1: "Events"
                              });
                        })
                    }), React.createElement(EventsSheetFilters$FilterBubble, {
                      label: "Destination",
                      items: Belt_Array.keepMapU(filters$1, (function (filter) {
                              if (typeof filter === "object" && filter.NAME === "Destination") {
                                return ModelUtils.getDestinationNameById(model, filter.VAL);
                              }
                              
                            })),
                      onRemove: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* UpdateFilters */0,
                                _0: /* ClearDestinations */2,
                                _1: "Events"
                              });
                        })
                    }), React.createElement(EventsSheetFilters$FilterBubble, {
                      label: "Tag",
                      items: Belt_Array.keepMapU(filters$1, (function (filter) {
                              if (typeof filter === "object" && filter.NAME === "Tag") {
                                return filter.VAL;
                              }
                              
                            })),
                      onRemove: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* UpdateFilters */0,
                                _0: /* ClearTags */1,
                                _1: "Events"
                              });
                        })
                    }), React.createElement(EventsSheetFilters$FilterBubble, {
                      label: "Property Bundle",
                      items: Belt_Array.keepMapU(filters$1, (function (filter) {
                              if (typeof filter === "object" && filter.NAME === "PropertyGroup") {
                                return Belt_Option.mapU(ModelUtils.getPropertyGroupById(filter.VAL, model), (function (param) {
                                              return param.name;
                                            }));
                              }
                              
                            })),
                      onRemove: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* UpdateFilters */0,
                                _0: /* ClearPropertyGroups */3,
                                _1: "Events"
                              });
                        })
                    }), React.createElement(EventsSheetFilters$FilterBubble, {
                      label: "Property",
                      items: Belt_Array.keepMapU(filters$1, (function (filter) {
                              if (typeof filter === "object" && filter.NAME === "Property") {
                                return ModelUtils.getPropertyNameById(filter.VAL, model);
                              }
                              
                            })),
                      onRemove: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* UpdateFilters */0,
                                _0: /* ClearProperties */4,
                                _1: "Events"
                              });
                        })
                    }), React.createElement(EventsSheetFilters$FilterBubble, {
                      label: "Action",
                      items: Belt_Array.keepMapU(filters$1, (function (filter) {
                              if (typeof filter === "object" && filter.NAME === "Action") {
                                return filter.VAL;
                              }
                              
                            })),
                      onRemove: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* UpdateFilters */0,
                                _0: /* ClearActions */5,
                                _1: "Events"
                              });
                        })
                    }), React.createElement(EventsSheetFilters$FilterBubble, {
                      label: "Category",
                      items: Belt_Array.keepMapU(filters$1, (function (filter) {
                              if (typeof filter === "object" && filter.NAME === "Category") {
                                return Belt_Option.mapU(ModelUtils.getGoalById(filter.VAL, model), (function (goal) {
                                              return goal.name;
                                            }));
                              }
                              
                            })),
                      onRemove: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* UpdateFilters */0,
                                _0: /* ClearCategories */6,
                                _1: "Events"
                              });
                        })
                    })));
}

var make = EventsSheetFilters;

export {
  FilterBubble ,
  MakeFilter ,
  SourceFilter ,
  DestinationFilter ,
  TagFilter ,
  PropertyGroupFilter ,
  PropertyFilter ,
  ActionFilter ,
  CategoryFilter ,
  DomainFilter ,
  Style$25 as Style,
  debounceFunction ,
  make ,
}
/* filter Not a pure module */
