// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as CopyChangesToBranchModal__BranchPicker from "./import/CopyChangesToBranchModal__BranchPicker.mjs";
import * as CopyChangesToBranchModal__ChangesToCopy from "./CopyChangesToBranchModal__ChangesToCopy.mjs";

var modalStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: {
              hd: Css.justifyContent("stretch"),
              tl: {
                hd: Css.maxHeight(Css.pct(90)),
                tl: {
                  hd: Css.boxShadow(Styles.Shadow.standard),
                  tl: {
                    hd: Css.borderRadius(Css.px(10)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.borderWidth(Css.px(0)),
                        tl: {
                          hd: Css.width(Css.px(600)),
                          tl: {
                            hd: Css.padding(Css.px(48)),
                            tl: {
                              hd: Css.wordWrap("breakWord"),
                              tl: {
                                hd: Css.maxHeight(Css.vh(90)),
                                tl: {
                                  hd: Css.overflowY("scroll"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function selectedBranchToKey(selectedBranch) {
  if (selectedBranch !== undefined) {
    if (typeof selectedBranch === "object") {
      if (selectedBranch.NAME === "Create") {
        return "create";
      } else {
        return selectedBranch.VAL.id;
      }
    } else {
      return "master";
    }
  } else {
    return "none";
  }
}

function CopyChangesToBranchModal(Props) {
  var copyItems = Props.copyItems;
  var onClose = Props.onClose;
  var match = React.useState(function () {
        
      });
  var selectedBranch = match[0];
  return React.createElement("div", {
              className: modalStyles
            }, React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Bold",
                  children: "Add to different branch"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      children: "Add these tracking plan changes to:"
                    }), React.createElement(Spacer.make, {
                      width: 6
                    }), React.createElement(CopyChangesToBranchModal__BranchPicker.make, {
                      selectedBranch: selectedBranch,
                      setSelectedBranch: match[1]
                    })), React.createElement(CopyChangesToBranchModal__ChangesToCopy.make, {
                  onClose: onClose,
                  copyItems: copyItems,
                  selectedBranch: selectedBranch,
                  key: selectedBranchToKey(selectedBranch)
                }));
}

var make = CopyChangesToBranchModal;

export {
  make ,
}
/* modalStyles Not a pure module */
