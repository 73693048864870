// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as CollapsedContent from "./CollapsedContent.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as GetEventsSendingLiteralUseCase from "../../model/src/eventSpecificPropertyValues/GetEventsSendingLiteralUseCase.mjs";
import * as GetVariantsCountOnEventsUseCase from "../../model/src/variants/GetVariantsCountOnEventsUseCase.mjs";
import * as GetVariantsSendingLiteralUseCase from "../../model/src/eventSpecificPropertyValues/GetVariantsSendingLiteralUseCase.mjs";

function getLiteralFromAction(action) {
  if (typeof action !== "object") {
    return Pervasives.failwith("Unsupported literal type and/or action in DiffPropertyAllowedValues.res");
  }
  var variant = action.NAME;
  if (variant === "AddEventSpecificPropertyValueForSomeEvents" || variant === "AddEventSpecificPropertyValueForAllEvents" || variant === "RemoveEventSpecificPropertyValueForAllEvents") {
    var match = action.VAL[1];
    if (typeof match === "object" && match.NAME === "StringLit") {
      return match.VAL;
    } else {
      return Pervasives.failwith("Unsupported literal type and/or action in DiffPropertyAllowedValues.res");
    }
  }
  if (variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources" || variant === "ClearAllEventSpecificPropertyValuesFromProperty") {
    return "All Values";
  }
  if (!(variant === "AddEventSpecificPropertyValue" || variant === "SetEventSpecificPropertyValueSources" || variant === "RemoveEventSpecificPropertyValue")) {
    return Pervasives.failwith("Unsupported literal type and/or action in DiffPropertyAllowedValues.res");
  }
  var match$1 = action.VAL[2];
  if (typeof match$1 === "object" && match$1.NAME === "StringLit") {
    return match$1.VAL;
  } else {
    return Pervasives.failwith("Unsupported literal type and/or action in DiffPropertyAllowedValues.res");
  }
}

var literalHeaderStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var literalExpandedContainerStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.small),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.marginTop(Css.px(10)),
          tl: /* [] */0
        }
      }
    });

function literalContainerStyles(diffColor) {
  return Curry._1(Css.style, {
              hd: Css.fontWeight(Styles.FontWeight.semi),
              tl: {
                hd: Css.fontSize(Styles.FontSize.small),
                tl: {
                  hd: Css.margin4(Css.px(5), Css.px(20), Css.px(5), Css.px(0)),
                  tl: {
                    hd: Css.padding2(Css.px(10), Css.px(15)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", diffColor),
                        tl: {
                          hd: Css.color(diffColor),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function DiffPropertyAllowedValues(Props) {
  var fromProperty = Props.fromProperty;
  var toProperty = Props.toProperty;
  var actions = Props.actions;
  var branchModel = Props.branchModel;
  var actionsGroupedByLiteral = BeltListExtensions.group((function (a, b) {
          return Caml.string_compare(getLiteralFromAction(a), getLiteralFromAction(b));
        }), actions);
  var propertyId = toProperty.id;
  var propertyEvents;
  if (toProperty.sendAs === /* SystemProperty */0) {
    var init = TrackingPlanModel.emptyEvent("system", "all events");
    propertyEvents = [{
        id: init.id,
        name: init.name,
        uniqueName: init.uniqueName,
        description: init.description,
        directPropertyRefs: init.directPropertyRefs,
        propertyBundles: init.propertyBundles,
        variants: init.variants,
        types: init.types,
        tags: init.tags,
        excludeSourcesDeprecated: init.excludeSourcesDeprecated,
        includeSources: Belt_List.map(branchModel.sources, (function (source) {
                return {
                        id: source.id,
                        includeInCodegen: false,
                        inspectorValidation: undefined
                      };
              })),
        includeDestinations: init.includeDestinations,
        hashes: init.hashes,
        propertyWhitelist: init.propertyWhitelist,
        eventGroupTypeIdsWithArchive: init.eventGroupTypeIdsWithArchive,
        userGroupTypeIdsWithArchive: init.userGroupTypeIdsWithArchive,
        triggers: init.triggers,
        globalRequirementsMetadata: init.globalRequirementsMetadata
      }];
  } else {
    propertyEvents = Belt_List.toArray(ModelUtils.eventsSendingProperty(branchModel, undefined, propertyId));
  }
  return Belt_List.toArray(Belt_List.map(actionsGroupedByLiteral, (function (literalActions) {
                    var value = getLiteralFromAction(Belt_List.headExn(literalActions));
                    var fromLiteral = Belt_Array.getBy(fromProperty.eventSpecificAllowedPropertyValues, (function (param) {
                            return Caml_obj.equal(param[0], {
                                        NAME: "StringLit",
                                        VAL: value
                                      });
                          }));
                    var toLiteral = Belt_Array.getBy(toProperty.eventSpecificAllowedPropertyValues, (function (param) {
                            return Caml_obj.equal(param[0], {
                                        NAME: "StringLit",
                                        VAL: value
                                      });
                          }));
                    var changeType = fromLiteral !== undefined ? (
                        toLiteral !== undefined ? "Update" : "Remove"
                      ) : (
                        toLiteral !== undefined ? "Add" : "Update"
                      );
                    var match = changeType === "Remove" ? [
                        Styles.Color.darkRed,
                        Styles.Color.darkRed
                      ] : (
                        changeType === "Update" ? [
                            Styles.Color.light11,
                            Styles.Color.light06
                          ] : [
                            Styles.Color.darkGreen,
                            Styles.Color.darkGreen
                          ]
                      );
                    var primary = match[0];
                    var eventsSendingLiteral = toLiteral !== undefined ? GetEventsSendingLiteralUseCase.getEventsSendingLiteral(propertyEvents, toLiteral[1]) : [];
                    var printEventName = function (eventId) {
                      if (eventId === TrackingPlanModel.propertyAbsenceAllEventsKey) {
                        return "all events";
                      } else {
                        return Belt_Option.mapWithDefault(ModelUtils.getEventByIdWithArchive(eventId, branchModel), "Unknown Event (" + eventId + ")", (function ($$event) {
                                      return $$event.name;
                                    }));
                      }
                    };
                    var pruneAndPrintSources = function (sourceSet, eventId) {
                      var eventIncludeSourcesSet = eventId === TrackingPlanModel.propertyAbsenceAllEventsKey ? Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray(branchModel.sources), (function (source) {
                                    return source.id;
                                  }))) : Belt_Option.mapWithDefault(ModelUtils.getEventByIdWithArchive(eventId, branchModel), sourceSet, (function ($$event) {
                                return Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map($$event.includeSources, (function (param) {
                                                      return param.id;
                                                    }))));
                              }));
                      var currentSourceSet = Belt_SetString.intersect(sourceSet, eventIncludeSourcesSet);
                      if (Belt_SetString.eq(currentSourceSet, eventIncludeSourcesSet)) {
                        return "";
                      }
                      var sourceNames = Belt_Array.map(Belt_SetString.toArray(currentSourceSet), (function (sourceId) {
                                return AvoConfig.getSourceNameById(branchModel, sourceId);
                              })).join(", ");
                      return "when sent from " + sourceNames + "";
                    };
                    return React.createElement("div", {
                                key: "" + propertyId + "-" + value + "",
                                className: literalContainerStyles(match[1]),
                                onClick: (function (prim) {
                                    prim.stopPropagation();
                                  })
                              }, React.createElement(CollapsedContent.make, {
                                    button: (function (onClick, isCollapsed) {
                                        var tmp;
                                        if (changeType === "Remove") {
                                          tmp = null;
                                        } else if (changeType === "Update") {
                                          tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    weight: "Semi",
                                                    color: primary,
                                                    children: AvoUtils.plural(undefined, undefined, Belt_List.length(literalActions), "change")
                                                  }), React.createElement(Spacer.make, {
                                                    width: 10
                                                  }), React.createElement(Icon.make, {
                                                    type_: isCollapsed ? "chevronRight" : "chevronDown",
                                                    size: "medium",
                                                    color: Styles.Color.light11
                                                  }));
                                        } else {
                                          var isAllowedOnAllEvents = propertyEvents.length === eventsSendingLiteral.length;
                                          var numberOfVariantsTotal = GetVariantsCountOnEventsUseCase.getVariantsCountOnEvents(propertyEvents);
                                          var numberOfVariantsAllowed = GetVariantsSendingLiteralUseCase.getVariantsSendingLiteral(propertyId, propertyEvents, eventsSendingLiteral, value).length;
                                          var isAllowedOnAllVariants = numberOfVariantsTotal === numberOfVariantsAllowed;
                                          var match = eventsSendingLiteral.length;
                                          var text = isAllowedOnAllEvents && isAllowedOnAllVariants ? "Allowed on all events and all event variants" : (
                                              numberOfVariantsTotal !== 0 ? (
                                                  match !== 0 ? "Allowed on " + AvoUtils.plural(undefined, undefined, eventsSendingLiteral.length, "event") + " and " + AvoUtils.plural(undefined, undefined, numberOfVariantsAllowed, "event variant") + "" : "Allowed on " + AvoUtils.plural(undefined, undefined, numberOfVariantsAllowed, "event variant") + ""
                                                ) : "Allowed on " + AvoUtils.plural(undefined, undefined, eventsSendingLiteral.length, "event") + ""
                                            );
                                          tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    weight: "Semi",
                                                    color: primary,
                                                    children: text
                                                  }), React.createElement(Spacer.make, {
                                                    width: 10
                                                  }), React.createElement(Icon.make, {
                                                    type_: isCollapsed ? "chevronRight" : "chevronDown",
                                                    size: "medium",
                                                    color: Styles.Color.light11
                                                  }));
                                        }
                                        return React.createElement("button", {
                                                    className: literalHeaderStyles,
                                                    onClick: (function (_event) {
                                                        Curry._1(onClick, undefined);
                                                      })
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        weight: "Semi",
                                                        color: primary,
                                                        children: value
                                                      }), React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.display("flex"),
                                                              tl: {
                                                                hd: Css.alignItems("center"),
                                                                tl: /* [] */0
                                                              }
                                                            })
                                                      }, tmp));
                                      }),
                                    children: (function (param) {
                                        return React.createElement("div", {
                                                    className: literalExpandedContainerStyles
                                                  }, Belt_List.toArray(Belt_List.map(literalActions, (function (action) {
                                                              if (typeof action !== "object") {
                                                                return null;
                                                              }
                                                              var variant = action.NAME;
                                                              if (variant === "RemoveEventSpecificPropertyValueForAllEvents") {
                                                                return React.createElement("div", {
                                                                            key: "RemoveEventSpecificPropertyValueForAllEvents-" + value + ""
                                                                          }, React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                weight: "Regular",
                                                                                color: Styles.Color.light12,
                                                                                children: null
                                                                              }, JsDiff.renderStringDiff(undefined, true, true, "Allowed", "Disallowed"), " on all events"), React.createElement(Spacer.make, {
                                                                                height: 4
                                                                              }));
                                                              }
                                                              if (variant === "ClearAllEventSpecificPropertyValuesFromProperty") {
                                                                return React.createElement("div", {
                                                                            key: "ClearAllEventSpecificPropertyValuesFromProperty-" + action.VAL + ""
                                                                          }, React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                weight: "Regular",
                                                                                color: Styles.Color.light12,
                                                                                children: "Allowed all values on the property"
                                                                              }), React.createElement(Spacer.make, {
                                                                                height: 4
                                                                              }));
                                                              }
                                                              if (variant === "RemoveEventSpecificPropertyValue") {
                                                                var match = action.VAL;
                                                                var disallowedSources = match[3];
                                                                var eventId = match[0];
                                                                return React.createElement("div", {
                                                                            key: "RemoveEventSpecificPropertyValue-" + eventId + "-" + value + "-" + Belt_SetString.toArray(disallowedSources).join("-") + ""
                                                                          }, React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                weight: "Regular",
                                                                                color: Styles.Color.light12,
                                                                                children: null
                                                                              }, JsDiff.renderStringDiff(undefined, true, true, "Allowed", "Disallowed"), " on " + printEventName(eventId) + " " + pruneAndPrintSources(disallowedSources, eventId) + ""), React.createElement(Spacer.make, {
                                                                                height: 4
                                                                              }));
                                                              }
                                                              if (variant === "SetEventSpecificPropertyValueSources") {
                                                                var match$1 = action.VAL;
                                                                var disallowedSources$1 = match$1[4];
                                                                var allowedSources = match$1[3];
                                                                var eventId$1 = match$1[0];
                                                                if (Belt_SetString.isEmpty(allowedSources)) {
                                                                  return React.createElement("div", {
                                                                              key: "SetEventSpecificPropertyValueSources-" + eventId$1 + "-" + value + "-" + Belt_SetString.toArray(allowedSources).join("-") + "-" + Belt_SetString.toArray(disallowedSources$1).join("-") + ""
                                                                            }, React.createElement($$Text.make, {
                                                                                  size: "Small",
                                                                                  weight: "Regular",
                                                                                  color: Styles.Color.light12,
                                                                                  children: null
                                                                                }, JsDiff.renderStringDiff(undefined, true, true, "Allowed", "Disallowed"), " on " + printEventName(eventId$1) + " " + pruneAndPrintSources(disallowedSources$1, eventId$1) + ""), React.createElement(Spacer.make, {
                                                                                  height: 4
                                                                                }));
                                                                } else if (Belt_SetString.isEmpty(disallowedSources$1)) {
                                                                  return React.createElement("div", {
                                                                              key: "SetEventSpecificPropertyValueSources-" + eventId$1 + "-" + value + "-" + Belt_SetString.toArray(allowedSources).join("-") + "-" + Belt_SetString.toArray(disallowedSources$1).join("-") + ""
                                                                            }, React.createElement($$Text.make, {
                                                                                  size: "Small",
                                                                                  weight: "Regular",
                                                                                  color: Styles.Color.light12,
                                                                                  children: null
                                                                                }, JsDiff.renderStringDiff(undefined, true, true, changeType === "Add" ? "" : "Disallowed", "Allowed"), " on " + printEventName(eventId$1) + " " + pruneAndPrintSources(allowedSources, eventId$1) + ""), React.createElement(Spacer.make, {
                                                                                  height: 4
                                                                                }));
                                                                } else {
                                                                  return React.createElement("div", {
                                                                              key: "SetEventSpecificPropertyValueSources-" + eventId$1 + "-" + value + "-" + Belt_SetString.toArray(allowedSources).join("-") + "-" + Belt_SetString.toArray(disallowedSources$1).join("-") + ""
                                                                            }, React.createElement($$Text.make, {
                                                                                  size: "Small",
                                                                                  weight: "Regular",
                                                                                  color: Styles.Color.light12,
                                                                                  children: null
                                                                                }, JsDiff.renderStringDiff(undefined, true, true, changeType === "Add" ? "" : "Disallowed", "Allowed"), " on " + printEventName(eventId$1) + " " + pruneAndPrintSources(allowedSources, eventId$1) + " and ", JsDiff.renderStringDiff(undefined, true, true, "Allowed", "Disallowed"), " " + pruneAndPrintSources(disallowedSources$1, eventId$1) + ""), React.createElement(Spacer.make, {
                                                                                  height: 4
                                                                                }));
                                                                }
                                                              }
                                                              if (variant === "AddEventSpecificPropertyValue") {
                                                                var match$2 = action.VAL;
                                                                var allowedSources$1 = match$2[3];
                                                                var eventId$2 = match$2[0];
                                                                if (Belt_SetString.isEmpty(allowedSources$1)) {
                                                                  return null;
                                                                } else {
                                                                  return React.createElement("div", {
                                                                              key: "AddEventSpecificPropertyValue-" + eventId$2 + "-" + value + "-" + Belt_SetString.toArray(allowedSources$1).join("-") + ""
                                                                            }, React.createElement($$Text.make, {
                                                                                  size: "Small",
                                                                                  weight: "Regular",
                                                                                  color: Styles.Color.light12,
                                                                                  children: null
                                                                                }, JsDiff.renderStringDiff(undefined, true, true, changeType === "Add" ? "" : "Disallowed", "Allowed"), " on " + printEventName(eventId$2) + " " + pruneAndPrintSources(allowedSources$1, eventId$2) + ""), React.createElement(Spacer.make, {
                                                                                  height: 4
                                                                                }));
                                                                }
                                                              }
                                                              if (variant === "AddEventSpecificPropertyValueForAllEvents") {
                                                                return React.createElement("div", {
                                                                            key: "AddEventSpecificPropertyValueForAllEvents-" + value + ""
                                                                          }, React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                weight: "Regular",
                                                                                color: Styles.Color.light12,
                                                                                children: null
                                                                              }, JsDiff.renderStringDiff(undefined, true, true, changeType === "Add" ? "" : "Disallowed", "Allowed"), " on all events"), React.createElement(Spacer.make, {
                                                                                height: 4
                                                                              }));
                                                              }
                                                              if (variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources") {
                                                                return React.createElement("div", {
                                                                            key: "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources-" + action.VAL + ""
                                                                          }, React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                weight: "Regular",
                                                                                color: Styles.Color.light12,
                                                                                children: null
                                                                              }, JsDiff.renderStringDiff(undefined, true, true, "", "Allowed"), " all values on all events and sources"), React.createElement(Spacer.make, {
                                                                                height: 4
                                                                              }));
                                                              }
                                                              if (variant !== "AddEventSpecificPropertyValueForSomeEvents") {
                                                                return null;
                                                              }
                                                              var match$3 = action.VAL;
                                                              var disallowedEvents = match$3[2];
                                                              var _property = match$3[0];
                                                              if (Belt_MapString.isEmpty(disallowedEvents)) {
                                                                return React.createElement("div", {
                                                                            key: "AddEventSpecificPropertyValueForAllEvents-" + value + ""
                                                                          }, React.createElement($$Text.make, {
                                                                                size: "Small",
                                                                                weight: "Regular",
                                                                                color: Styles.Color.light12,
                                                                                children: null
                                                                              }, JsDiff.renderStringDiff(undefined, true, true, changeType === "Add" ? "" : "Disallowed", "Allowed"), " on all events"), React.createElement(Spacer.make, {
                                                                                height: 4
                                                                              }));
                                                              } else {
                                                                return Belt_Array.map(propertyEvents, (function ($$event) {
                                                                              var maybeDisallowedState = Belt_MapString.get(disallowedEvents, $$event.id);
                                                                              var eventIncludeSourcesSet = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map($$event.includeSources, (function (param) {
                                                                                              return param.id;
                                                                                            }))));
                                                                              if (maybeDisallowedState === undefined) {
                                                                                return React.createElement("div", {
                                                                                            key: "AddEventSpecificPropertyValueForSomeEvents-" + _property + "-" + value + "-" + $$event.id + ""
                                                                                          }, React.createElement($$Text.make, {
                                                                                                size: "Small",
                                                                                                weight: "Regular",
                                                                                                color: Styles.Color.light12,
                                                                                                children: null
                                                                                              }, JsDiff.renderStringDiff(undefined, true, true, "", "Allowed"), " on " + printEventName($$event.id) + ""), React.createElement(Spacer.make, {
                                                                                                height: 4
                                                                                              }));
                                                                              }
                                                                              if (!maybeDisallowedState) {
                                                                                return null;
                                                                              }
                                                                              var disallowedSources = maybeDisallowedState._0;
                                                                              if (Belt_SetString.eq(Belt_SetString.intersect(disallowedSources, eventIncludeSourcesSet), eventIncludeSourcesSet)) {
                                                                                return null;
                                                                              }
                                                                              if (Belt_SetString.isEmpty(disallowedSources)) {
                                                                                return React.createElement("div", {
                                                                                            key: "AddEventSpecificPropertyValueForSomeEvents-" + _property + "-" + value + "-" + $$event.id + ""
                                                                                          }, React.createElement($$Text.make, {
                                                                                                size: "Small",
                                                                                                weight: "Regular",
                                                                                                color: Styles.Color.light12,
                                                                                                children: null
                                                                                              }, JsDiff.renderStringDiff(undefined, true, true, "", "Allowed"), " on " + printEventName($$event.id) + ""), React.createElement(Spacer.make, {
                                                                                                height: 4
                                                                                              }));
                                                                              }
                                                                              var allowedSources = Belt_SetString.diff(eventIncludeSourcesSet, disallowedSources);
                                                                              return React.createElement("div", {
                                                                                          key: "AddEventSpecificPropertyValueForSomeEvents-" + _property + "-" + value + "-" + $$event.id + ""
                                                                                        }, React.createElement($$Text.make, {
                                                                                              size: "Small",
                                                                                              weight: "Regular",
                                                                                              color: Styles.Color.light12,
                                                                                              children: null
                                                                                            }, JsDiff.renderStringDiff(undefined, true, true, "", "Allowed"), " on " + printEventName($$event.id) + " " + pruneAndPrintSources(allowedSources, $$event.id) + ""), React.createElement(Spacer.make, {
                                                                                              height: 4
                                                                                            }));
                                                                            }));
                                                              }
                                                            }))));
                                      })
                                  }));
                  })));
}

var make = DiffPropertyAllowedValues;

export {
  getLiteralFromAction ,
  literalHeaderStyles ,
  literalExpandedContainerStyles ,
  literalContainerStyles ,
  make ,
}
/* literalHeaderStyles Not a pure module */
