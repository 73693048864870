// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as FramerMotion from "framer-motion";
import * as PortalMenu_deprecated from "./PortalMenu_deprecated.mjs";
import * as ImplementationStatusLight from "./implementationStatus/ImplementationStatusLight.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";
import * as ImplementationStatusPriority from "./implementationStatus/ImplementationStatusPriority.mjs";
import * as FetchImplementationStatusUtils from "../../shared/utils/FetchImplementationStatusUtils.mjs";
import * as SourcePillImplementationStatusPopup from "./implementationStatus/SourcePillImplementationStatusPopup.mjs";

var sourcePillStyles = Curry._1(Css.style, {
      hd: Css.alignItems("center"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.setAlpha(Css.black, 0.04)),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.setAlpha(Css.black, 0.05)),
              tl: {
                hd: Css.borderRadius(Css.px(100)),
                tl: {
                  hd: Css.paddingTop(Css.px(1)),
                  tl: {
                    hd: Css.paddingRight(Css.px(4)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(1)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(4)),
                        tl: {
                          hd: Css.marginRight(Css.px(2)),
                          tl: {
                            hd: Css.marginBottom(Css.px(4)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function avoCodegenImplementationStatus(isCodegenEnabled, codegenInvocationsFetcherState, validFunctionInvocations, invalidFunctionInvocations) {
  if (isCodegenEnabled) {
    if (codegenInvocationsFetcherState === "Ready") {
      return {
              TAG: /* Codegen */1,
              _0: /* Loaded */{
                _0: validFunctionInvocations,
                _1: invalidFunctionInvocations
              }
            };
    } else {
      return {
              TAG: /* Codegen */1,
              _0: /* Loading */0
            };
    }
  } else {
    return {
            TAG: /* Codegen */1,
            _0: /* Unknown */1
          };
  }
}

function SourcePill(Props) {
  var $$event = Props.event;
  var isEventModified = Props.isEventModified;
  var eventInvocations = Props.eventInvocations;
  var codegenInvocationsFetcherState = Props.codegenInvocationsFetcherState;
  var source = Props.source;
  var containerRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setIsHoveringOver = match[1];
  var isCodegenEnabled = Belt_List.someU($$event.includeSources, (function (includedSource) {
          if (includedSource.id === source.id) {
            return includedSource.includeInCodegen;
          } else {
            return false;
          }
        }));
  var validFunctionInvocations = FetchImplementationStatusUtils.getInvocationsFromList(source.id, true, undefined, undefined, eventInvocations);
  var invalidFunctionInvocations = FetchImplementationStatusUtils.getInvocationsFromList(source.id, false, undefined, undefined, eventInvocations);
  var inspectorImplementationData = ImplementationStatusContext.useEventSourceInspectorImplementationStatusSubscription($$event.name, source.id);
  var inspectorEventSourceImplementationProdStatus = inspectorImplementationData.implementationProdStatus;
  var inspectorEventSourceImplementationDevStatus = inspectorImplementationData.implementationDevStatus;
  var inspectorEventSourceImplementationStagingStatus = inspectorImplementationData.implementationStagingStatus;
  var inspectorImplementationLoadProdStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationProdStatus, /* Prod */0, /* Version */0);
  var inspectorImplementationLoadDevStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationDevStatus, /* Dev */1, /* TimeWindow */1);
  var inspectorImplementationLoadStagingStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationStagingStatus, /* Staging */2, /* TimeWindow */1);
  var codegenStatus = avoCodegenImplementationStatus(isCodegenEnabled, codegenInvocationsFetcherState, validFunctionInvocations, invalidFunctionInvocations);
  var inspectorStatuses = ImplementationStatusPriority.orderInspectorStatusesBasedOnIsEventModified(isEventModified, [
        inspectorImplementationLoadProdStatus,
        inspectorImplementationLoadDevStatus,
        inspectorImplementationLoadStagingStatus
      ]);
  var highlightedStatus = ImplementationStatusPriority.highlightedStatus(isEventModified, codegenStatus, inspectorStatuses);
  return React.createElement("div", {
              ref: containerRef,
              className: sourcePillStyles,
              onMouseEnter: (function (param) {
                  Curry._1(setIsHoveringOver, (function (param) {
                          return true;
                        }));
                }),
              onMouseLeave: (function (param) {
                  Curry._1(setIsHoveringOver, (function (param) {
                          return false;
                        }));
                })
            }, React.createElement(ImplementationStatusLight.make, {
                  implementationStatus: highlightedStatus
                }), React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  singleLine: true,
                  color: Styles.Color.light11,
                  children: AvoConfig.getSourceName(source)
                }), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: React.createElement(PortalMenu_deprecated.make, {
                        position: "Bottom",
                        distance: 0,
                        clickOutsideToDismiss: false,
                        visible: match[0],
                        onClose: (function (param) {
                            Curry._1(setIsHoveringOver, (function (param) {
                                    return false;
                                  }));
                          }),
                        referenceItemRef: containerRef,
                        children: React.createElement(FramerMotion.motion.div, {
                              animate: {
                                opacity: 1,
                                scale: 1
                              },
                              transition: {
                                duration: 0.2
                              },
                              initial: {
                                opacity: 0,
                                scale: 0.95
                              },
                              exit: {
                                opacity: 0,
                                scale: 0.95
                              },
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(4)),
                                    tl: /* [] */0
                                  }),
                              key: "implementation-status-popup",
                              children: React.createElement(SourcePillImplementationStatusPopup.make, {
                                    source: source,
                                    inspectorStatuses: inspectorStatuses,
                                    codegenStatus: codegenStatus,
                                    event: $$event,
                                    isEventModified: isEventModified
                                  })
                            })
                      })
                }));
}

function SourcePill$Simple(Props) {
  var $$event = Props.event;
  var isEventModified = Props.isEventModified;
  var eventInvocations = Props.eventInvocations;
  var codegenInvocationsFetcherState = Props.codegenInvocationsFetcherState;
  var source = Props.source;
  var size = Props.size;
  var containerRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setIsHoveringOver = match[1];
  var isCodegenEnabled = Belt_List.someU($$event.includeSources, (function (includedSource) {
          if (includedSource.id === source.id) {
            return includedSource.includeInCodegen;
          } else {
            return false;
          }
        }));
  var validFunctionInvocations = FetchImplementationStatusUtils.getInvocationsFromList(source.id, true, undefined, undefined, eventInvocations);
  var invalidFunctionInvocations = FetchImplementationStatusUtils.getInvocationsFromList(source.id, false, undefined, undefined, eventInvocations);
  var inspectorImplementationData = ImplementationStatusContext.useEventSourceInspectorImplementationStatusSubscription($$event.name, source.id);
  var inspectorEventSourceImplementationProdStatus = inspectorImplementationData.implementationProdStatus;
  var inspectorEventSourceImplementationDevStatus = inspectorImplementationData.implementationDevStatus;
  var inspectorEventSourceImplementationStagingStatus = inspectorImplementationData.implementationStagingStatus;
  var inspectorImplementationLoadProdStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationProdStatus, /* Prod */0, /* Version */0);
  var inspectorImplementationLoadDevStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationDevStatus, /* Dev */1, /* TimeWindow */1);
  var inspectorImplementationLoadStagingStatus = ImplementationStatusContext.inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationStagingStatus, /* Staging */2, /* TimeWindow */1);
  var codegenStatus = avoCodegenImplementationStatus(isCodegenEnabled, codegenInvocationsFetcherState, validFunctionInvocations, invalidFunctionInvocations);
  var inspectorStatuses = ImplementationStatusPriority.orderInspectorStatusesBasedOnIsEventModified(isEventModified, [
        inspectorImplementationLoadProdStatus,
        inspectorImplementationLoadDevStatus,
        inspectorImplementationLoadStagingStatus
      ]);
  var highlightedStatus = ImplementationStatusPriority.highlightedStatus(isEventModified, codegenStatus, inspectorStatuses);
  return React.createElement("div", {
              ref: containerRef,
              onMouseEnter: (function (param) {
                  Curry._1(setIsHoveringOver, (function (param) {
                          return true;
                        }));
                }),
              onMouseLeave: (function (param) {
                  Curry._1(setIsHoveringOver, (function (param) {
                          return false;
                        }));
                })
            }, React.createElement(ImplementationStatusLight.make, {
                  implementationStatus: highlightedStatus,
                  size: size
                }), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: React.createElement(PortalMenu_deprecated.make, {
                        position: "Bottom",
                        distance: 0,
                        clickOutsideToDismiss: false,
                        visible: match[0],
                        onClose: (function (param) {
                            Curry._1(setIsHoveringOver, (function (param) {
                                    return false;
                                  }));
                          }),
                        referenceItemRef: containerRef,
                        children: React.createElement(FramerMotion.motion.div, {
                              animate: {
                                opacity: 1,
                                scale: 1
                              },
                              transition: {
                                duration: 0.2
                              },
                              initial: {
                                opacity: 0,
                                scale: 0.95
                              },
                              exit: {
                                opacity: 0,
                                scale: 0.95
                              },
                              className: Curry._1(Css.style, {
                                    hd: Css.padding(Css.px(4)),
                                    tl: /* [] */0
                                  }),
                              key: "implementation-status-popup",
                              children: React.createElement(SourcePillImplementationStatusPopup.make, {
                                    source: source,
                                    inspectorStatuses: inspectorStatuses,
                                    codegenStatus: codegenStatus,
                                    event: $$event,
                                    isEventModified: isEventModified
                                  })
                            })
                      })
                }));
}

var Simple = {
  make: SourcePill$Simple
};

var make = SourcePill;

export {
  sourcePillStyles ,
  avoCodegenImplementationStatus ,
  make ,
  Simple ,
}
/* sourcePillStyles Not a pure module */
