// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Hooks from "../Hooks.mjs";
import * as Icons from "../Icons.mjs";
import * as Title from "../Title.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Actions from "../actions.mjs";
import * as AvoTable from "../AvoTable.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "../IconErase.mjs";
import * as IconTrees from "../IconTrees.mjs";
import * as Timestamp from "../Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import Downloadjs from "downloadjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as ActivityLog from "../ActivityLog.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as IconChevron from "../IconChevron.mjs";
import * as RouterStore from "../RouterStore.mjs";
import * as ExportFormat from "../../../shared/models/ExportFormat.mjs";
import * as TrialRequest from "../TrialRequest.mjs";
import * as FetchActivity from "../FetchActivity.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as SegmentControl from "../SegmentControl.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as DrawerContainer from "../DrawerContainer.mjs";
import * as EntityLightning from "../EntityLightning.mjs";
import * as FirebaseFetchers from "../FirebaseFetchers.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as PortalMenuAnimator from "../PortalMenuAnimator.mjs";
import * as OrganizationContext from "../OrganizationContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as PortalMenu_deprecated from "../PortalMenu_deprecated.mjs";
import * as BranchStateFirebaseModel from "../../../shared/models/BranchStateFirebaseModel.mjs";
import * as BranchOverviewRow__Merged from "./BranchOverviewRow__Merged.mjs";
import * as BranchOverviewRow__Unmerged from "./BranchOverviewRow__Unmerged.mjs";
import * as BranchOverview__ViewContext from "./BranchOverview__ViewContext.mjs";

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.height(Css.pct(100.0)),
          tl: /* [] */0
        }
      }
    });

var pageContent = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.overflow("auto"),
            tl: /* [] */0
          }
        }
      }
    });

var pageHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.marginTop(Css.px(32)),
            tl: {
              hd: Css.marginLeft(Css.px(48)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var branchesBox = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(32)),
      tl: {
        hd: Css.marginBottom(Css.px(32)),
        tl: {
          hd: Css.marginLeft(Css.px(32)),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.overflowX("auto"),
                  tl: {
                    hd: Css.flexShrink(0.0),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var branchesMainRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.light01),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.cursor("pointer"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var noBranches = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding(Css.px(80)),
            tl: /* [] */0
          }
        }
      }
    });

var defaultBranchLabel = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: {
        hd: Css.paddingLeft(Css.px(16)),
        tl: {
          hd: Css.textTransform("uppercase"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
              tl: /* [] */0
            }
          }
        }
      }
    });

var branchesHeader = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light02),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
          tl: {
            hd: Css.borderBottomWidth(Css.px(0)),
            tl: {
              hd: Css.borderTopLeftRadius(Styles.Border.radius),
              tl: {
                hd: Css.borderTopRightRadius(Styles.Border.radius),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light04),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Style = {
  root: root,
  pageContent: pageContent,
  pageHeader: pageHeader,
  branchesBox: branchesBox,
  branchesMainRow: branchesMainRow,
  noBranches: noBranches,
  defaultBranchLabel: defaultBranchLabel,
  branchesHeader: branchesHeader
};

function BranchOverview$BranchName(Props) {
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var currentBranchOpenAction = FirebaseFetcherHooks.useBranchOpenAction(schemaId, branchId, "All", undefined, undefined);
  return Belt_Option.mapWithDefault(currentBranchOpenAction, "main", (function (action) {
                var match = action.contents;
                if (typeof match === "object" && match.NAME === "OpenBranch") {
                  return match.VAL[1];
                } else {
                  return "N/A";
                }
              }));
}

var BranchName = {
  make: BranchOverview$BranchName
};

var activityBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.width(Css.px(296)),
          tl: {
            hd: Css.flexShrink(0.0),
            tl: {
              hd: Css.margin(Css.px(32)),
              tl: {
                hd: Css.marginBottom(Css.px(16)),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var activityHeaderStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.padding(Css.px(10)),
          tl: {
            hd: Css.paddingLeft(Css.px(16)),
            tl: {
              hd: Css.paddingRight(Css.px(16)),
              tl: {
                hd: Css.height(Css.px(52)),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light07),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var activityListStyles = Curry._1(Css.style, {
      hd: Css.overflow("auto"),
      tl: {
        hd: Css.borderBottomLeftRadius(Css.px(4)),
        tl: {
          hd: Css.borderBottomRightRadius(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

function activityLogSwitcherButtonStyles(enabled) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.padding(Css.px(4)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(6)),
                        tl: {
                          hd: Css.paddingRight(Css.px(6)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexDirection("row"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.textAlign("left"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: enabled ? Curry._1(Css.style, {
                          hd: Css.transition({
                                NAME: "ms",
                                VAL: Styles.Duration.$$default
                              }, undefined, undefined, "background-color"),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.backgroundColor(Styles.Color.light02),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }) : Curry._1(Css.style, {
                          hd: Css.cursor("default"),
                          tl: /* [] */0
                        }),
                  tl: /* [] */0
                }
              }
            });
}

var activityLogSwitchMenuStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, {
            hd: Css.width(Css.px(300)),
            tl: {
              hd: Css.maxHeight(Css.px(450)),
              tl: {
                hd: Css.overflow("scroll"),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: /* [] */0
                }
              }
            }
          }),
      tl: {
        hd: PortalMenuAnimator.defaultStyles,
        tl: /* [] */0
      }
    });

var inputContainerStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top("zero"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var searchInputStyles = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "add",
            VAL: [
              Css.pct(100),
              Css.px(8)
            ]
          }),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.paddingLeft(Css.px(18)),
          tl: {
            hd: Css.fontSize(Css.px(14)),
            tl: {
              hd: Css.fontWeight({
                    NAME: "num",
                    VAL: 500
                  }),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.outlineStyle("none"),
                  tl: {
                    hd: Css.outlineWidth(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButtonStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.height(Css.pct(100)),
              tl: {
                hd: Css.padding(Css.px(2)),
                tl: {
                  hd: Css.marginLeft(Css.px(6)),
                  tl: {
                    hd: Css.marginRight(Css.px(16)),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.borderRadius(Css.px(2)),
                        tl: {
                          hd: Css.background("transparent"),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$default
                                }, undefined, undefined, "opacity"),
                            tl: {
                              hd: Css.opacity(disabled ? 0 : 1),
                              tl: {
                                hd: Css.unsafe("appearance", "none"),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.active({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.focus({
                                              hd: Css.outlineStyle("none"),
                                              tl: {
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var activityLogSwitcherListStyles = Curry._1(Css.style, {
      hd: Css.margin("zero"),
      tl: {
        hd: Css.padding(Css.px(0)),
        tl: {
          hd: Css.paddingTop(Css.px(10)),
          tl: {
            hd: Css.selector(":empty::after", {
                  hd: Css.unsafe("content", "'No results...'"),
                  tl: {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.padding(Css.px(18)),
                      tl: {
                        hd: Css.paddingTop(Css.px(11)),
                        tl: {
                          hd: Css.color(Styles.Color.light10),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var activityLogSwitcherItemStyles = Curry._1(Css.style, {
      hd: Css.listStyleType("none"),
      tl: /* [] */0
    });

var activityLogSwitcherItemButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.textAlign("left"),
              tl: {
                hd: Css.width(Css.pct(100.0)),
                tl: {
                  hd: Css.padding(Css.px(10)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(18)),
                    tl: {
                      hd: Css.hover({
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.selector(" p", {
                                    hd: Css.color(Styles.Color.light11),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function BranchOverview$ActivityBox(Props) {
  var globalSend = Props.globalSend;
  var model = Props.model;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var activityLogAvailability = AvoLimits.ActivityLogs.computeAvailability(workspace);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var activityLogSwitcherRef = React.useRef(null);
  var match = React.useState(function () {
        return false;
      });
  var setActivityLogSwitcherIsVisible = match[1];
  var activityLogSwitcherIsVisible = match[0];
  var match$1 = React.useState(function () {
        return currentBranch;
      });
  var setActivityLogBranch = match$1[1];
  var activityLogBranch = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setActivityLogSearchTerm = match$2[1];
  var activityLogSearchTerm = match$2[0];
  var debouncedActivityLogSearchTerm = Hooks.useDebounced((function (param) {
          return activityLogSearchTerm;
        }), 500);
  var openBranchesArray = React.useMemo((function () {
          return Belt_Array.concat([{
                        branchId: "master",
                        branchName: "main"
                      }], Belt_Array.mapU(Belt_Array.reverse(Belt_List.toArray(openBranches)), (function (param) {
                            return {
                                    branchId: param[0],
                                    branchName: param[1]
                                  };
                          })));
        }), [openBranches]);
  var filteredOpenBranches = React.useMemo((function () {
          return Fuzzaldrin.filter(openBranchesArray, activityLogSearchTerm, {
                      key: "branchName",
                      maxResults: 100
                    });
        }), [
        activityLogSearchTerm,
        openBranchesArray
      ]);
  var hasOpenBranches = openBranches !== /* [] */0;
  Hooks.useDidUpdate1((function (param) {
          if (!activityLogSwitcherIsVisible) {
            Curry._1(setActivityLogSearchTerm, (function (param) {
                    return "";
                  }));
          }
          AnalyticsRe.activityLogMenuInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, undefined, activityLogSwitcherIsVisible ? "MenuOpened" : "MenuClosed");
        }), [activityLogSwitcherIsVisible]);
  Hooks.useDidUpdate1((function (param) {
          AnalyticsRe.activityLogMenuInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, undefined, "SearchTermEntered");
        }), [debouncedActivityLogSearchTerm]);
  var onBranchChosen = React.useCallback((function (branch) {
          return function (_event) {
            Curry._1(setActivityLogSwitcherIsVisible, (function (param) {
                    return false;
                  }));
            Curry._1(setActivityLogBranch, (function (param) {
                    var branchId = branch.branchId;
                    if (branchId === "master") {
                      return "Master";
                    } else {
                      return {
                              NAME: "Branch",
                              VAL: branchId
                            };
                    }
                  }));
            AnalyticsRe.activityLogMenuInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, branch.branchId, "BranchChosen");
          };
        }), [schemaBundle]);
  return React.createElement("div", {
              className: activityBoxStyles
            }, React.createElement("div", {
                  className: activityHeaderStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.gap(Css.px(8)),
                              tl: {
                                hd: Css.flexShrink(0.0),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Activity"
                        }), React.createElement(EntityLightning.make, {
                          availability: activityLogAvailability,
                          planHint: AvoLimits.ActivityLogs.availableOnPlan(workspace)
                        })), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement("button", {
                      ref: activityLogSwitcherRef,
                      className: activityLogSwitcherButtonStyles(hasOpenBranches),
                      onClick: (function (param) {
                          if (hasOpenBranches) {
                            return Curry._1(setActivityLogSwitcherIsVisible, (function (state) {
                                          return !state;
                                        }));
                          }
                          
                        })
                    }, React.createElement(Icons.Branch.make, {
                          size: 12,
                          color: Styles.Color.light08
                        }), React.createElement(Spacer.make, {
                          width: 8
                        }), React.createElement($$Text.make, {
                          element: "Span",
                          size: "Small",
                          lines: 1,
                          color: Styles.Color.light11,
                          children: React.createElement(BranchOverview$BranchName, {
                                schemaId: workspace.id,
                                branchId: Actions.branchToId(activityLogBranch),
                                key: Actions.branchToId(activityLogBranch)
                              })
                        }), hasOpenBranches ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                width: 5
                              }), React.createElement(IconChevron.make, {
                                size: 6,
                                color: Styles.Color.light11
                              })) : null)), React.createElement("div", {
                  className: activityListStyles
                }, React.createElement(FetchActivity.make, {
                      schemaId: workspace.id,
                      currentBranch: activityLogBranch,
                      pageSize: 200,
                      children: (function (items, status, param, getMore) {
                          return React.createElement(ActivityLog.make, {
                                      availability: activityLogAvailability,
                                      items: items,
                                      status: status,
                                      getMore: getMore,
                                      openBranches: openBranches,
                                      globalSend: globalSend,
                                      currentBranch: activityLogBranch,
                                      model: model
                                    });
                        })
                    })), React.createElement(PortalMenu_deprecated.make, {
                  position: "Bottom",
                  placement: "End",
                  distance: 4,
                  clickOutsideToDismiss: true,
                  visible: activityLogSwitcherIsVisible,
                  onClose: (function (param) {
                      Curry._1(setActivityLogSwitcherIsVisible, (function (param) {
                              return false;
                            }));
                    }),
                  referenceItemRef: activityLogSwitcherRef,
                  children: React.createElement(PortalMenuAnimator.make, {
                        key_: "activity-log-switcher",
                        className: activityLogSwitchMenuStyles,
                        children: React.createElement("section", undefined, React.createElement("span", {
                                  className: inputContainerStyles
                                }, React.createElement("input", {
                                      className: searchInputStyles,
                                      autoFocus: true,
                                      placeholder: "Search branches",
                                      type: "text",
                                      value: activityLogSearchTerm,
                                      onChange: (function (e) {
                                          var value = e.target.value;
                                          Curry._1(setActivityLogSearchTerm, (function (param) {
                                                  return value;
                                                }));
                                        })
                                    }), React.createElement("button", {
                                      className: eraseButtonStyles(activityLogSearchTerm === ""),
                                      disabled: activityLogSearchTerm === "",
                                      onClick: (function (param) {
                                          Curry._1(setActivityLogSearchTerm, (function (param) {
                                                  return "";
                                                }));
                                        })
                                    }, React.createElement(IconErase.make, {
                                          color: Styles.Color.light07,
                                          hoverColor: Styles.Color.red
                                        }))), React.createElement("ul", {
                                  className: activityLogSwitcherListStyles
                                }, Belt_Array.mapU(filteredOpenBranches, (function (branch) {
                                        return React.createElement("li", {
                                                    key: branch.branchId,
                                                    className: activityLogSwitcherItemStyles
                                                  }, React.createElement("button", {
                                                        className: activityLogSwitcherItemButtonStyles,
                                                        onClick: Curry._1(onBranchChosen, branch)
                                                      }, React.createElement($$Text.make, {
                                                            size: "Medium",
                                                            weight: "Medium",
                                                            lines: 1,
                                                            color: Styles.Color.light10,
                                                            children: branch.branchName
                                                          })));
                                      }))))
                      })
                }));
}

var ActivityBox = {
  activityBoxStyles: activityBoxStyles,
  activityHeaderStyles: activityHeaderStyles,
  activityListStyles: activityListStyles,
  activityLogSwitcherButtonStyles: activityLogSwitcherButtonStyles,
  activityLogSwitchMenuStyles: activityLogSwitchMenuStyles,
  inputContainerStyles: inputContainerStyles,
  searchInputStyles: searchInputStyles,
  eraseButtonStyles: eraseButtonStyles,
  activityLogSwitcherListStyles: activityLogSwitcherListStyles,
  activityLogSwitcherItemStyles: activityLogSwitcherItemStyles,
  activityLogSwitcherItemButtonStyles: activityLogSwitcherItemButtonStyles,
  make: BranchOverview$ActivityBox
};

var branchesOverviewTableHeaders = [
  {
    name: "Name",
    width: 350,
    sortBy: (function (dataRow) {
        return dataRow.name;
      })
  },
  {
    name: "Status",
    width: 156,
    sortBy: (function (dataRow) {
        return BranchStateFirebaseModel.branchStatusToJs(dataRow.branchState.branchStatus);
      })
  },
  {
    name: "Created",
    sortBy: (function (dataRow) {
        return dataRow.branchState.creationDate.getTime().toString();
      }),
    defaultOrder: /* Desc */1,
    defaultSortedColumn: true
  },
  {
    name: "Creator"
  },
  {
    name: "Collaborators"
  },
  {
    name: "Reviewers"
  },
  {
    name: "Last activity",
    sortBy: (function (dataRow) {
        return Belt_Option.mapWithDefault(dataRow.branchState.lastActivityDate, "", (function (date) {
                      return date.getTime().toString();
                    }));
      }),
    defaultOrder: /* Desc */1
  },
  {
    name: "Last Active User"
  },
  {
    name: "Impacted Sources"
  },
  {
    name: "Comments"
  },
  {
    name: "Latest Codegens"
  },
  {
    name: "Last Published",
    sortBy: (function (dataRow) {
        return Belt_Option.mapWithDefault(dataRow.branchState.lastPublishedAt, "", (function (date) {
                      return date.getTime().toString();
                    }));
      }),
    defaultOrder: /* Desc */1
  }
];

var mergedBranchesOverviewTableHeaders = [
  {
    name: "Name",
    width: 350,
    sortBy: (function (dataRow) {
        return dataRow.name;
      })
  },
  {
    name: "Status",
    width: 156,
    sortBy: (function (dataRow) {
        return BranchStateFirebaseModel.branchStatusToJs(dataRow.branchState.branchStatus);
      })
  },
  {
    name: "Merged",
    defaultOrder: /* Desc */1,
    defaultSortedColumn: true
  },
  {
    name: "Creator"
  },
  {
    name: "Collaborators"
  },
  {
    name: "Last activity",
    sortBy: (function (dataRow) {
        return Belt_Option.mapWithDefault(dataRow.branchState.lastActivityDate, "", (function (date) {
                      return date.getTime().toString();
                    }));
      }),
    defaultOrder: /* Desc */1
  },
  {
    name: "Last Active User"
  },
  {
    name: "Impacted Workspaces"
  },
  {
    name: "Comments"
  },
  {
    name: "Latest Codegens"
  },
  {
    name: "Last Published",
    sortBy: (function (dataRow) {
        return Belt_Option.mapWithDefault(dataRow.branchState.lastPublishedAt, "", (function (date) {
                      return date.getTime().toString();
                    }));
      }),
    defaultOrder: /* Desc */1
  }
];

function getId(dataRow) {
  return dataRow.key;
}

var GlobalMergedBranchesOverviewTableConfig = {
  headers: mergedBranchesOverviewTableHeaders,
  getId: getId
};

var GlobalMergedBranchesOverviewTable = AvoTable.Make(GlobalMergedBranchesOverviewTableConfig);

var tr = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.after({
              hd: Css.position("absolute"),
              tl: {
                hd: Css.unsafe("content", "' '"),
                tl: {
                  hd: Css.top(Css.px(-101)),
                  tl: {
                    hd: Css.left(Css.px(0)),
                    tl: {
                      hd: Css.height(Css.px(100)),
                      tl: {
                        hd: Css.width(Css.pct(100.0)),
                        tl: {
                          hd: Css.zIndex(3),
                          tl: {
                            hd: Css.pointerEvents("none"),
                            tl: {
                              hd: Css.background({
                                    NAME: "linearGradient",
                                    VAL: [
                                      {
                                        NAME: "deg",
                                        VAL: 180.0
                                      },
                                      [
                                        [
                                          Css.pct(0.0),
                                          Styles.Color.setAlpha(Styles.Color.white, 0.0)
                                        ],
                                        [
                                          Css.pct(100.0),
                                          Styles.Color.setAlpha(Styles.Color.white, 0.9)
                                        ]
                                      ]
                                    ]
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var th = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.zIndex(3),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.paddingTop(Css.px(32)),
              tl: {
                hd: Css.paddingBottom(Css.px(32)),
                tl: {
                  hd: Css.width(Css.pct(100.0)),
                  tl: {
                    hd: Css.margin(Css.px(0)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var Style$1 = {
  tr: tr,
  th: th
};

function BranchOverview$ClosedBranchesFooterForFree(Props) {
  var userId = Props.userId;
  var match = TrialRequest.useTrialRequest(undefined);
  var requestTrial = match[1];
  var status = match[0];
  var workspace = WorkspaceContext.use(undefined);
  var trialAvailableOrPending = status === /* Available */2 || status === /* Pending */4;
  return React.createElement("tr", {
              className: tr
            }, React.createElement("th", {
                  className: th,
                  colSpan: 3
                }, React.createElement(Title.make, {
                      children: trialAvailableOrPending ? "Unlock full branch history" : "Upgrade for full branch history",
                      size: "Tiny"
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Regular",
                      color: Styles.Color.light10,
                      maxWidth: Css.px(500),
                      children: "Our Team plan allows you to gain oversight over all your closed branches in your workspace. " + (
                        trialAvailableOrPending ? "Start your free trial" : "Upgrade now"
                      ) + " to browse your history."
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(Button.make, {
                      label: trialAvailableOrPending ? "Start 14 day trial" : "Upgrade Now",
                      loading: status === /* Pending */4,
                      onClick: (function (param) {
                          if (status === /* Available */2) {
                            Curry._1(requestTrial, userId).then(function (param) {
                                  AnalyticsRe.workspaceTrialStarted(workspace.id, Date.now() / 1000.0, workspace.name);
                                  return Promise.resolve(undefined);
                                });
                            return ;
                          } else {
                            return Router.Schema.pushSchemaRoute(undefined, undefined, "billing");
                          }
                        }),
                      size: "large"
                    })));
}

var ClosedBranchesFooterForFree = {
  Style: Style$1,
  make: BranchOverview$ClosedBranchesFooterForFree
};

function getBranchStats(schemaId) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                return fetch("/api/workspaces/" + schemaId + "/branches/export/v1", Fetch.RequestInit.make(/* Get */0, [
                                  [
                                    "Accept",
                                    "text/csv"
                                  ],
                                  [
                                    "Content-Type",
                                    "text/csv"
                                  ],
                                  [
                                    "Authorization",
                                    "Bearer " + token + ""
                                  ]
                                ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
              }).then(function (res) {
              var status = res.status;
              if (status !== 200) {
                console.error("Error while fetching branch stats", status, Fetch.$$Response.text(res));
                return Promise.resolve("");
              } else {
                return Fetch.$$Response.text(res);
              }
            });
}

function BranchOverview(Props) {
  var model = Props.model;
  var openBranches = Props.openBranches;
  var currentBranch = Props.currentBranch;
  var latestMasterAction = Props.latestMasterAction;
  var userId = Props.userId;
  var workspace = WorkspaceContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useState(function () {
        
      });
  var setLocalBranchStates = match[1];
  var localBranchStates = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setLocalSchemaNames = match$1[1];
  var localSchemaNames = match$1[0];
  var hasEmptyState = model.events === /* [] */0 && openBranches === /* [] */0;
  React.useEffect((function () {
          if (hasEmptyState) {
            AnalyticsRe.emptyStateDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "BranchesOverview", schemaBundle.schemaId);
          }
          
        }), []);
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  var match$2 = React.useState(function () {
        return /* OpenBranches */0;
      });
  var setView = match$2[1];
  var view = match$2[0];
  var tmp;
  switch (view) {
    case /* OpenBranches */0 :
        tmp = /* Active */1;
        break;
    case /* MergedBranches */1 :
        tmp = /* Merged */2;
        break;
    case /* ClosedBranches */2 :
        tmp = /* Closed */0;
        break;
    
  }
  var branchStatesQuery = FirebaseFetcherHooks.useBranchStates(tmp, workspace.id);
  var handleExport = function (param) {
    Curry._1(addToast, {
          message: "Starting branches export…",
          toastType: /* Info */2
        });
    AnalyticsRe.exportBranchesStarted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType);
    getBranchStats(workspace.id).then(function (exportedBranches) {
          if (exportedBranches === "") {
            return Curry._1(addToast, {
                        message: "Error exporting branches",
                        toastType: /* Error */1
                      });
          }
          var match = ExportFormat.toHeader(/* Csv */2);
          var mimeType = match[0];
          Downloadjs(new Blob([exportedBranches], {
                    encoding: "UTF-8",
                    type: mimeType
                  }), "" + workspace.name + "-branches." + match[1] + "", mimeType);
          Curry._1(addToast, {
                message: "Branches successfully exported",
                toastType: /* Success */0
              });
        });
  };
  var branchStates = typeof branchStatesQuery === "object" ? branchStatesQuery.VAL : /* [] */0;
  var handleViewChange = function (newView) {
    Curry._1(setView, (function (param) {
            return newView;
          }));
    AnalyticsRe.branchesViewConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, newView === /* OpenBranches */0 ? "OpenBranches" : "ClosedBranches", schemaBundle.branchId, schemaBundle.schemaId);
  };
  var workspace$1 = React.useContext(WorkspaceContext.workspaceContext);
  var hasClosedBranches = AvoLimits.ClosedBranches.isAvailable(workspace$1);
  var branchStates$1 = React.useMemo((function () {
          if ((view === /* ClosedBranches */2 || view === /* MergedBranches */1) && !hasClosedBranches) {
            return Belt_Option.getWithDefault(Belt_List.take(branchStates, 3), branchStates);
          } else {
            return branchStates;
          }
        }), [
        view,
        hasClosedBranches,
        branchStatesQuery
      ]);
  React.useEffect((function () {
          if (view !== 1 || !isGlobalWorkspace) {
            
          } else {
            Promise.all(Belt_Array.map(Belt_List.toArray(branchStates$1), (function (branchState) {
                          var affectedWorkspaces = Belt_Option.getWithDefault(branchState.impactedSourceIds, []);
                          return FirebaseFetchers.fetchBranchStatesForSchemas(affectedWorkspaces, branchState.branchId).then(function (localBranchStates) {
                                      return Promise.resolve(localBranchStates);
                                    });
                        }))).then(function (localBranchStates) {
                  var localBranchStatesMap = Belt_MapString.fromArray(localBranchStates);
                  Curry._1(setLocalBranchStates, (function (param) {
                          return Caml_option.some(localBranchStatesMap);
                        }));
                  var schemaIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(Belt_Array.concatMany(Belt_MapString.valuesToArray(localBranchStatesMap)), (function (param) {
                                  return param.schemaId;
                                }))));
                  return FirebaseFetchers.getSchemas(schemaIds).then(function (localSchemas) {
                              var localSchemaNames = Belt_MapString.fromArray(Belt_Array.keepMap(localSchemas, (function (localSchema) {
                                          var name = localSchema.name;
                                          if (!(name == null)) {
                                            return [
                                                    localSchema.id,
                                                    name
                                                  ];
                                          }
                                          
                                        })));
                              Curry._1(setLocalSchemaNames, (function (param) {
                                      return localSchemaNames;
                                    }));
                            });
                });
          }
        }), [
        branchStates$1,
        view
      ]);
  var branchesOverviewTableData = Belt_Array.map(Belt_List.toArray(branchStates$1), (function (branchState) {
          return {
                  branchState: branchState,
                  workspace: workspace$1,
                  key: branchState.branchId,
                  schemaBundle: schemaBundle,
                  branchId: branchState.branchId,
                  model: model,
                  name: branchState.branchName,
                  selected: typeof currentBranch === "object" ? currentBranch.VAL === branchState.branchId : false,
                  userId: userId,
                  localBranchStates: Belt_Option.flatMap(localBranchStates, (function (states) {
                          return Belt_MapString.get(states, branchState.branchId);
                        }))
                };
        }));
  var mergedBranchesOverviewTableData = Belt_Array.map(Belt_List.toArray(branchStates$1), (function (branchState) {
          return {
                  branchState: branchState,
                  workspace: workspace$1,
                  key: branchState.branchId,
                  schemaBundle: schemaBundle,
                  branchId: branchState.branchId,
                  model: model,
                  name: branchState.branchName,
                  selected: typeof currentBranch === "object" ? currentBranch.VAL === branchState.branchId : false,
                  userId: userId,
                  localBranchStates: Belt_Option.flatMap(localBranchStates, (function (states) {
                          return Belt_MapString.get(states, branchState.branchId);
                        }))
                };
        }));
  var hasShallowStates = Belt_Array.some(branchesOverviewTableData, (function (param) {
          return param.branchState.isShallow;
        }));
  var getId = function (dataRow) {
    return dataRow.key;
  };
  var BranchesOverviewTableConfig = {
    headers: branchesOverviewTableHeaders,
    getId: getId
  };
  var BranchesOverviewTable = AvoTable.Make(BranchesOverviewTableConfig);
  var tmp$1;
  if (openBranches !== /* [] */0 || view !== /* OpenBranches */0) {
    var tmp$2;
    switch (view) {
      case /* OpenBranches */0 :
          tmp$2 = "Open Branches";
          break;
      case /* MergedBranches */1 :
          tmp$2 = "Merged Branches";
          break;
      case /* ClosedBranches */2 :
          tmp$2 = "Closed Branches";
          break;
      
    }
    var tmp$3;
    if (view === /* MergedBranches */1 && isGlobalWorkspace) {
      var tmp$4 = {
        paginate: true,
        paginationBoundaries: 2,
        sortable: workspace$1.plan.name === /* TeamV2 */8 || workspace$1.plan.name === /* TeamV2Annual */9 || workspace$1.plan.name === /* Enterprise */6,
        firstColumnSticky: true,
        headerSticky: true,
        show: 25,
        data: mergedBranchesOverviewTableData,
        children: (function (param) {
            return React.createElement(BranchOverviewRow__Merged.make, {
                        branchId: param.branchId,
                        branchState: param.branchState,
                        localBranchStates: Belt_Option.getWithDefault(param.localBranchStates, []),
                        localSchemaNames: localSchemaNames,
                        name: param.name,
                        workspace: param.workspace
                      });
          })
      };
      var tmp$5 = !hasClosedBranches ? Caml_option.some(React.createElement(BranchOverview$ClosedBranchesFooterForFree, {
                  userId: userId
                })) : undefined;
      if (tmp$5 !== undefined) {
        tmp$4.footer = Caml_option.valFromOption(tmp$5);
      }
      tmp$3 = React.createElement(GlobalMergedBranchesOverviewTable.make, tmp$4);
    } else {
      var tmp$6 = {
        paginate: true,
        paginationBoundaries: 2,
        sortable: workspace$1.plan.name === /* TeamV2 */8 || workspace$1.plan.name === /* TeamV2Annual */9 || workspace$1.plan.name === /* Enterprise */6,
        firstColumnSticky: true,
        headerSticky: true,
        show: 25,
        data: branchesOverviewTableData,
        children: (function (param) {
            return React.createElement(BranchOverviewRow__Unmerged.make, {
                        branchId: param.branchId,
                        branchState: param.branchState,
                        model: param.model,
                        name: param.name,
                        schemaBundle: param.schemaBundle,
                        selected: param.selected,
                        userId: param.userId,
                        workspace: param.workspace
                      });
          })
      };
      var tmp$7 = (view === /* ClosedBranches */2 || view === /* MergedBranches */1) && !hasClosedBranches ? Caml_option.some(React.createElement(BranchOverview$ClosedBranchesFooterForFree, {
                  userId: userId
                })) : undefined;
      if (tmp$7 !== undefined) {
        tmp$6.footer = Caml_option.valFromOption(tmp$7);
      }
      tmp$3 = React.createElement(BranchesOverviewTable.make, tmp$6);
    }
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("section", {
              className: Curry._1(Css.style, {
                    hd: Css.marginLeft(Css.px(32)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.overflow("auto"),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: 300.0
                                }, undefined, undefined, "padding"),
                            tl: {
                              hd: Css.paddingRight(Css.px(drawerItems === /* [] */0 ? 0 : (DrawerContainer.drawerWidth - 296 | 0) - 32 | 0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: branchesHeader
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: tmp$2
                    })), tmp$3), hasShallowStates ? React.createElement("section", {
                className: Curry._1(Css.style, {
                      hd: Css.marginBottom(Css.px(16)),
                      tl: {
                        hd: Css.marginLeft(Css.px(32)),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.padding(Css.px(16)),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Medium",
                    children: "💡"
                  }), React.createElement(Spacer.make, {
                    width: 8
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light11,
                    children: "Values noted as \"-\" will be calculated on the next branch interaction (e.g. change or comment)"
                  })) : null);
  } else {
    tmp$1 = React.createElement("section", {
          className: branchesBox
        }, React.createElement("div", {
              className: noBranches
            }, React.createElement(IconTrees.make, {
                  size: 100
                }), React.createElement(Spacer.make, {
                  height: 20
                }), React.createElement(Title.make, {
                  children: "There are no open branches",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  textAlign: "center",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(440),
                  children: null
                }, "Draft and collaborate on changes to your tracking plan without messing up your source of truth. Read more in ", React.createElement("a", {
                      className: Curry._1(Css.style, {
                            hd: Css.textDecoration("none"),
                            tl: {
                              hd: Css.color(Styles.Color.darkBlue),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.deepBlue),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      href: "https://www.avo.app/docs/workspace/branches",
                      rel: "noopener",
                      target: "_blank"
                    }, "our docs"), "."), React.createElement(Spacer.make, {
                  height: 20
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      })
                }, currentBranch === "Master" ? React.createElement(Button.make, {
                        label: "Create branch",
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "NewBranch",
                                    VAL: [
                                      undefined,
                                      ""
                                    ]
                                  }
                                });
                          }),
                        size: "large"
                      }) : null)));
  }
  return React.createElement(BranchOverview__ViewContext.make, {
              view: view,
              children: React.createElement("div", {
                    className: root
                  }, React.createElement("div", {
                        className: pageContent
                      }, React.createElement("header", {
                            className: pageHeader
                          }, React.createElement(Title.make, {
                                children: "Branches",
                                size: "Tiny"
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Medium",
                                color: Styles.Color.light10,
                                children: "(" + String(Belt_List.length(openBranches)) + " open)"
                              }), React.createElement(Spacer.make, {
                                width: 12
                              }), currentBranch === "Master" ? React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                      label: "Create branch",
                                      onClick: (function (param) {
                                          Curry._1(globalSend, {
                                                TAG: /* OpenModal */4,
                                                _0: {
                                                  NAME: "NewBranch",
                                                  VAL: [
                                                    undefined,
                                                    ""
                                                  ]
                                                }
                                              });
                                        })
                                    }), React.createElement(Spacer.make, {
                                      width: 12
                                    })) : null, React.createElement(SegmentControl.make, {
                                options: [
                                  [
                                    "Open",
                                    /* OpenBranches */0
                                  ],
                                  [
                                    "Merged",
                                    /* MergedBranches */1
                                  ],
                                  [
                                    "Closed",
                                    /* ClosedBranches */2
                                  ]
                                ],
                                onSelect: handleViewChange,
                                selected: view,
                                circular: true
                              }), React.createElement(Spacer.make, {
                                width: 8,
                                grow: 1.0
                              }), React.createElement(ContextMenu.make, {
                                options: [{
                                    NAME: "Option",
                                    VAL: {
                                      label: "Export branch overview .csv",
                                      onClick: handleExport
                                    }
                                  }]
                              })), React.createElement("section", {
                            className: branchesBox
                          }, React.createElement("div", {
                                className: defaultBranchLabel
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    weight: "Semi",
                                    color: Styles.Color.light10,
                                    children: "Default branch"
                                  })), React.createElement(Link.make, {
                                path: Curry._4(Router.Link.get, {
                                      NAME: "schema",
                                      VAL: workspace$1.id
                                    }, "master", "events", /* [] */0),
                                className: branchesMainRow,
                                children: null
                              }, React.createElement($$Text.make, {
                                    element: "Label",
                                    size: "Medium",
                                    weight: "Semi",
                                    color: Styles.Color.light11,
                                    children: "main"
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.light11,
                                    children: Belt_Option.mapWithDefault(latestMasterAction, null, (function (latestMasterAction) {
                                            return React.createElement(React.Fragment, undefined, "Latest activity ", React.createElement(Timestamp.make, {
                                                            date: latestMasterAction.createdAt
                                                          }));
                                          }))
                                  }))), tmp$1), React.createElement(BranchOverview$ActivityBox, {
                        globalSend: globalSend,
                        model: model,
                        currentBranch: currentBranch,
                        openBranches: openBranches
                      }))
            });
}

var ViewContext;

var activityLogWidth = 296;

var activityLogPadding = 32;

var activityHeaderHeight = 52;

var make = BranchOverview;

export {
  ViewContext ,
  activityLogWidth ,
  activityLogPadding ,
  activityHeaderHeight ,
  Style ,
  BranchName ,
  ActivityBox ,
  branchesOverviewTableHeaders ,
  mergedBranchesOverviewTableHeaders ,
  GlobalMergedBranchesOverviewTableConfig ,
  GlobalMergedBranchesOverviewTable ,
  ClosedBranchesFooterForFree ,
  getBranchStats ,
  make ,
}
/* root Not a pure module */
