// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as CollapsedContent from "./CollapsedContent.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function AuditLog$UserName(Props) {
  var userId = Props.userId;
  var user = FirebaseFetcherHooks.useUser(userId);
  if (typeof user === "object") {
    return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.VAL.email), AvoConfig.anonymousUserDisplayName);
  } else {
    return null;
  }
}

var UserName = {
  make: AuditLog$UserName
};

function AuditLog(Props) {
  var workspace = WorkspaceContext.use(undefined);
  var match = FirebaseFetcherHooks.usePagedActions(undefined, workspace.id, undefined, undefined);
  var fetchMore = match[1];
  var logs = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("center"),
                      tl: {
                        hd: Css.padding(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.padding2(Css.px(30), Css.px(50)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Css.px(10)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(Title.make, {
                      children: "Audit Log",
                      size: "Large"
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                            tl: {
                              hd: Css.marginTop(Css.px(8)),
                              tl: {
                                hd: Css.marginBottom(Css.px(16)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }), typeof logs === "object" ? React.createElement("div", undefined, React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement("td", undefined, "Timestamp"), React.createElement("td", undefined, "User"), React.createElement("td", undefined, "Action Name"), React.createElement("td", undefined, "Raw Action"))), React.createElement("tbody", undefined, Belt_Array.map(Belt_Array.keep(logs.VAL[1], (function (action) {
                                          return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(action.system), true, (function (prim) {
                                                        return !prim;
                                                      }));
                                        })), (function (action) {
                                      var match = action.contents;
                                      var tmp;
                                      if (typeof match === "object") {
                                        var variant = match.NAME;
                                        tmp = variant === "UpdateDestinationDisabledByDefault" ? "UpdateDestinationDisabledByDefault" : (
                                            variant === "AddProperty" ? "AddProperty" : (
                                                variant === "MergeBranch" ? "MergeBranch" : (
                                                    variant === "AddEventType" ? "AddEventType" : (
                                                        variant === "UpdateTriggerContent" ? "UpdateTriggerContent" : (
                                                            variant === "ConvertPropertyBundleToGlobalPropertyBundle" ? "ConvertPropertyBundleToGlobalPropertyBundle" : (
                                                                variant === "RemoveEventVariantTrigger" ? "RemoveEventVariantTrigger" : (
                                                                    variant === "DeprecatedRemovePropertyValidationMatch" ? "RemovePropertyValidationMatch" : (
                                                                        variant === "UpdateSourceDestinationMode" ? "UpdateSourceDestinationMode" : (
                                                                            variant === "TogglePropertyValidationMatchSource" ? "TogglePropertyValidationMatchSource" : (
                                                                                variant === "RemoveEventSpecificPropertyValueForAllEvents" ? "RemoveEventSpecificPropertyValueForAllEvents" : (
                                                                                    variant === "UpdatePropertyUniqueName" ? "UpdatePropertyUniqueName" : (
                                                                                        variant === "UpdateDestinationIncludeUserPropsWithEventProps" ? "UpdateDestinationIncludeUserPropsWithEventProps" : (
                                                                                            variant === "AddEvent" ? "AddEvent" : (
                                                                                                variant === "RemovePropertyPermanently" ? "RemovePropertyPermanently" : (
                                                                                                    variant === "CreateSavedView" ? "CreateSavedView" : (
                                                                                                        variant === "RemovePropertyFromWhitelist" ? "RemovePropertyFromWhitelist" : (
                                                                                                            variant === "SetBranchStatus" ? "SetBranchStatus" : (
                                                                                                                variant === "UpdateLibraryDestination" ? "UpdateLibraryDestination" : (
                                                                                                                    variant === "AddPropertyToEventVariant" ? "AddPropertyToEventVariant" : (
                                                                                                                        variant === "PaymentCompleted" ? "PaymentCompleted" : (
                                                                                                                            variant === "UpdateEventVariantTriggerSources" ? "UpdateEventVariantTriggerSources" : (
                                                                                                                                variant === "ImportDeprecated" ? "Import" : (
                                                                                                                                    variant === "ClearPropertyPinnedValueFromEventVariant" ? "ClearPropertyPinnedValueFromEventVariant" : (
                                                                                                                                        variant === "AddGoal" ? "AddCategory" : (
                                                                                                                                            variant === "UpdateIntegrationAutoPublish" ? "UpdateIntegrationAutoPublish" : (
                                                                                                                                                variant === "SetSourceOverrideOnVariant" ? "SetSourceOverrideOnVariant" : (
                                                                                                                                                    variant === "CreateDemoBranch" ? "CreateDemoBranch" : (
                                                                                                                                                        variant === "DeprecatedAddPropertyValidationMatch" ? "AddPropertyValidationMatch" : (
                                                                                                                                                            variant === "UpdateGoalDescription" ? "UpdateGoalDescription" : (
                                                                                                                                                                variant === "MigrateSourcesAndDestinationsToModel" ? "MigrateSourcesAndDestinationsToModel" : (
                                                                                                                                                                    variant === "DeprecatedRemovePropertyValidationMatches" ? "RemovePropertyValidationMatches" : (
                                                                                                                                                                        variant === "UpdatePropertyList" ? "UpdatePropertyList" : (
                                                                                                                                                                            variant === "SetMetricWhere" || variant === "SetMetricWhereV2" ? "SetMetricWhere" : (
                                                                                                                                                                                variant === "ReviewerRemoved" ? "ReviewerRemoved" : (
                                                                                                                                                                                    variant === "ConvertEventToGlobalEvent" ? "ConvertEventToGlobalEvent" : (
                                                                                                                                                                                        variant === "UpdatePropertyName" ? "UpdatePropertyName" : (
                                                                                                                                                                                            variant === "InviteMember" ? "InviteMember" : (
                                                                                                                                                                                                variant === "UpdatePropertyDescription" ? "UpdatePropertyDescription" : (
                                                                                                                                                                                                    variant === "AddDomainItems" ? "AddDomainItems" : (
                                                                                                                                                                                                        variant === "AddTag" ? "AddTag" : (
                                                                                                                                                                                                            variant === "ReorderEventsInGoal" || variant === "ReorderEventsInGoalV2" ? "ReorderEventsInCategory" : (
                                                                                                                                                                                                                variant === "ClearAllEventSpecificPropertyValuesFromProperty" ? "ClearAllEventSpecificPropertyValuesFromProperty" : (
                                                                                                                                                                                                                    variant === "ReorderMetricsInGoalV2" || variant === "ReorderMetricsInGoal" ? "ReorderMetricsInCategory" : (
                                                                                                                                                                                                                        variant === "UpdatePropertySendAs" ? "UpdatePropertySendAs" : (
                                                                                                                                                                                                                            variant === "RemoveEventVariantTriggerSource" ? "RemoveEventVariantTriggerSource" : (
                                                                                                                                                                                                                                variant === "AddRule" ? "AddRule" : (
                                                                                                                                                                                                                                    variant === "UpdateSourceName" ? "UpdateSourceName" : (
                                                                                                                                                                                                                                        variant === "BranchComment" ? "BranchComment" : (
                                                                                                                                                                                                                                            variant === "UpdatePropertyType" ? "UpdatePropertyType" : (
                                                                                                                                                                                                                                                variant === "LegacyIncludeDestinationInSource" ? "IncludeDestinationInSource" : (
                                                                                                                                                                                                                                                    variant === "AddPropertyRef" ? "AddPropertyRef" : (
                                                                                                                                                                                                                                                        variant === "RemoveInvite" ? "RemoveInvite" : (
                                                                                                                                                                                                                                                            variant === "OpenBranch" ? "OpenBranch" : (
                                                                                                                                                                                                                                                                variant === "ConvertPropertyToGlobalProperty" ? "ConvertPropertyToGlobalProperty" : (
                                                                                                                                                                                                                                                                    variant === "RemoveEvent" ? "RemoveEvent" : (
                                                                                                                                                                                                                                                                        variant === "EventComment" ? "EventComment" : (
                                                                                                                                                                                                                                                                            variant === "UpdateInspectorIssueStatus" ? "UpdateInspectorIssueStatus" : (
                                                                                                                                                                                                                                                                                variant === "AddMigrationDangerous" ? "AddMigrationDangerous" : (
                                                                                                                                                                                                                                                                                    variant === "CreateEventVariantTrigger" ? "CreateEventVariantTrigger" : (
                                                                                                                                                                                                                                                                                        variant === "DeleteLocalWorkspace" ? "DeleteLocalWorkspace" : (
                                                                                                                                                                                                                                                                                            variant === "PropertyGroupComment" ? "PropertyGroupComment" : (
                                                                                                                                                                                                                                                                                                variant === "ExcludeEventFromSourceV2" ? "ExcludeEventFromSourceV2" : (
                                                                                                                                                                                                                                                                                                    variant === "CreateGroupType" ? "CreateGroupType" : (
                                                                                                                                                                                                                                                                                                        variant === "CollaboratorAdded" ? "CollaboratorAdded" : (
                                                                                                                                                                                                                                                                                                            variant === "LegacyToggleDestination" ? "ToggleDestination" : (
                                                                                                                                                                                                                                                                                                                variant === "DeletePublicBranchImplementation" ? "DeletePublicBranchImplementation" : (
                                                                                                                                                                                                                                                                                                                    variant === "ClearSourceOverrideOnVariant" ? "ClearSourceOverrideOnVariant" : (
                                                                                                                                                                                                                                                                                                                        variant === "DeleteSource" ? "DeleteSource" : (
                                                                                                                                                                                                                                                                                                                            variant === "ReorderEventsInMetric" || variant === "ReorderEventsInMetricV2" ? "ReorderEventsInMetric" : (
                                                                                                                                                                                                                                                                                                                                variant === "UpdatePropertyRegexOverrideOnEventVariant" ? "UpdatePropertyRegexOverrideOnEventVariant" : (
                                                                                                                                                                                                                                                                                                                                    variant === "RemoveMetricGroupByV2" || variant === "RemoveMetricGroupBy" ? "RemoveMetricGroupBy" : (
                                                                                                                                                                                                                                                                                                                                        variant === "UpdateProgrammingLanguage" ? "UpdateProgrammingLanguage" : (
                                                                                                                                                                                                                                                                                                                                            variant === "ConvertEventToGlobalEventV2" ? "ConvertEventToGlobalEventV2" : (
                                                                                                                                                                                                                                                                                                                                                variant === "AddMetric" ? "AddMetric" : (
                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateTriggerSources" ? "UpdateTriggerSources" : (
                                                                                                                                                                                                                                                                                                                                                        variant === "MetricComment" ? "MetricComment" : (
                                                                                                                                                                                                                                                                                                                                                            variant === "UpdateSourcePlatformV2" ? "UpdateSourcePlatformV2" : (
                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateSourcePlatformV3" ? "UpdateSourcePlatformV3" : (
                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateEventUniqueNameV2" ? "UpdateEventUniqueNameV2" : (
                                                                                                                                                                                                                                                                                                                                                                        variant === "CreateEventTrigger" ? "CreateEventTrigger" : (
                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdatePropertyRegexValidation" ? "UpdatePropertyRegexValidation" : (
                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateGroupTypeName" ? "UpdateGroupTypeName" : (
                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemoveDestinationApiKey" ? "RemoveDestinationApiKey" : (
                                                                                                                                                                                                                                                                                                                                                                                        variant === "DeleteServiceAccount" ? "DeleteServiceAccount" : (
                                                                                                                                                                                                                                                                                                                                                                                            variant === "RemoveEventSpecificPropertyValue" ? "RemoveEventSpecificPropertyValue" : (
                                                                                                                                                                                                                                                                                                                                                                                                variant === "Archive" ? "Archive" : (
                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemovePropertyRefV2Deprecated" ? "RemovePropertyRefV2" : (
                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdateIntegrationConfig" ? "UpdateIntegrationConfig" : (
                                                                                                                                                                                                                                                                                                                                                                                                            variant === "ClearEventVariantPropertyOverride" ? "ClearEventVariantPropertyOverride" : (
                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdatePropertyOperation" ? "UpdatePropertyOperation" : (
                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "TogglePropertyExcludedSource" ? "TogglePropertyExcludedSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "RemoveTag" ? "RemoveTag" : (
                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddUserToGroup" ? "AddUserToGroup" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "SetEventSpecificPropertyValueSources" ? "SetEventSpecificPropertyValueSources" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "MigrateExcludeEventsToIncludeEvents" ? "MigrateExcludeEventsToIncludeEvents" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "RemoveDomainItems" ? "RemoveDomainItems" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdateEventVariantDescription" ? "UpdateEventVariantDescription" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "DeprecatedUpdatePropertyValidationMinOrMax" ? "UpdatePropertyValidationMinOrMax" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemovePropertyRefV3" ? "RemovePropertyRef" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "InspectorIssueComment" ? "InspectorIssueComment" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "RemoveIntegration" ? "RemoveIntegration" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "SetPropertyAbsenceOnVariant" ? "SetPropertyAbsenceOnVariant" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "IntegrationComment" ? "IntegrationComment" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdateDestinationApiKey" ? "UpdateDestinationApiKey" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdateDestinationName" ? "UpdateDestinationName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "ToggleIncludeEventInCodegenForSource" ? "ToggleIncludeEventInCodegenForSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateLocalWorkspaceName" ? "UpdateLocalWorkspaceName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdatePropertyOptional" ? "UpdatePropertyOptional" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "OverrideEventVariantSpecificPropertyValueToBeDisallowed" ? "OverrideEventVariantSpecificPropertyValueToBeDisallowed" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "IncludeEventInSourceV2" ? "IncludeEventInSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateSavedView" ? "UpdateSavedView" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "ConvertPropertyToGlobalPropertyV2" ? "ConvertPropertyToGlobalPropertyV2" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddSystemProperty" ? "AddSystemProperty" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateRule" ? "UpdateRule" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "GoalComment" ? "GoalComment" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "SetMetricGroupByV2" || variant === "SetMetricGroupBy" ? "SetMetricGroupBy" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" ? "OverrideEventVariantSpecificPropertyValueToBeAllowedOnAllSources" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "AddEventSpecificPropertyValue" ? "AddEventSpecificPropertyValue" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateMemberRole" ? "UpdateMemberRole" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "RemoveSourceSegmentIntegration" ? "RemoveSourceSegmentIntegration" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "IncludeEventInSource" ? "IncludeEventInSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateEventVariantTriggerDescription" ? "UpdateEventVariantTriggerDescription" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateEventDescription" ? "UpdateEventDescription" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "DuplicateMetric" ? "DuplicateMetric" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddEventToMetric" ? "AddEventToMetric" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "AddEventVariantTriggerSource" ? "AddEventVariantTriggerSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "CreateDestination" ? "CreateDestination" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdateNestedPropertyPinnedValue" ? "UpdateNestedPropertyPinnedValue" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddMetricToGoal" ? "AddMetricToCategory" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "AddEventSpecificPropertyValueForAllEvents" ? "AddEventSpecificPropertyValueForAllEvents" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "ExcludeDestinationFromEventSource" ? "ExcludeDestinationFromEventSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "ReviewerAdded" ? "ReviewerAdded" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "DeleteDestination" ? "DeleteDestination" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "GenerateWebhookSecret" ? "GenerateWebhookSecret" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdatePropertyGroupDescription" ? "UpdatePropertyGroupDescription" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "ExcludeEventFromSource" ? "ExcludeEventFromSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdateTriggerDescription" ? "UpdateTriggerDescription" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "AddPropertyToWhitelist" ? "AddPropertyToWhitelist" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources" ? "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "CopyChangesToBranch" ? "CopyChangesToBranch" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "IncludeDestinationInSourceV2" ? "IncludeDestinationInSourceV2" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "CreateIntegration" ? "CreateIntegration" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateDestinationAnalyticsTool" ? "UpdateDestinationAnalyticsTool" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "RemovePropertyFromGroup" ? "RemovePropertyFromGroup" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddEventToGoal" ? "AddEventToCategory" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "SubscriptionUpdated" ? "SubscriptionUpdated" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateDomainMemberRole" ? "UpdateDomainMemberRole" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "SubscriptionCancelled" ? "SubscriptionCancelled" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddTriggerSource" ? "AddTriggerSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "IncludeDestinationInEventSource" ? "IncludeDestinationInEventSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "PushGlobalRequirementsToTrackingPlan" ? "PushGlobalRequirementsToTrackingPlan" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "AddGroupTypeToLogEvent" ? "AddGroupTypeToLogEvent" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdatePropertyPinnedValue" ? "UpdatePropertyPinnedValue" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "PublishIntegration" ? "PublishIntegration" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemoveUserFromGroup" ? "RemoveUserFromGroup" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "RemovePropertyValidation" ? "RemovePropertyValidation" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddDomainMembers" ? "AddDomainMembers" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateGoalName" ? "UpdateGoalName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "CreateDomain" ? "CreateDomain" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdateDomainName" ? "UpdateDomainName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "DuplicateEvent" ? "DuplicateEvent" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "CreateEventVariant" ? "CreateEventVariant" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "AddPropertyValidation" ? "AddPropertyValidation" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "PullMaster" ? "PullMaster" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddEventSpecificPropertyValueForSomeEvents" ? "AddEventSpecificPropertyValueForSomeEvents" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateMetricName" ? "UpdateMetricName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemoveNestedPropertyPinnedValue" ? "RemoveNestedPropertyPinnedValue" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "CreateSource" ? "CreateSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdateSourceSegmentIntegration" ? "UpdateSourceSegmentIntegration" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "ArchiveDomain" ? "ArchiveDomain" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemoveEventType" ? "RemoveEventType" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "RemoveEventFromGoal" ? "RemoveEventFromCategory" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "ExcludeDestinationFromSource" ? "ExcludeDestinationFromSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "ReorderGoals" || variant === "ReorderGoalsV2" ? "ReorderGoals" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateMetricType" ? "UpdateMetricType" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "ClearPropertyExcludedSources" ? "ClearPropertyExcludedSources" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdateIntegrationName" ? "UpdateIntegrationName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "CreatePropertyGroup" ? "CreatePropertyGroup" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdatePropertyGroupName" ? "UpdatePropertyGroupName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "AddSourceConfig" ? "AddSourceConfig" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "SubscriptionStarted" ? "SubscriptionStarted" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateEventUniqueName" ? "UpdateEventUniqueName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "CreatePublicBranchImplementation" ? "CreatePublicBranchImplementation" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "DeprecatedRemovePropertyValidationMinOrMax" ? "RemovePropertyValidationMinOrMax" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "RemovePropertyFromEventVariant" ? "RemovePropertyFromEventVariant" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateIntegrationType" ? "UpdateIntegrationType" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemoveMetricWhereV2" || variant === "RemoveMetricWhere" ? "RemoveMetricWhere" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "DeleteComment" ? "DeleteComment" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "PropertyComment" ? "PropertyComment" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateEventName" ? "UpdateEventName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "CreateInspectorIssue" ? "CreateInspectorIssue" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "ResendInvite" ? "ResendInvite" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdateIntegrationFilters" ? "UpdateIntegrationFilters" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "CreateProperty" ? "CreateProperty" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdatePropertyOptionalWhenInObject" ? "UpdatePropertyOptionalWhenInObject" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "RemoveGroupTypeFromLogEvent" ? "RemoveGroupTypeFromLogEvent" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "ClearPropertyRegexOverrideFromEventVariant" ? "ClearPropertyRegexOverrideFromEventVariant" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "RemovePropertyGroupFromEvent" ? "RemovePropertyGroupFromEvent" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateMetricDescription" ? "UpdateMetricDescription" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdateDomainDescription" ? "UpdateDomainDescription" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "CreateLocalWorkspace" ? "CreateLocalWorskspace" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdateGlobalRequirements" ? "UpdateGlobalRequirements" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemoveMetricFromGoal" ? "RemoveMetricFromCategory" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdateEventVariantTriggerContent" ? "UpdateEventVariantTriggerContent" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "ConfigureInspectorValidation" ? "ConfigureInspectorValidation" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "CodeGenerated" ? "CodeGenerated" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "AddPropertyToGroup" ? "AddPropertyToGroup" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdateSourcePlatform" ? "UpdateSourcePlatform" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "UpdateDestinationDevApiKey" ? "UpdateDestinationDevApiKey" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "ArchiveSavedView" ? "ArchiveSavedView" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateLibraryName" ? "UpdateLibraryName" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "CloseBranch" ? "CloseBranch" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "RemoveEventFromMetric" ? "RemoveEventFromMetric" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "RemovePropertyPinnedValue" ? "RemovePropertyPinnedValue" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateDestinationProdApiKey" ? "UpdateDestinationProdApiKey" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "BranchInit" ? "BranchInit" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AutoPublishIntegration" ? "AutoPublishIntegration" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "RemoveEventTrigger" ? "RemoveEventTrigger" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "DeprecatedAddPropertyValidationMinOrMax" ? "AddPropertyValidationMinOrMax" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "CreateServiceAccount" ? "CreateServiceAccount" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "CollaboratorRemoved" ? "CollaboratorRemoved" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "RemoveGoal" ? "RemoveCategory" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemoveTriggerSource" ? "RemoveTriggerSource" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdatePropertyAbsence" ? "UpdatePropertyAbsence" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "Unarchive" ? "Unarchive" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "CompletedImport" ? "CompletedImport" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "ClearEventVariantSpecificPropertyValueOverride" ? "ClearEventVariantSpecificPropertyValueOverride" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "UpdateIntegrationDestinationIds" ? "UpdateIntegrationDestinationIds" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "RemoveMember" ? "RemoveMember" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "UpdatePropertyPinnedValueOnEventVariant" ? "UpdatePropertyPinnedValueOnEventVariant" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "RemoveSourceConfig" ? "RemoveSourceConfig" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "RemoveDomainMembers" ? "RemoveDomainMembers" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            variant === "AddSourceSegmentIntegration" ? "AddSourceSegmentIntegration" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                variant === "RemoveRule" ? "RemoveRule" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    variant === "UpdateTrackingPlanAuditConfig" ? "UpdateTrackingPlanAuditConfig" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        variant === "AddPropertyGroupToEvent" ? "AddPropertyGroupToEvent" : "RemoveMetric"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                                              )
                                                                                                                                                                                                                                          )
                                                                                                                                                                                                                                      )
                                                                                                                                                                                                                                  )
                                                                                                                                                                                                                              )
                                                                                                                                                                                                                          )
                                                                                                                                                                                                                      )
                                                                                                                                                                                                                  )
                                                                                                                                                                                                              )
                                                                                                                                                                                                          )
                                                                                                                                                                                                      )
                                                                                                                                                                                                  )
                                                                                                                                                                                              )
                                                                                                                                                                                          )
                                                                                                                                                                                      )
                                                                                                                                                                                  )
                                                                                                                                                                              )
                                                                                                                                                                          )
                                                                                                                                                                      )
                                                                                                                                                                  )
                                                                                                                                                              )
                                                                                                                                                          )
                                                                                                                                                      )
                                                                                                                                                  )
                                                                                                                                              )
                                                                                                                                          )
                                                                                                                                      )
                                                                                                                                  )
                                                                                                                              )
                                                                                                                          )
                                                                                                                      )
                                                                                                                  )
                                                                                                              )
                                                                                                          )
                                                                                                      )
                                                                                                  )
                                                                                              )
                                                                                          )
                                                                                      )
                                                                                  )
                                                                              )
                                                                          )
                                                                      )
                                                                  )
                                                              )
                                                          )
                                                      )
                                                  )
                                              )
                                          );
                                      } else {
                                        tmp = match === "StartedImport" ? "StartedImport" : (
                                            match === "MigratePropertyOptionalAndExcludedSourcesToAbsence" ? "MigratePropertyOptionalAndExcludedSourcesToAbsence" : (
                                                match === "SchemaAccessed" ? "SchemaAccessed" : (
                                                    match === "MigrateToEventSpecificAllowedPropertyValues" ? "MigrateToEventSpecificAllowedPropertyValues" : (
                                                        match === "MigrateSkipWebFnTagToIncludeInCodegen" ? "MigrateSkipWebFnTagToIncludeInCodegen" : (
                                                            match === "MigrateSourceDestinationMapping" ? "MigrateSourceDestinationMapping" : (
                                                                match === "ResetTrackingPlan" ? "ResetTrackingPlan" : "MigrateRemoveUnarchivedItemsFromArchive"
                                                              )
                                                          )
                                                      )
                                                  )
                                              )
                                          );
                                      }
                                      return React.createElement("tr", {
                                                  key: action.id,
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                                                        tl: {
                                                          hd: Css.padding2(Css.px(5), Css.px(0)),
                                                          tl: /* [] */0
                                                        }
                                                      })
                                                }, React.createElement("td", undefined, DateFns.format("MM/dd/yyyy HH:mm:ss", Belt_Option.getWithDefault(Models.toDateOption(action.createdAt), new Date())), " Z"), React.createElement("td", undefined, React.createElement(AuditLog$UserName, {
                                                          userId: action.createdBy
                                                        })), React.createElement("td", undefined, tmp), React.createElement("td", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.maxWidth(Css.px(200)),
                                                            tl: {
                                                              hd: Css.overflow("auto"),
                                                              tl: {
                                                                hd: Css.fontFamily(Styles.FontFamily.polar),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          })
                                                    }, React.createElement(CollapsedContent.make, {
                                                          collapseLabel: "Hide Raw",
                                                          expandLabel: "Show Raw",
                                                          children: (function (param) {
                                                              return JSON.stringify(action.contentsJson);
                                                            })
                                                        })));
                                    })))), typeof logs === "object" && logs.NAME === "Loaded" && logs.VAL[0] !== undefined ? React.createElement(Button.make, {
                              label: "Load more",
                              onClick: (function (param) {
                                  Curry._1(fetchMore, undefined);
                                })
                            }) : null) : (
                    logs === "Initial" ? React.createElement(LoadingCircle.make, {
                            color: Styles.Color.darkBlue
                          }) : "Error loading audit logs."
                  )));
}

var make = AuditLog;

export {
  UserName ,
  make ,
}
/* Css Not a pure module */
