// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Styles from "./styles.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as DiffComments from "./DiffComments.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as DiffComponents from "./DiffComponents.mjs";
import * as IconDescription from "./IconDescription.mjs";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IconArrowDownEast from "./IconArrowDownEast.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as DiffItemAuditIssues from "./DiffItemAuditIssues.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as DiffPropertyGlobalPill from "./DiffPropertyGlobalPill.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as DiffPropertyAllowedValues from "./DiffPropertyAllowedValues.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";

function printEventAbsence(absence, eventId, eventName) {
  if (typeof absence === "number") {
    return "Always sent with " + eventName;
  }
  if (absence.TAG === /* SometimesSent */0) {
    var description = absence._0;
    return "Sometimes sent with " + eventName + "" + (
            description === "" ? "" : ": " + description + ""
          );
  }
  var match = Belt_MapString.get(absence._0, eventId);
  if (match === undefined) {
    return "Always sent with " + eventName + "";
  }
  if (match.TAG !== /* AllSources */0) {
    return ;
  }
  var description$1 = match._0;
  if (!description$1) {
    return "Never sent with " + eventName;
  }
  var description$2 = description$1._0;
  return "Sometimes sent with " + eventName + "" + (
          description$2 === "" ? "" : ": " + description$2 + ""
        );
}

function renderAbsenceTextDiff(nextEvents, absence, nextAbsence) {
  var getStringForAbsence = function (absence) {
    if (typeof absence === "number") {
      return "Always sent for all events";
    }
    if (absence.TAG !== /* SometimesSent */0) {
      return "";
    }
    var description = absence._0;
    if (description === "") {
      return "Sometimes sent for all events";
    } else {
      return "Sometimes sent for all events: " + description;
    }
  };
  var nextAbsence$1;
  if (nextAbsence !== undefined && !(typeof nextAbsence === "number" || nextAbsence.TAG === /* SometimesSent */0)) {
    var absence$1 = nextAbsence._0;
    var absence$2 = Belt_List.map(nextEvents, (function ($$event) {
            return Belt_MapString.get(absence$1, $$event.id);
          }));
    nextAbsence$1 = Belt_List.everyU(absence$2, (function (absence) {
            return Caml_obj.equal(absence, {
                        TAG: /* AllSources */0,
                        _0: /* SometimesSent */{
                          _0: ""
                        }
                      });
          })) ? ({
          TAG: /* SometimesSent */0,
          _0: ""
        }) : nextAbsence;
  } else {
    nextAbsence$1 = nextAbsence;
  }
  var exit = 0;
  var absence$3;
  var nextAbsence$2;
  if (absence !== undefined) {
    var exit$1 = 0;
    var absence$4;
    if (typeof absence === "number") {
      if (nextAbsence$1 !== undefined) {
        if (typeof nextAbsence$1 === "number") {
          return Caml_option.some(null);
        }
        absence$4 = absence;
        exit$1 = 3;
      } else {
        absence$4 = absence;
        exit$1 = 3;
      }
    } else {
      if (absence.TAG !== /* SometimesSent */0) {
        if (nextAbsence$1 !== undefined) {
          return ;
        } else {
          return Caml_option.some(null);
        }
      }
      var description = absence._0;
      if (nextAbsence$1 !== undefined) {
        if (typeof nextAbsence$1 === "number") {
          absence$3 = absence;
          nextAbsence$2 = nextAbsence$1;
          exit = 2;
        } else if (nextAbsence$1.TAG === /* SometimesSent */0) {
          absence$4 = absence;
          exit$1 = 3;
        } else {
          var absence$5 = nextAbsence$1._0;
          var eventDiffs = Belt_Array.map(Belt_Array.keepMap(Belt_MapString.toArray(absence$5), (function (param) {
                      var sourceAbsence = param[1];
                      var eventId = param[0];
                      return Belt_Option.map(Belt_List.getBy(nextEvents, (function ($$event) {
                                        return $$event.id === eventId;
                                      })), (function ($$event) {
                                    return [
                                            eventId,
                                            $$event.name,
                                            sourceAbsence
                                          ];
                                  }));
                    })), (function (param) {
                  var eventName = param[1];
                  var eventId = param[0];
                  var printedEventAbsence = printEventAbsence({
                        TAG: /* SometimesSent */0,
                        _0: description
                      }, eventId, eventName);
                  var nextPrintedEventAbsence = printEventAbsence({
                        TAG: /* Mixed */1,
                        _0: absence$5
                      }, eventId, eventName);
                  if (printedEventAbsence !== undefined && nextPrintedEventAbsence !== undefined) {
                    return Caml_option.some(JsDiff.renderStringDiff(undefined, undefined, undefined, printedEventAbsence, nextPrintedEventAbsence));
                  }
                  
                }));
          if (Caml_obj.notequal(eventDiffs, []) && Belt_Array.every(eventDiffs, Belt_Option.isSome)) {
            return Caml_option.some(Belt_Array.map(Belt_Array.keepMap(eventDiffs, (function (diff) {
                                  return diff;
                                })), (function (diff) {
                              return React.createElement("div", undefined, diff);
                            })));
          } else {
            return ;
          }
        }
      } else {
        absence$4 = absence;
        exit$1 = 3;
      }
    }
    if (exit$1 === 3) {
      if (nextAbsence$1 === undefined) {
        return Caml_option.some(null);
      }
      if (typeof nextAbsence$1 === "number" || nextAbsence$1.TAG !== /* SometimesSent */0) {
        exit = 1;
      } else {
        absence$3 = absence$4;
        nextAbsence$2 = nextAbsence$1;
        exit = 2;
      }
    }
    
  } else {
    if (nextAbsence$1 === undefined) {
      return Caml_option.some(null);
    }
    var exit$2 = 0;
    if (typeof nextAbsence$1 === "number" || nextAbsence$1.TAG === /* SometimesSent */0) {
      exit$2 = 3;
    } else {
      exit = 1;
    }
    if (exit$2 === 3) {
      return Caml_option.some(JsDiff.renderStringDiff(undefined, undefined, undefined, "Always sent for all events", getStringForAbsence(nextAbsence$1)));
    }
    
  }
  switch (exit) {
    case 1 :
        var absence$6 = nextAbsence$1._0;
        var eventDiffs$1 = Belt_Array.map(Belt_Array.keepMap(Belt_MapString.toArray(absence$6), (function (param) {
                    var sourceAbsence = param[1];
                    var eventId = param[0];
                    return Belt_Option.map(Belt_List.getBy(nextEvents, (function ($$event) {
                                      return $$event.id === eventId;
                                    })), (function ($$event) {
                                  return [
                                          eventId,
                                          $$event.name,
                                          sourceAbsence
                                        ];
                                }));
                  })), (function (param) {
                return Belt_Option.map(printEventAbsence({
                                TAG: /* Mixed */1,
                                _0: absence$6
                              }, param[0], param[1]), (function (printedEventAbsence) {
                              return JsDiff.renderStringDiff(undefined, undefined, undefined, "", printedEventAbsence);
                            }));
              }));
        if (Caml_obj.notequal(eventDiffs$1, []) && Belt_Array.every(eventDiffs$1, Belt_Option.isSome)) {
          return Caml_option.some(Belt_Array.map(Belt_Array.keepMap(eventDiffs$1, (function (diff) {
                                return diff;
                              })), (function (diff) {
                            return React.createElement("div", undefined, diff);
                          })));
        } else {
          return ;
        }
    case 2 :
        return Caml_option.some(JsDiff.renderStringDiff(undefined, undefined, undefined, getStringForAbsence(absence$3), getStringForAbsence(nextAbsence$2)));
    
  }
}

var tableHeaderStyles = Curry._1(Css.style, {
      hd: Css.background(Styles.Color.light02),
      tl: {
        hd: Css.borderTopLeftRadius(Styles.Border.radius),
        tl: {
          hd: Css.borderTopRightRadius(Styles.Border.radius),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.padding2(Css.px(12), Css.px(16)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var tableBoxStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.overflow("auto"),
        tl: {
          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.borderBottomLeftRadius(Styles.Border.radius),
              tl: {
                hd: Css.borderBottomRightRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var tableStyles = Curry._1(Css.style, {
      hd: Css.borderSpacing(Css.px(0)),
      tl: {
        hd: Css.minWidth(Css.pct(100.0)),
        tl: /* [] */0
      }
    });

var tableHeaderCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light02),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.selector(":first-child", {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.selector(":last-child", {
                      hd: Css.borderRightWidth(Css.px(0)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.selector(":not(:first-child)", {
                        hd: Css.textAlign("center"),
                        tl: {
                          hd: Css.verticalAlign("middle"),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tableHeaderCellContentStyles = Curry._1(Css.style, {
      hd: Css.width(Css.px(100)),
      tl: {
        hd: Css.margin2(Css.px(0), Css.auto),
        tl: /* [] */0
      }
    });

var tableNameCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.borderLeftWidth(Css.px(0)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.background(Styles.Color.white),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var tableSelectCellStyles = Curry._1(Css.style, {
      hd: Css.selector(":last-child", {
            hd: Css.borderRightWidth(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.padding(Css.px(4)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.textAlign("center"),
              tl: {
                hd: Css.verticalAlign("middle"),
                tl: {
                  hd: Css.background(Styles.Color.white),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function renderPropertyMatrixDiff(masterModel, branchModel, masterAbsence, nextProperty, actions) {
  var matrix = PropertyAbsenceViewHelpers.getFullStateMatrix(ModelUtils.eventsSendingProperty(masterModel, undefined, nextProperty.id), masterModel.sources, masterAbsence);
  var nextMatrix = PropertyAbsenceViewHelpers.getFullStateMatrix(ModelUtils.eventsSendingProperty(branchModel, undefined, nextProperty.id), branchModel.sources, nextProperty.absence);
  var absenceUpdates = Belt_List.keepMap(actions, (function (action) {
          if (typeof action === "object" && action.NAME === "UpdatePropertyAbsence") {
            return action.VAL[1];
          }
          
        }));
  var changedAndAddedEvents = Belt_MapString.keep(nextMatrix, (function (eventId, nextSources) {
          var sources = Belt_MapString.get(matrix, eventId);
          var hasChange = sources !== undefined ? Caml_obj.notequal(Caml_option.valFromOption(sources), nextSources) : true;
          var hasAction = Belt_Option.isSome(Belt_List.getBy(absenceUpdates, (function (updateType) {
                      if (typeof updateType === "number") {
                        return true;
                      }
                      switch (updateType.TAG | 0) {
                        case /* Source */1 :
                            return false;
                        case /* Event */0 :
                        case /* EventSource */2 :
                            return updateType._0 === eventId;
                        
                      }
                    })));
          if (hasChange) {
            return hasAction;
          } else {
            return false;
          }
        }));
  var match = PropertyAbsenceViewHelpers.getSimplestModeForStateMatrix(matrix, masterAbsence);
  var match$1 = PropertyAbsenceViewHelpers.getSimplestModeForStateMatrix(nextMatrix, nextProperty.absence);
  if (match !== 1 && match < 3 && match$1 !== 1 && match$1 < 3) {
    var printSourceByEvent = function (matrixEvent) {
      return Belt_Option.mapWithDefault(Belt_Option.flatMap(matrixEvent, (function (sourceAbsence) {
                        return Belt_MapString.findFirstBy(sourceAbsence, (function (_sourceId, absence) {
                                      return absence !== /* Unavailable */0;
                                    }));
                      })), "Unavailable for ", (function (param) {
                    return PropertyAbsenceViewHelpers.getEventAndSourceStateString(param[1]) + " sent with ";
                  }));
    };
    return React.createElement($$Text.make, {
                element: "Div",
                size: "Small",
                color: Styles.Color.light10,
                children: Belt_Array.map(Belt_MapString.toArray(changedAndAddedEvents), (function (param) {
                        var eventId = param[0];
                        var eventName = Belt_Option.mapWithDefault(ModelUtils.getEventById(eventId, branchModel), "Untitled Event", (function ($$event) {
                                return $$event.name;
                              }));
                        return React.createElement("span", {
                                    key: eventId,
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("block"),
                                          tl: /* [] */0
                                        })
                                  }, JsDiff.renderStringDiff(undefined, true, false, printSourceByEvent(Belt_MapString.get(matrix, eventId)), printSourceByEvent(Caml_option.some(param[1]))), eventName);
                      }))
              });
  }
  var events = ModelUtils.eventsSendingProperty(branchModel, undefined, nextProperty.id);
  var modifiedMatrix = Belt_MapString.keep(nextMatrix, (function (eventId, param) {
          return Belt_MapString.has(changedAndAddedEvents, eventId);
        }));
  var modifiedSources = Belt_SetString.fromArray(Belt_Array.concatMany(Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(modifiedMatrix, (function (eventId, sourceMap) {
                      return Belt_MapString.keysToArray(Belt_MapString.keep(sourceMap, (function (sourceId, state) {
                                        var fromState = Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_MapString.get(matrix, eventId), (function (sources) {
                                                    return Belt_MapString.get(sources, sourceId);
                                                  })), /* Unavailable */0);
                                        return state !== fromState;
                                      })));
                    })))));
  var modifiedMatrix$1 = Belt_MapString.map(modifiedMatrix, (function (sourceMap) {
          return Belt_MapString.keep(sourceMap, (function (sourceId, param) {
                        return Belt_SetString.has(modifiedSources, sourceId);
                      }));
        }));
  var sourceColumns = Belt_List.toArray(Belt_List.keep(branchModel.sources, (function (source) {
              return Belt_SetString.has(modifiedSources, source.id);
            })));
  var modifiedEventsOrdered = Belt_SortArray.stableSortBy(Belt_Array.map(Belt_MapString.keysToArray(modifiedMatrix$1), (function (eventId) {
              var eventName = Belt_Option.mapWithDefault(Belt_List.getBy(events, (function ($$event) {
                          return $$event.id === eventId;
                        })), "Untitled", (function ($$event) {
                      return $$event.name;
                    }));
              return [
                      eventId,
                      eventName
                    ];
            })), (function (param, param$1) {
          return $$String.compare(param[1], param$1[1]);
        }));
  return React.createElement("div", undefined, React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: tableHeaderStyles
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Sent In " + String(Belt_List.length(events)) + " Events"
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Regular",
                      color: Styles.Color.light11,
                      children: "(Showing " + AvoUtils.plural(undefined, undefined, Belt_MapString.size(changedAndAddedEvents), "updated event") + " and " + AvoUtils.plural(undefined, undefined, Belt_SetString.size(modifiedSources), "updated source") + ")"
                    })), React.createElement("div", {
                  className: tableBoxStyles
                }, React.createElement("table", {
                      className: tableStyles
                    }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                  className: tableHeaderCellStyles
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.light11,
                                      children: "Event Name"
                                    })), Belt_Array.map(sourceColumns, (function (source) {
                                    return React.createElement("td", {
                                                key: source.id,
                                                className: tableHeaderCellStyles
                                              }, React.createElement("div", {
                                                    className: tableHeaderCellContentStyles
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        weight: "Semi",
                                                        singleLine: true,
                                                        color: Styles.Color.light11,
                                                        withWordBreak: false,
                                                        children: Belt_Option.getWithDefault(source.name, "Untitled")
                                                      })));
                                  })))), React.createElement("tbody", undefined, Belt_Array.map(modifiedEventsOrdered, (function (param) {
                                var eventId = param[0];
                                var sourceMap = Belt_Option.getWithDefault(Belt_MapString.get(modifiedMatrix$1, eventId), undefined);
                                return React.createElement("tr", {
                                            key: eventId
                                          }, React.createElement("td", {
                                                className: tableNameCellStyles
                                              }, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    weight: "Semi",
                                                    children: param[1]
                                                  })), Belt_Array.map(sourceColumns, (function (source) {
                                                  var fromState = Belt_Option.flatMap(Belt_MapString.get(matrix, eventId), (function (sources) {
                                                          return Belt_MapString.get(sources, source.id);
                                                        }));
                                                  var toState = Belt_MapString.get(sourceMap, source.id);
                                                  var tmp;
                                                  if (Belt_Option.isSome(fromState) || Belt_Option.isSome(toState)) {
                                                    var fromState$1 = Belt_Option.getWithDefault(fromState, /* Unavailable */0);
                                                    var toState$1 = Belt_Option.getWithDefault(toState, /* Unavailable */0);
                                                    tmp = React.createElement($$Text.make, {
                                                          size: "Small",
                                                          color: Styles.Color.light08,
                                                          children: JsDiff.renderStringDiff("inlineBlock", true, undefined, PropertyAbsenceViewHelpers.getEventAndSourceStateStringForBranchDiff(fromState$1), PropertyAbsenceViewHelpers.getEventAndSourceStateStringForBranchDiff(toState$1))
                                                        });
                                                  } else {
                                                    tmp = null;
                                                  }
                                                  return React.createElement("td", {
                                                              key: source.id,
                                                              className: tableSelectCellStyles
                                                            }, tmp);
                                                })));
                              }))))));
}

function renderSystemPropertyAbsenceDiff(masterModel, branchModel, masterAbsence, nextProperty) {
  var propertyAbsenceMap = PropertyAbsenceViewHelpers.getFullSysAbsenceMap(masterModel.sources, masterAbsence);
  var nextPropertyAbsenceMap = PropertyAbsenceViewHelpers.getFullSysAbsenceMap(branchModel.sources, nextProperty.absence);
  var match = PropertyAbsenceViewHelpers.getSimplestModeForAbsenceMap(propertyAbsenceMap, masterAbsence);
  var match$1 = PropertyAbsenceViewHelpers.getSimplestModeForAbsenceMap(nextPropertyAbsenceMap, nextProperty.absence);
  if (match < 2 && match$1 < 2) {
    var printSource = function (sourceAbsence) {
      return Belt_Option.getWithDefault(Belt_Option.map(sourceAbsence, (function (mode) {
                        return PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode) + " sent with all events";
                      })), "Always sent with all events");
    };
    var sourceId = Belt_Option.getWithDefault(Belt_Option.map(Belt_List.head(masterModel.sources), (function (source) {
                return source.id;
              })), "untitled");
    return React.createElement($$Text.make, {
                element: "Div",
                size: "Small",
                color: Styles.Color.light10,
                children: React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("block"),
                            tl: /* [] */0
                          })
                    }, JsDiff.renderStringDiff(undefined, true, false, printSource(Belt_MapString.get(propertyAbsenceMap, sourceId)), printSource(Belt_MapString.get(nextPropertyAbsenceMap, sourceId))), " on all sources")
              });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  children: React.createElement($$Text.make, {
                        element: "Span",
                        color: Styles.Color.light08,
                        children: "showing changes"
                      })
                }), React.createElement("div", {
                  className: tableBoxStyles
                }, React.createElement("table", {
                      className: tableStyles
                    }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                  className: tableHeaderCellStyles
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      color: Styles.Color.light11,
                                      children: "Source Name"
                                    })), React.createElement("td", {
                                  className: tableHeaderCellStyles
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Semi",
                                      children: "Presence"
                                    })))), React.createElement("tbody", undefined, Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(nextPropertyAbsenceMap, (function (sourceId, mode) {
                                    return React.createElement("tr", {
                                                key: sourceId
                                              }, React.createElement("td", {
                                                    className: tableNameCellStyles
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        weight: "Semi",
                                                        children: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_List.getBy(branchModel.sources, (function (source) {
                                                                        return source.id === sourceId;
                                                                      })), (function (source) {
                                                                    return source.name;
                                                                  })), "Untitled")
                                                      })), React.createElement("td", {
                                                    className: tableSelectCellStyles
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        color: Styles.Color.light08,
                                                        children: JsDiff.renderStringDiff("inlineBlock", true, undefined, PropertyAbsenceViewHelpers.getEventAndSourceStateString(Belt_Option.getWithDefault(Belt_MapString.get(propertyAbsenceMap, sourceId), /* Unavailable */0)), PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode))
                                                      })));
                                  })))))));
}

function DiffProperty$DiffAbsence(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var masterProperty = Props.masterProperty;
  var nextProperty = Props.nextProperty;
  var actions = Props.actions;
  var match = nextProperty.sendAs;
  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  height: 8
                }), Belt_Option.getWithDefault(Belt_Option.map(renderAbsenceTextDiff(branchModel.events, PropertyAbsenceViewHelpers.getDirectPropertyAbsenceForRelevantEventIds(masterProperty, masterModel), PropertyAbsenceViewHelpers.getDirectPropertyAbsenceForRelevantEventIds(nextProperty, branchModel)), (function (diff) {
                        return React.createElement($$Text.make, {
                                    element: "Div",
                                    size: "Small",
                                    color: Styles.Color.light10,
                                    children: diff
                                  });
                      })), match === 0 ? renderSystemPropertyAbsenceDiff(masterModel, branchModel, masterProperty.absence, nextProperty) : renderPropertyMatrixDiff(masterModel, branchModel, masterProperty.absence, nextProperty, actions)));
}

var DiffAbsence = {
  tableHeaderStyles: tableHeaderStyles,
  tableBoxStyles: tableBoxStyles,
  tableStyles: tableStyles,
  tableHeaderCellStyles: tableHeaderCellStyles,
  tableHeaderCellContentStyles: tableHeaderCellContentStyles,
  tableNameCellStyles: tableNameCellStyles,
  tableSelectCellStyles: tableSelectCellStyles,
  renderPropertyMatrixDiff: renderPropertyMatrixDiff,
  renderSystemPropertyAbsenceDiff: renderSystemPropertyAbsenceDiff,
  make: DiffProperty$DiffAbsence
};

function getMappingDestinationName(mappingDestination, branchModel) {
  if (typeof mappingDestination === "object") {
    return Belt_Option.mapWithDefault(ModelUtils.getDestinationById(mappingDestination.VAL, branchModel), "N/A", ModelUtils.getDestinationName);
  } else {
    return "all destinations";
  }
}

function DiffProperty(Props) {
  var branchStatus = Props.branchStatus;
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withCommentsAndCopyToBranch = Props.withCommentsAndCopyToBranch;
  var events = Props.events;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var hasCopyToBranchFeatureFlag = AppFeatureFlag.useFeatureFlag("CopyToBranch");
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Property") {
    return null;
  }
  var propertyId = match.VAL;
  var actions$1 = Belt_List.keepMap(actions, (function (param) {
          var item = param[0];
          if (typeof item === "object" && item.NAME === "Property" && propertyId === item.VAL) {
            return param[1];
          }
          
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          var variant = x.NAME;
          if (variant === "CreateProperty" || variant === "AddSystemProperty") {
            return true;
          } else {
            return variant === "AddProperty";
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Property";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var property = Belt_List.getBy(masterModel.properties, (function (x) {
          if (x.TAG === /* PropertyRef */0) {
            return false;
          } else {
            return x._0.id === propertyId;
          }
        }));
  var property$1 = property !== undefined && property.TAG !== /* PropertyRef */0 ? property._0 : TrackingPlanModel.emptyProperty(propertyId, "", /* EventProperty */1, "");
  var nextProperty = Belt_List.getBy(branchModel.properties, (function (x) {
          if (x.TAG === /* PropertyRef */0) {
            return false;
          } else {
            return x._0.id === propertyId;
          }
        }));
  var nextProperty$1 = nextProperty !== undefined && nextProperty.TAG !== /* PropertyRef */0 ? nextProperty._0 : TrackingPlanModel.emptyProperty(propertyId, "", /* EventProperty */1, "");
  var canCopyChangesFromBranch = hasCopyToBranchFeatureFlag && !BranchStatusModel.branchIsMergedOrMain(branchStatus) && Belt_Option.isNone(hasArchive) && withCommentsAndCopyToBranch;
  var excludedSourceActions = Belt_List.keep(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          var variant = x.NAME;
          if (variant === "ClearPropertyExcludedSources") {
            return true;
          } else {
            return variant === "TogglePropertyExcludedSource";
          }
        }));
  var ruleActions = Belt_List.keep(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          var variant = x.NAME;
          if (variant === "RemoveRule" || variant === "UpdateRule") {
            return true;
          } else {
            return variant === "AddRule";
          }
        }));
  var absenceActions = Belt_List.keep(actions$1, (function (action) {
          if (typeof action === "object") {
            return action.NAME === "UpdatePropertyAbsence";
          } else {
            return false;
          }
        }));
  var eventSpecificAllowedValuesActions = Belt_List.keep(actions$1, (function (action) {
          if (typeof action !== "object") {
            return false;
          }
          var variant = action.NAME;
          if (variant === "AddEventSpecificPropertyValueForSomeEvents" || variant === "AllowAllEventSpecificPropertyValuesOnAllEventsAndSources" || variant === "AddEventSpecificPropertyValueForAllEvents" || variant === "AddEventSpecificPropertyValue" || variant === "SetEventSpecificPropertyValueSources" || variant === "RemoveEventSpecificPropertyValue" || variant === "ClearAllEventSpecificPropertyValuesFromProperty") {
            return true;
          } else {
            return variant === "RemoveEventSpecificPropertyValueForAllEvents";
          }
        }));
  var tmp;
  if (withCommentsAndCopyToBranch) {
    var tmp$1 = {
      branchId: branchId,
      threadId: property$1.id,
      itemType: "Property",
      itemName: property$1.name,
      events: events
    };
    if (goToCommentId !== undefined) {
      tmp$1.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    tmp = React.createElement(DiffComments.make, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement(DiffComponents.Item.make, {
                  borderColor: changeType === "Create" ? Styles.Color.darkGreen : (
                      changeType === "Update" ? Styles.Color.light06 : Styles.Color.darkRed
                    ),
                  children: null,
                  onClick: (function (param) {
                      Router.Schema.pushDrawerItem(undefined, undefined, {
                            NAME: "property",
                            VAL: [
                              property$1.id,
                              undefined
                            ]
                          });
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderTopLeftRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.borderTopRightRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                tl: {
                                  hd: Css.padding(Css.px(20)),
                                  tl: {
                                    hd: Css.marginTop(Css.px(-15)),
                                    tl: {
                                      hd: Css.marginLeft(Css.px(-15)),
                                      tl: {
                                        hd: Css.marginRight(Css.px(-15)),
                                        tl: {
                                          hd: Css.marginBottom(Css.px(12)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement(DiffItemAuditIssues.make, {
                          branchStatus: branchStatus,
                          itemId: property$1.id,
                          children: React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.gap(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.flexShrink(0),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        children: JsDiff.renderStringDiff(undefined, true, undefined, property$1.name, nextProperty$1.name)
                                      })), React.createElement($$Text.make, {
                                    size: "Small",
                                    children: ModelUtils.hasMigrated(branchModel, "EventSpecificAllowedPropertyValues") ? JsDiff.renderStringDiff(undefined, true, undefined, PropertyUtils.printPropertyType(property$1, undefined, masterModel, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, false, undefined), PropertyUtils.printPropertyType(nextProperty$1, undefined, branchModel, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, false, undefined)) : JsDiff.renderStringDiff(undefined, true, undefined, PropertyUtils.printPropertyType(property$1, undefined, masterModel, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined, undefined), PropertyUtils.printPropertyType(nextProperty$1, undefined, branchModel, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined, undefined))
                                  }), React.createElement(DiffPropertyGlobalPill.make, {
                                    propertyId: nextProperty$1.id,
                                    globalRequirements: branchModel.globalRequirements
                                  }), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.flexGrow(1.0),
                                            tl: {
                                              hd: Css.justifyContent("flexEnd"),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }, canCopyChangesFromBranch ? React.createElement(Button.make, {
                                          label: "Copy",
                                          onClick: (function ($$event) {
                                              $$event.stopPropagation();
                                              Curry._1(globalSend, {
                                                    TAG: /* OpenModal */4,
                                                    _0: {
                                                      NAME: "CopyChangesToBranch",
                                                      VAL: [{
                                                          NAME: "property",
                                                          VAL: propertyId
                                                        }]
                                                    }
                                                  });
                                            }),
                                          style: "outline"
                                        }) : null, changeType === "Archive" ? React.createElement(ContextMenu.make, {
                                          options: [{
                                              NAME: "Option",
                                              VAL: {
                                                label: "Restore Property",
                                                onClick: (function (param) {
                                                    var conflictingProperties = Belt_List.keepMapU(branchModel.properties, (function (branchProperty) {
                                                            if (branchProperty.TAG === /* PropertyRef */0) {
                                                              return ;
                                                            }
                                                            var name = branchProperty._0.name;
                                                            if (property$1.name === name || property$1.name.toLowerCase() === name.toLowerCase() || Case.camel(property$1.name) === Case.camel(name) || Case.snake(property$1.name) === Case.snake(name)) {
                                                              return name;
                                                            }
                                                            
                                                          }));
                                                    if (conflictingProperties) {
                                                      if (conflictingProperties.tl) {
                                                        window.alert("Can't unarchive property because properties with conflicting names already exist: " + Belt_List.toArray(conflictingProperties).join(", "));
                                                      } else {
                                                        window.alert("Can't unarchive property because a property with a conflicting name already exists: " + conflictingProperties.hd);
                                                      }
                                                      return ;
                                                    } else {
                                                      return Curry.app(sendActions, [
                                                                  undefined,
                                                                  false,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  (function (_branch) {
                                                                      Router.Schema.pushDrawerItem({
                                                                            NAME: "branch",
                                                                            VAL: branchId
                                                                          }, undefined, {
                                                                            NAME: "property",
                                                                            VAL: [
                                                                              property$1.id,
                                                                              undefined
                                                                            ]
                                                                          });
                                                                      AnalyticsRe.itemUnarchived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, property$1.id, property$1.name, "Property", "BranchReview", schemaBundle.schemaId, schemaBundle.branchId);
                                                                    }),
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "Unarchive",
                                                                        VAL: {
                                                                          NAME: "Property",
                                                                          VAL: property$1.id
                                                                        }
                                                                      },
                                                                      {
                                                                        propertyId: property$1.id,
                                                                        propertyQuery: property$1.id
                                                                      }
                                                                    ]]
                                                                ]);
                                                    }
                                                  })
                                              }
                                            }]
                                        }) : null))
                        })), JsDiff.hasDiff(property$1.description, nextProperty$1.description) ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.gap(Css.px(12)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.flexShrink(0.0),
                                  tl: /* [] */0
                                })
                          }, React.createElement(IconDescription.make, {
                                size: 14
                              })), React.createElement($$Text.make, {
                            size: "Small",
                            withWordBreak: true,
                            children: JsDiff.renderStringDiff(undefined, undefined, undefined, property$1.description, nextProperty$1.description)
                          })) : null, Belt_List.toArray(Belt_List.map(excludedSourceActions, (function (action) {
                            var tmp;
                            if (typeof action === "object") {
                              var variant = action.NAME;
                              if (variant === "TogglePropertyExcludedSource") {
                                var match = action.VAL;
                                var sourceId = match[1];
                                tmp = match[2] ? React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.darkGreen),
                                              tl: /* [] */0
                                            })
                                      }, "Start sending property to " + Belt_Option.mapWithDefault(ModelUtils.getSourceById(sourceId, branchModel), "-", AvoConfig.getSourceName)) : React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.darkRed),
                                              tl: /* [] */0
                                            })
                                      }, "Stop sending property to " + Belt_Option.mapWithDefault(ModelUtils.getSourceById(sourceId, branchModel), "-", AvoConfig.getSourceName));
                              } else {
                                tmp = variant === "ClearPropertyExcludedSources" ? React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.darkGreen),
                                              tl: /* [] */0
                                            })
                                      }, "Send property to all sources") : null;
                              }
                            } else {
                              tmp = null;
                            }
                            return React.createElement("div", {
                                        key: JSON.stringify(Printer.printAction(action)),
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                    tl: {
                                                      hd: Css.margin2(Css.px(5), Css.px(0)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.marginRight(Css.px(10)),
                                                  tl: /* [] */0
                                                })
                                          }, React.createElement(IconArrowDownEast.make, {
                                                size: 12,
                                                color: Styles.Color.light07
                                              })), tmp);
                          }))), Belt_List.toArray(Belt_List.map(ruleActions, (function (action) {
                            var ruleId;
                            if (typeof action === "object") {
                              var variant = action.NAME;
                              ruleId = variant === "RemoveRule" || variant === "UpdateRule" || variant === "AddRule" ? action.VAL[0] : Pervasives.failwith("should never happen");
                            } else {
                              ruleId = Pervasives.failwith("should never happen");
                            }
                            var rule = ModelUtils.getRuleById(ruleId, masterModel);
                            var nextRule = ModelUtils.getRuleById(ruleId, branchModel);
                            var tmp;
                            if (rule !== undefined) {
                              if (nextRule !== undefined) {
                                var match = rule.definition;
                                var match$1 = nextRule.definition;
                                var variant$1 = match.NAME;
                                if (variant$1 === "NameMapping") {
                                  var match$2 = match.VAL;
                                  if (match$1.NAME === "NameMapping") {
                                    var match$3 = match$1.VAL;
                                    var destinationName = Belt_Option.mapWithDefault(match$2.destination, "no destinations", (function (param) {
                                            return getMappingDestinationName(param, branchModel);
                                          }));
                                    var nextDestinationName = Belt_Option.mapWithDefault(match$3.destination, "no destinations", (function (param) {
                                            return getMappingDestinationName(param, branchModel);
                                          }));
                                    tmp = React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement(IconArrowDownEast.make, {
                                              size: 8,
                                              color: Styles.Color.light12
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            }), JsDiff.renderStringDiff(undefined, undefined, undefined, "Send as \"" + Belt_Option.getWithDefault(match$2.name, "N/A") + "\" to " + destinationName + " on all events", "Send as \"" + Belt_Option.getWithDefault(match$3.name, "N/A") + "\" to " + nextDestinationName + " on all events"));
                                  } else {
                                    tmp = null;
                                  }
                                } else if (variant$1 === "NameInLocalWorkspace") {
                                  var match$4 = match.VAL;
                                  var workspace = match$4.workspace;
                                  if (match$1.NAME === "NameInLocalWorkspace") {
                                    var match$5 = match$1.VAL;
                                    var nextWorkspace = match$5.workspace;
                                    var workspaceName = workspace !== undefined ? (
                                        typeof workspace === "object" ? GlobalRequirementsUtils.getWorkspaceNameById(branchModel, workspace.VAL) : "all workspaces"
                                      ) : "no workspaces";
                                    var nextWorkspaceName = nextWorkspace !== undefined ? (
                                        typeof nextWorkspace === "object" ? GlobalRequirementsUtils.getWorkspaceNameById(branchModel, nextWorkspace.VAL) : "all workspaces"
                                      ) : "no workspaces";
                                    tmp = React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement(IconArrowDownEast.make, {
                                              size: 8,
                                              color: Styles.Color.light12
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            }), JsDiff.renderStringDiff(undefined, undefined, undefined, "Send as \"" + Belt_Option.getWithDefault(match$4.name, "N/A") + "\" to " + workspaceName + " on all events", "Send as \"" + Belt_Option.getWithDefault(match$5.name, "N/A") + "\" to " + nextWorkspaceName + " on all events"));
                                  } else {
                                    tmp = null;
                                  }
                                } else {
                                  tmp = null;
                                }
                              } else {
                                var match$6 = rule.definition;
                                var variant$2 = match$6.NAME;
                                if (variant$2 === "NameMapping") {
                                  var match$7 = match$6.VAL;
                                  var destinationName$1 = Belt_Option.mapWithDefault(match$7.destination, "no destinations", (function (param) {
                                          return getMappingDestinationName(param, branchModel);
                                        }));
                                  tmp = React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement(IconArrowDownEast.make, {
                                            size: 8,
                                            color: Styles.Color.darkRed
                                          }), React.createElement(Spacer.make, {
                                            width: 4
                                          }), JsDiff.renderStringDiff(undefined, undefined, undefined, "Send as \"" + Belt_Option.getWithDefault(match$7.name, "N/A") + "\" to " + destinationName$1 + " on all events", ""));
                                } else if (variant$2 === "NameInLocalWorkspace") {
                                  var match$8 = match$6.VAL;
                                  var workspace$1 = match$8.workspace;
                                  var workspaceName$1 = workspace$1 !== undefined ? (
                                      typeof workspace$1 === "object" ? GlobalRequirementsUtils.getWorkspaceNameById(branchModel, workspace$1.VAL) : "all workspaces"
                                    ) : "no workspaces";
                                  tmp = React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement(IconArrowDownEast.make, {
                                            size: 8,
                                            color: Styles.Color.darkRed
                                          }), React.createElement(Spacer.make, {
                                            width: 4
                                          }), JsDiff.renderStringDiff(undefined, undefined, undefined, "Send as \"" + Belt_Option.getWithDefault(match$8.name, "N/A") + "\" to " + workspaceName$1 + " on all events", ""));
                                } else {
                                  tmp = null;
                                }
                              }
                            } else if (nextRule !== undefined) {
                              var match$9 = nextRule.definition;
                              var variant$3 = match$9.NAME;
                              if (variant$3 === "NameMapping") {
                                var match$10 = match$9.VAL;
                                var destinationName$2 = Belt_Option.mapWithDefault(match$10.destination, "no destinations", (function (param) {
                                        return getMappingDestinationName(param, branchModel);
                                      }));
                                tmp = React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement(IconArrowDownEast.make, {
                                          size: 8,
                                          color: Styles.Color.darkGreen
                                        }), React.createElement(Spacer.make, {
                                          width: 4
                                        }), JsDiff.renderStringDiff(undefined, undefined, undefined, "", "Send as \"" + Belt_Option.getWithDefault(match$10.name, "N/A") + "\" to " + destinationName$2 + " on all events"));
                              } else if (variant$3 === "NameInLocalWorkspace") {
                                var match$11 = match$9.VAL;
                                var workspace$2 = match$11.workspace;
                                var workspaceName$2 = workspace$2 !== undefined ? (
                                    typeof workspace$2 === "object" ? GlobalRequirementsUtils.getWorkspaceNameById(branchModel, workspace$2.VAL) : "all workspaces"
                                  ) : "no workspaces";
                                tmp = React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement(IconArrowDownEast.make, {
                                          size: 8,
                                          color: Styles.Color.darkGreen
                                        }), React.createElement(Spacer.make, {
                                          width: 4
                                        }), JsDiff.renderStringDiff(undefined, undefined, undefined, "", "Send as \"" + Belt_Option.getWithDefault(match$11.name, "N/A") + "\" to " + workspaceName$2 + " on all events"));
                              } else {
                                tmp = null;
                              }
                            } else {
                              tmp = null;
                            }
                            return React.createElement("div", {
                                        key: ruleId,
                                        className: Curry._1(Css.style, {
                                              hd: Css.paddingLeft(Css.px(4)),
                                              tl: {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Regular",
                                            children: tmp
                                          }));
                          }))), absenceActions !== /* [] */0 ? React.createElement(DiffProperty$DiffAbsence, {
                        masterModel: masterModel,
                        branchModel: branchModel,
                        masterProperty: property$1,
                        nextProperty: nextProperty$1,
                        actions: absenceActions
                      }) : null, ModelUtils.getPropertyImmediateParents(property$1.id, branchModel) !== /* [] */0 && property$1.optionalWhenInObject !== nextProperty$1.optionalWhenInObject ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.marginTop(Css.px(2)),
                                tl: /* [] */0
                              }
                            })
                      }, JsDiff.renderStringDiff(undefined, undefined, undefined, property$1.optionalWhenInObject ? "Optional" : "Required", nextProperty$1.optionalWhenInObject ? "Optional" : "Required"), " when sent as part of an object property") : null, eventSpecificAllowedValuesActions ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.margin4(Css.px(8), Css.px(0), Css.px(0), Css.px(22)),
                              tl: /* [] */0
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Allowed values"
                          }), React.createElement(DiffPropertyAllowedValues.make, {
                            fromProperty: property$1,
                            toProperty: nextProperty$1,
                            actions: eventSpecificAllowedValuesActions,
                            branchModel: branchModel
                          })) : null), tmp);
}

var make = DiffProperty;

export {
  printEventAbsence ,
  renderAbsenceTextDiff ,
  DiffAbsence ,
  getMappingDestinationName ,
  make ,
}
/* tableHeaderStyles Not a pure module */
