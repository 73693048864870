// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";

function cmp(a, b) {
  var exit = 0;
  var a$1;
  var variant = a.NAME;
  if (variant === "event") {
    var a$2 = a.VAL;
    if (typeof b === "object") {
      if (b.NAME === "event") {
        return $$String.compare(a$2, b.VAL);
      }
      a$1 = a$2;
      exit = 2;
    } else {
      a$1 = a$2;
      exit = 2;
    }
  } else if (variant === "property") {
    var a$3 = a.VAL;
    if (typeof b === "object") {
      var variant$1 = b.NAME;
      if (variant$1 === "eventProperty") {
        return 1;
      }
      if (variant$1 === "property") {
        return $$String.compare(a$3, b.VAL);
      }
      a$1 = a$3;
      exit = 2;
    } else {
      a$1 = a$3;
      exit = 2;
    }
  } else if (typeof b === "object") {
    var variant$2 = b.NAME;
    if (variant$2 === "eventProperty") {
      return $$String.compare(a.VAL, b.VAL);
    }
    if (variant$2 === "property") {
      return -1;
    }
    
  }
  if (exit === 2 && typeof b === "object") {
    var variant$3 = b.NAME;
    if (variant$3 === "property" || variant$3 === "event") {
      return $$String.compare(a$1, b.VAL);
    }
    
  }
  return $$String.compare(a.VAL, b.VAL);
}

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

var CopyItems = {
  Comparator: Comparator
};

export {
  CopyItems ,
}
/* Comparator Not a pure module */
