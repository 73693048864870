// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ActionDiff from "../../shared/ActionDiff.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_MutableSet from "rescript/lib/es6/belt_MutableSet.js";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as CopyChangesToBranchModal__Types from "./CopyChangesToBranchModal__Types.mjs";

function dedupe(items) {
  var itemsSet = Belt_MutableSet.make(CopyChangesToBranchModal__Types.CopyItems.Comparator);
  return Belt_Array.keep(items, (function (item) {
                if (Belt_MutableSet.has(itemsSet, item)) {
                  return false;
                } else {
                  Belt_MutableSet.add(itemsSet, item);
                  return true;
                }
              }));
}

function prioritizePropertiesOverEventProperties(items) {
  var itemsSet = Belt_Set.fromArray(items, CopyChangesToBranchModal__Types.CopyItems.Comparator);
  return Belt_Array.keep(items, (function (item) {
                var variant = item.NAME;
                if (variant === "property" || variant === "event") {
                  return true;
                } else {
                  return !Belt_Set.has(itemsSet, {
                              NAME: "property",
                              VAL: item.VAL
                            });
                }
              }));
}

var CopyItems = {
  dedupe: dedupe,
  prioritizePropertiesOverEventProperties: prioritizePropertiesOverEventProperties
};

function resolveCopyItemActions(copyItemsActions) {
  var actions = [];
  var itemsNotFound = [];
  Belt_Array.forEachU(copyItemsActions, (function (copyItemActions) {
          if (copyItemActions.NAME === "itemNotFound") {
            itemsNotFound.push(copyItemActions.VAL);
          } else {
            actions.push(copyItemActions.VAL[1]);
          }
        }));
  return [
          Belt_List.flatten(Belt_List.fromArray(actions)),
          itemsNotFound
        ];
}

function allowedValueIrrelevantDueToPinnedValueOnEvent(pinnedValuesMap, eventId, propertyId, allowedValue) {
  var propertyPinnedValues = Belt_MapString.get(pinnedValuesMap, eventId);
  if (propertyPinnedValues === undefined) {
    return true;
  }
  var propertyPinnedValue = Belt_MapString.get(Caml_option.valFromOption(propertyPinnedValues), propertyId);
  if (propertyPinnedValue !== undefined) {
    return Caml_obj.notequal(Caml_option.valFromOption(propertyPinnedValue), allowedValue);
  } else {
    return false;
  }
}

function allowedValueIrrelevantDueToPinnedValueForAllEvents(eventIds, pinnedValuesMap, propertyId, allowedValue) {
  return Belt_Array.everyU(Belt_SetString.toArray(eventIds), (function (eventId) {
                return allowedValueIrrelevantDueToPinnedValueOnEvent(pinnedValuesMap, eventId, propertyId, allowedValue);
              }));
}

function getFilteredEventsPropertyActionsToCopy(eventPropertyActionsToCopy, pinnedValuesMap, propertyId, relevantEventIds) {
  return Belt_List.keepMapU(eventPropertyActionsToCopy, (function (action) {
                var eventId;
                var allowedValue;
                if (typeof action !== "object") {
                  return action;
                }
                var variant = action.NAME;
                if (variant === "AddEventSpecificPropertyValueForAllEvents" || variant === "RemoveEventSpecificPropertyValueForAllEvents") {
                  var match = action.VAL[1];
                  if (typeof match === "object" && match.NAME === "StringLit" && allowedValueIrrelevantDueToPinnedValueForAllEvents(relevantEventIds, pinnedValuesMap, propertyId, match.VAL)) {
                    return ;
                  } else {
                    return action;
                  }
                }
                if (variant === "SetPropertyAbsenceOnVariant" || variant === "UpdatePropertyRegexOverrideOnEventVariant") {
                  if (Belt_SetString.has(relevantEventIds, action.VAL[0].baseEventId)) {
                    return action;
                  } else {
                    return ;
                  }
                }
                if (variant === "UpdatePropertyRegexValidation") {
                  var eventId$1 = action.VAL[1];
                  if (eventId$1 !== undefined && !Belt_SetString.has(relevantEventIds, eventId$1)) {
                    return ;
                  } else {
                    return action;
                  }
                }
                if (variant === "AddEventSpecificPropertyValue" || variant === "RemoveEventSpecificPropertyValue") {
                  var match$1 = action.VAL;
                  var match$2 = match$1[2];
                  if (typeof match$2 !== "object") {
                    return action;
                  }
                  if (match$2.NAME !== "StringLit") {
                    return action;
                  }
                  eventId = match$1[0];
                  allowedValue = match$2.VAL;
                } else if (variant === "SetEventSpecificPropertyValueSources") {
                  var match$3 = action.VAL;
                  var match$4 = match$3[2];
                  if (typeof match$4 !== "object") {
                    return action;
                  }
                  if (match$4.NAME !== "StringLit") {
                    return action;
                  }
                  eventId = match$3[0];
                  allowedValue = match$4.VAL;
                } else {
                  if (variant === "AddEventSpecificPropertyValueForSomeEvents") {
                    var match$5 = action.VAL;
                    var match$6 = match$5[1];
                    if (typeof match$6 !== "object") {
                      return action;
                    }
                    if (match$6.NAME !== "StringLit") {
                      return action;
                    }
                    var propertyValueDisallowedEvents = match$5[2];
                    if (allowedValueIrrelevantDueToPinnedValueForAllEvents(Belt_SetString.keep(relevantEventIds, (function (eventId) {
                                  return !Belt_MapString.has(propertyValueDisallowedEvents, eventId);
                                })), pinnedValuesMap, propertyId, match$6.VAL)) {
                      return ;
                    } else {
                      return action;
                    }
                  }
                  if (variant !== "UpdatePropertyAbsence") {
                    return action;
                  }
                  var match$7 = action.VAL[1];
                  var exit = 0;
                  if (typeof match$7 === "number") {
                    return action;
                  }
                  switch (match$7.TAG | 0) {
                    case /* Source */1 :
                        return action;
                    case /* Event */0 :
                    case /* EventSource */2 :
                        exit = 2;
                        break;
                    
                  }
                  if (exit === 2) {
                    if (Belt_SetString.has(relevantEventIds, match$7._0)) {
                      return action;
                    } else {
                      return ;
                    }
                  }
                  
                }
                if (allowedValueIrrelevantDueToPinnedValueOnEvent(pinnedValuesMap, eventId, propertyId, allowedValue)) {
                  return ;
                } else {
                  return action;
                }
              }));
}

function getPinnedValuesMap(relevantEventIds, toMappedEvents) {
  return Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_SetString.toArray(relevantEventIds), (function (eventId) {
                    var match = Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, eventId);
                    if (match !== undefined) {
                      return [
                              eventId,
                              Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_List.toArray(match.directPropertyRefs), (function (propertyRef) {
                                          if (propertyRef.TAG !== /* PropertyRef */0) {
                                            return ;
                                          }
                                          var match = propertyRef._0;
                                          var match$1 = match.pinnedValue;
                                          if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "StringLit") {
                                            return [
                                                    match.id,
                                                    match$1.VAL
                                                  ];
                                          }
                                          
                                        })))
                            ];
                    }
                    
                  })));
}

function getPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, toProperty) {
  var propertyActionsToCopy = ActionDiff.getNewAndUpdatedPropertyActions(fromMappedProperties, fromMappedPropertiesArchive, fromModel, toModel, toMappedEvents, hasMigratedToEventSpecificAllowedValues, toProperty);
  var keepPropertyRelatedRules = function (rules, propertyId) {
    return Belt_List.keepMap(rules, (function (rule) {
                  var match = rule.item;
                  var variant = match.NAME;
                  if (variant === "PropertyRef") {
                    if (match.VAL[1] === propertyId) {
                      return rule;
                    } else {
                      return ;
                    }
                  } else if (variant === "Property" && match.VAL === propertyId) {
                    return rule;
                  } else {
                    return ;
                  }
                }));
  };
  var ruleActionsToCopy = ActionDiff.diffRules(keepPropertyRelatedRules(fromModel.rules, toProperty.id), keepPropertyRelatedRules(toModel.rules, toProperty.id), toModel);
  return Belt_List.concat(propertyActionsToCopy, ruleActionsToCopy);
}

function getEventPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, relevantEventIds, toProperty) {
  var propertyActionsToCopy = getPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, toProperty);
  var pinnedValuesMap = getPinnedValuesMap(relevantEventIds, toMappedEvents);
  return getFilteredEventsPropertyActionsToCopy(propertyActionsToCopy, pinnedValuesMap, toProperty.id, relevantEventIds);
}

function getEventCopyActions(fromRules, toModel, fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements, toEvent) {
  var eventActionsToCopy = ActionDiff.getNewAndUpdatedEventActions(fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toGlobalRequirements, toEvent);
  var keepEventRelatedRules = function (rules, eventId) {
    return Belt_List.keepMap(rules, (function (rule) {
                  var match = rule.item;
                  var ruleEventId;
                  var variant = match.NAME;
                  if (variant === "Property" || variant === "PropertyRef") {
                    return ;
                  }
                  ruleEventId = variant === "Event" ? match.VAL : match.VAL[0];
                  if (ruleEventId === eventId) {
                    return rule;
                  }
                  
                }));
  };
  var ruleActionsToCopy = ActionDiff.diffRules(keepEventRelatedRules(fromRules, toEvent.id), keepEventRelatedRules(toModel.rules, toEvent.id), toModel);
  return Belt_List.concat(eventActionsToCopy, ruleActionsToCopy);
}

function getItemsActions(toModel, fromModel, copyItems) {
  var fromMappedEvents = Curry._1(TrackingPlanMappedModel.Events.fromList, fromModel.events);
  var fromMappedEventsArchive = Curry._1(TrackingPlanMappedModel.Events.fromList, fromModel.archive.events);
  var fromMappedProperties = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, fromModel.properties);
  var fromMappedPropertiesArchive = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, fromModel.archive.properties);
  var toMappedEvents = Curry._1(TrackingPlanMappedModel.Events.fromList, toModel.events);
  var toMappedProperties = Curry._1(TrackingPlanMappedModel.Properties.Utils.fromOld, toModel.properties);
  var toMappedPropertyBundles = Curry._1(TrackingPlanMappedModel.PropertyBundles.fromList, toModel.propertyBundles);
  var hasMigratedToEventSpecificAllowedValues = ModelUtils.hasMigrated(toModel, "EventSpecificAllowedPropertyValues");
  var relevantEventIds = Belt_SetString.fromArray(Belt_Array.keepMap(copyItems, (function (copyItem) {
              if (typeof copyItem === "object" && copyItem.NAME === "event") {
                return copyItem.VAL;
              }
              
            })));
  return Belt_Array.map(prioritizePropertiesOverEventProperties(dedupe(Belt_Array.concatMany(Belt_Array.map(copyItems, (function (copyItem) {
                                var variant = copyItem.NAME;
                                if (variant !== "event") {
                                  if (variant === "property") {
                                    return [{
                                              NAME: "property",
                                              VAL: copyItem.VAL
                                            }];
                                  } else {
                                    return [{
                                              NAME: "eventProperty",
                                              VAL: copyItem.VAL
                                            }];
                                  }
                                }
                                var eventId = copyItem.VAL;
                                var match = Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, eventId);
                                var eventProperties = match !== undefined ? Belt_List.toArray(Belt_List.keepMapU(match.directPropertyRefs, (function (propertyRef) {
                                              if (propertyRef.TAG === /* PropertyRef */0) {
                                                return {
                                                        NAME: "eventProperty",
                                                        VAL: propertyRef._0.id
                                                      };
                                              }
                                              
                                            }))) : [];
                                return Belt_Array.concat([{
                                              NAME: "event",
                                              VAL: eventId
                                            }], eventProperties);
                              }))))), (function (copyItem) {
                var variant = copyItem.NAME;
                if (variant === "event") {
                  var toEvent = Curry._2(TrackingPlanMappedModel.Events.get, toMappedEvents, copyItem.VAL);
                  if (toEvent !== undefined) {
                    return {
                            NAME: "actions",
                            VAL: [
                              copyItem,
                              getEventCopyActions(fromModel.rules, toModel, fromMappedEvents, fromMappedEventsArchive, toMappedProperties, toMappedPropertyBundles, toModel.globalRequirements, toEvent)
                            ]
                          };
                  } else {
                    return {
                            NAME: "itemNotFound",
                            VAL: copyItem
                          };
                  }
                }
                if (variant === "property") {
                  var match = ModelUtils.getPropertyById(copyItem.VAL, toModel);
                  if (match !== undefined && match.TAG !== /* PropertyRef */0) {
                    return {
                            NAME: "actions",
                            VAL: [
                              copyItem,
                              getPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, match._0)
                            ]
                          };
                  } else {
                    return {
                            NAME: "itemNotFound",
                            VAL: copyItem
                          };
                  }
                }
                var match$1 = ModelUtils.getPropertyById(copyItem.VAL, toModel);
                if (match$1 !== undefined && match$1.TAG !== /* PropertyRef */0) {
                  return {
                          NAME: "actions",
                          VAL: [
                            copyItem,
                            getEventPropertyCopyActions(fromModel, toModel, toMappedEvents, fromMappedProperties, fromMappedPropertiesArchive, hasMigratedToEventSpecificAllowedValues, relevantEventIds, match$1._0)
                          ]
                        };
                } else {
                  return {
                          NAME: "itemNotFound",
                          VAL: copyItem
                        };
                }
              }));
}

export {
  CopyItems ,
  resolveCopyItemActions ,
  allowedValueIrrelevantDueToPinnedValueOnEvent ,
  allowedValueIrrelevantDueToPinnedValueForAllEvents ,
  getFilteredEventsPropertyActionsToCopy ,
  getPinnedValuesMap ,
  getPropertyCopyActions ,
  getEventPropertyCopyActions ,
  getEventCopyActions ,
  getItemsActions ,
}
/* ActionDiff Not a pure module */
