// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ActionDiff from "../../shared/ActionDiff.mjs";
import * as FetchModel from "./FetchModel.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ActionsReducer from "./actionsReducer.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as BranchActionDiffCalculator from "./BranchActionDiffCalculator.mjs";

function useFromAndToModelsForCopy(selectedBranch) {
  var workspace = WorkspaceContext.use(undefined);
  var mainModel = ModelStore.useMainModel(undefined);
  var latestMainAction = ModelStore.useLatestMainAction(undefined);
  var maybeMainToOriginBranchDiffInformation = ModelStore.useDiffInformation(undefined);
  var fetchDestinationBranchModel = FetchModel.use(undefined, undefined, selectedBranch !== undefined && typeof selectedBranch === "object" && selectedBranch.NAME !== "Create" ? "Fetch" : "Skip", selectedBranch !== undefined && typeof selectedBranch === "object" && selectedBranch.NAME !== "Create" ? ({
            NAME: "branch",
            VAL: selectedBranch.VAL.id
          }) : "master");
  var selectedBranchModelFetched = React.useMemo((function () {
          if (selectedBranch === undefined) {
            return false;
          }
          if (typeof selectedBranch !== "object") {
            return false;
          }
          if (selectedBranch.NAME !== "Branch") {
            return false;
          }
          switch (fetchDestinationBranchModel.TAG | 0) {
            case /* Loading */0 :
            case /* Error */1 :
                return false;
            case /* Result */2 :
                var latestAction = fetchDestinationBranchModel._2;
                if (latestAction !== undefined && Caml_obj.equal(selectedBranch.VAL.id, Caml_option.undefined_to_opt(Caml_option.valFromOption(latestAction).branchId))) {
                  return true;
                } else {
                  return false;
                }
            
          }
        }), [
        selectedBranch,
        fetchDestinationBranchModel
      ]);
  var tmp;
  switch (fetchDestinationBranchModel.TAG | 0) {
    case /* Loading */0 :
    case /* Error */1 :
        tmp = mainModel;
        break;
    case /* Result */2 :
        tmp = fetchDestinationBranchModel._0;
        break;
    
  }
  var tmp$1;
  switch (fetchDestinationBranchModel.TAG | 0) {
    case /* Loading */0 :
    case /* Error */1 :
        tmp$1 = undefined;
        break;
    case /* Result */2 :
        tmp$1 = fetchDestinationBranchModel._2;
        break;
    
  }
  var maybeDestinationBranchFromAndToModels = BranchActionDiffCalculator.useFromAndToModels(selectedBranchModelFetched ? "Fetch" : "Skip", workspace.id, selectedBranch !== undefined && typeof selectedBranch === "object" && selectedBranch.NAME !== "Create" ? selectedBranch.VAL.id : "master", tmp, tmp$1, mainModel, latestMainAction, (function (param) {
          
        }));
  var maybeOriginBranchWithDestinationBranchChanges;
  if (maybeDestinationBranchFromAndToModels !== undefined && maybeMainToOriginBranchDiffInformation !== undefined) {
    var match = maybeMainToOriginBranchDiffInformation.fromAndToModels;
    var destinationBranchMergeActions = ActionDiff.diff((function (error) {
            console.error("Destination branch changes diff error", error);
          }), maybeDestinationBranchFromAndToModels.from.model, maybeDestinationBranchFromAndToModels.to.model, undefined);
    var mainWithDestinationBranchChanges = Belt_List.reduce(destinationBranchMergeActions, mainModel, ActionsReducer.reduce);
    var originBranchChanges = ActionDiff.diff((function (error) {
            console.error("Origin branch changes branch diff error", error);
          }), match.from.model, match.to.model, undefined);
    maybeOriginBranchWithDestinationBranchChanges = Belt_List.reduce(originBranchChanges, mainWithDestinationBranchChanges, ActionsReducer.reduce);
  } else {
    maybeOriginBranchWithDestinationBranchChanges = undefined;
  }
  var getFromAndToModels = function (param) {
    if (selectedBranch !== undefined && typeof selectedBranch === "object" && selectedBranch.NAME !== "Create") {
      if (maybeOriginBranchWithDestinationBranchChanges !== undefined && maybeDestinationBranchFromAndToModels !== undefined) {
        return {
                NAME: "Loaded",
                VAL: [
                  maybeDestinationBranchFromAndToModels.to.model,
                  maybeOriginBranchWithDestinationBranchChanges
                ]
              };
      } else {
        return "Loading";
      }
    }
    if (maybeMainToOriginBranchDiffInformation === undefined) {
      return "Loading";
    }
    var match = maybeMainToOriginBranchDiffInformation.fromAndToModels;
    return {
            NAME: "Loaded",
            VAL: [
              match.from.model,
              match.to.model
            ]
          };
  };
  var match$1 = React.useState(function () {
        return getFromAndToModels(undefined);
      });
  var setFromAndToModels = match$1[1];
  React.useEffect((function () {
          Curry._1(setFromAndToModels, (function (param) {
                  return getFromAndToModels(undefined);
                }));
        }), [
        selectedBranch,
        maybeMainToOriginBranchDiffInformation,
        maybeDestinationBranchFromAndToModels
      ]);
  return match$1[0];
}

export {
  useFromAndToModelsForCopy ,
}
/* react Not a pure module */
